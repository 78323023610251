import React, { useEffect, useState } from "react";
import { Text, Flex, Box, Button, useToast } from "@chakra-ui/react";
import SpeechButton from "../components/speech-button";
import { fetchExamples } from "../api/word.service";
import { Constants } from "../utils/constants";
import { CopyIcon } from "@chakra-ui/icons";
import CopyToClipboardButton from "../components/copy-button";
import { focusHTMLElementDelayed, focusRefDelayed } from "../utils/utils";
import { useTranslation } from "react-i18next";
import { logEvent_UserSnippets_WordDetails_SeeExamplesError } from "../analytics/analytics";

function WordInfoDialog({
  term,
  termLanguageCode,
  userLanguageCode,
  popoverRef,
  onClose,
}) {
  const [isFetchingExamples, setFetchingExamples] = useState(false);
  const [examples, setExamples] = useState(null);
  const [word, setWord] = useState(term);

  const [termLanguage, setTermLanguage] = useState(termLanguageCode);
  const [userLanguage, setUserLanguage] = useState(userLanguageCode);
  const [updateRef, setUpdateRef] = useState(1); // This is a hack to force the popover to update when the mode changes
  const toast = useToast();

  const rootBoxRef = React.useRef(null);

  const { t } = useTranslation();

  // Reset function
  const resetState = () => {
    setWord(term);
    setTermLanguage(termLanguageCode);
    setUserLanguage(userLanguageCode);
    setExamples(null);
    setFetchingExamples(false);
    setUpdateRef(updateRef + 1);
  };

  // Use the useEffect hook to call the reset function when term or languageCode changes
  useEffect(() => {
    resetState();
  }, [term, termLanguageCode, userLanguageCode]);

  const extractWord = (str) => {
    // remove <w> and </w> tags from string
    return str.replace(/<[^>]*>/g, "");
  };

  const renderWord = (str) => {
    console.log("renderWord " + JSON.stringify(str));
    let splitText = str.split(/<w>|<\/w>/);

    if (splitText.length === 1) {
      // split by russian tags
      splitText = str.split(/<в>|<\/в>/);
    }

    return splitText.map((text, index) => {
      if (index % 2 === 1) {
        return <strong key={index}>{text}</strong>;
      }
      return text;
    });
  };

  function focusParentDelayed() {
    let parent = rootBoxRef.current?.parentElement?.parentElement;
    focusHTMLElementDelayed(parent);
  }

  async function onSeeExamples() {
    setFetchingExamples(true);
    let ref = updateRef;
    focusParentDelayed();

    const trimmedTerm = term.trim();
    try {
      let keysToIgnore = examples ? examples.map((e) => e.id) : [];
      console.log("keysToIgnore " + JSON.stringify(keysToIgnore, null, 2));
      
      let resultArray = await fetchExamples(
        trimmedTerm,
        termLanguage,
        userLanguage,
        keysToIgnore
      );
      console.log("examples result " + JSON.stringify(resultArray, null, 2));
      if (resultArray && ref === updateRef) {
        var existingExamples = examples ?? [];

        // concat existing examples with new examples
        existingExamples = existingExamples.concat(resultArray);
        setExamples(existingExamples);
      }
    } catch (e) {
      if (ref !== updateRef) {
        return;
      }

      logEvent_UserSnippets_WordDetails_SeeExamplesError(
        e,
        trimmedTerm,
        termLanguage
      );
      console.log("error " + e.message);
      toast({
        description: e.message ?? "Error fetching examples",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      if (ref !== updateRef) {
        return;
      }
      setFetchingExamples(false);
      focusParentDelayed();
    }
  }

  function onClickCopy() {
    onClose?.();
  }

  return (
    <>
      <Box ref={rootBoxRef}>
        {word && termLanguage && popoverRef && (
          <Box style={{ width: "auto", textAlign: "left" }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                style={{ width: "auto", textAlign: "left" }}
                color="white"
                fontSize="lg"
              >
                {term}
              </Text>
              <Box>
                <CopyToClipboardButton
                  text={word}
                  onClick={() => {
                    onClickCopy();
                  }}
                />
                <SpeechButton
                  text={word}
                  languageCode={termLanguage}
                  onPlay={() => {
                    focusParentDelayed();
                  }}
                  onClick={() => {
                    focusParentDelayed();
                  }}
                />
              </Box>
            </Flex>
            {!examples && (
              <Button
                isLoading={isFetchingExamples}
                mt={4}
                left={0}
                variant="link"
                onClick={onSeeExamples}
              >
                {t("sentences.see_examples")}
              </Button>
            )}
            {examples && (
              <Box mt={4} width="100%">
                <Text
                  style={{ width: "auto", textAlign: "left" }}
                  color="white"
                  fontSize="xl"
                  fontWeight="bold"
                >
                  {t("sentences.examples_title")}
                </Text>
                {examples.map((example, index) => (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box key={index} mt={2} p={1}>
                      <Text
                        style={{
                          width: "98%",
                          textAlign: "left",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                        key={index}
                        color="white"
                        fontSize="md"
                      >
                        {renderWord(example.text)}
                      </Text>
                      <Text
                        style={{
                          width: "98%",
                          textAlign: "left",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                        mt={1}
                        key={index}
                        color="whiteAlpha.600"
                        fontSize="md"
                      >
                        {renderWord(example.translation)}
                      </Text>
                    </Box>
                    <SpeechButton
                      key={index}
                      text={extractWord(example.text)}
                      languageCode={termLanguage}
                      onClick={() => {
                        focusParentDelayed();
                      }}
                    />
                  </Flex>
                ))}
              </Box>
            )}
            {examples && examples.length < Constants.MAX_EXAMPLES && (
              <Button
                isLoading={isFetchingExamples}
                mt={4}
                left={0}
                variant="link"
                onClick={onSeeExamples}
              >
                Load More
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default WordInfoDialog;
