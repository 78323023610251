import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Input, Select, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function EditSnippetHeader({
    editModel,
    term,
    expandedState,
    toggleSnippetCollapsed,
    canCollapse,
    definitions,
    selectedDefinitionID,
    customDefinitionText,
    showAddSnippetButton,
    onChange
}) {
    const [model, setModel] = useState(editModel)
    const [customText, setCustomText] = useState(customDefinitionText)
    const [isEditingCustomDefinition, setIsEditingCustomDefinition] = useState(false)

    const [selectedDefinition, setSelectedDefinition] = useState(null)

    const customDefinitionRef = React.useRef(null)

    useEffect(() => {
        console.log('ch')
        if(selectedDefinition || customText) {
            if(editModel) {
                if(editModel.selectedDefinitionID !== selectedDefinition?.id) {
                    editModel.selectedDefinitionID = selectedDefinition?.id
                }
                
                if(editModel.customDefinitionText !== customText) {
                    editModel.customDefinitionText = customText
                }

                if(isEditingCustomDefinition || customText) {
                    editModel.selectedDefinitionID = null
                }
            }

            onChange?.(editModel)
        }
    }, [customText, selectedDefinition, onChange, editModel, isEditingCustomDefinition])

    useEffect(() => {
        if (definitions && Array.isArray(definitions) && definitions.length > 0) {
            let selected = definitions.find((definition) => {
                return definition.id === selectedDefinitionID
            })

            if (selected) {
                setSelectedDefinition(selected)
            } 
        } else {
            setSelectedDefinition(null)
        }
    }, [definitions, selectedDefinitionID])

    function selectFirstDefinition() {
        if (definitions && Array.isArray(definitions) && definitions.length > 0) {
            setSelectedDefinition(definitions[0])
        } 
    }

    const selectDefinition = (index) => {
        setSelectedDefinition(definitions[index])
    }

    return (
        <Box backgroundColor="darkPurple" m={2} p={1} borderRadius="md" data-test='edit-snippet-header'>
            <Flex align="center" justifyContent="space-between">
                <Text
                    data-test='text-term'
                    ml={showAddSnippetButton ? 0 : 3}
                    mr={2}
                    fontWeight="bold"
                    color="white"
                    fontSize="lg"
                >
                    {term}&nbsp;-
                </Text>
                {!isEditingCustomDefinition && !customText &&
                    <Flex w='100%' align="center" justifyContent="space-between">
                        <Select
                            data-test='select-definition'
                            color="white"
                            value={selectedDefinition?.text ?? ''}
                            onChange={(event) => {
                                selectDefinition(event.target.selectedIndex);
                            }}
                        >
                            {definitions && definitions.map((section, index) => {
                                return (
                                    <option data-test={`option_${index}`} key={index} value={section?.text}>
                                        {section?.text}
                                    </option>
                                );
                            })}
                        </Select>

                        <IconButton
                            color="white"
                            width={"32px"}
                            height={"32px"}
                            icon={<EditIcon />}
                            _hover={{ bg: "midPurple" }}
                            data-test='edit-definition'
                            onClick={() => {
                                setIsEditingCustomDefinition(true)
                                setCustomText('')
                                customDefinitionRef.current?.focus();
                            }}
                            aria-label="Toggle Subsection"
                            variant="ghost"
                        />
                    </Flex>
                }

                { customText && !isEditingCustomDefinition && 
                    <Flex w='100%' align="center" justifyContent="space-between">
                        <Text color="white" fontSize="lg">{customText}</Text>
                        <IconButton
                            ml={2}
                            colorScheme="red"
                            width={"32px"}
                            height={"32px"}
                            icon={<CloseIcon />}
                            data-test='close-custom-definition'
                            _hover={{ bg: "midPurple" }}
                            onClick={() => {
                                setCustomText(null)
                                selectFirstDefinition()
                                setIsEditingCustomDefinition(false)
                            }}
                            aria-label="Toggle Subsection"
                            variant="ghost"
                        />
                    </Flex>
                }

                {isEditingCustomDefinition && <>
                    <Flex align="center">
                        <Input color='white' autoFocus={true} ref={customDefinitionRef} value={customText} onChange={(e) => { setCustomText(e.target.value) }} />
                        <IconButton
                            color="white"
                            ml={2}
                            width={"32px"}
                            height={"32px"}
                            icon={<CheckIcon />}
                            data-test='save-custom-definition'
                            _hover={{ bg: "midPurple" }}
                            onClick={() => {
                                if ( (customText ?? '').trim()) {
                                    setIsEditingCustomDefinition(false)
                                } else {
                                    customDefinitionRef.current?.focus();
                                }
                            }}
                            aria-label="Toggle Subsection"
                            variant="ghost"
                        />
                        <IconButton
                            color="white"
                            width={"32px"}
                            height={"32px"}
                            ml={2}
                            icon={<CloseIcon />}
                            _hover={{ bg: "midPurple" }}
                            data-test='reject-custom-definition'
                            onClick={() => {
                                setCustomText(null)
                                setIsEditingCustomDefinition(false)
                            }}
                            aria-label="Toggle Subsection"
                            variant='ghost'
                        />
                    </Flex>
                </>
                }

                { canCollapse && <IconButton
                    color="white"
                    width={"24px"}
                    height={"24px"}
                    data-test='collapse-snippet'
                    icon={expandedState ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    style={{ cursor: canCollapse ? "pointer" : "default" }}
                    opacity={canCollapse ? 1 : 0}
                    _hover={{ bg: "midPurple" }}
                    onClick={() => {
                        if (canCollapse) {
                            toggleSnippetCollapsed();
                        }
                    }}
                    aria-label="Toggle Subsection"
                    variant="ghost"
                    ml="auto"
                /> }
            </Flex>
        </Box>
    );
}

export default EditSnippetHeader;
