import { atom } from "recoil";

export const classroomIDState = atom({
    key: 'classroomIDState',
    default: null,
});

export const classroomRoleState = atom({
    key: 'classroomRoleState',
    default: null,
});

export const classroomLanguageState = atom({
    key: 'classroomLanguageState',
    default: null,
});

export const classroomPermissionsState = atom({
    key: 'classroomPermissionsState',
    default: null,
});

export const classroomDataState = atom({
    key: 'classroomDataState',
    default: null,
});

export const classroomSentencesState = atom({
    key: 'classroomSentencesState',
    default: null,
});

export const classroomNotesState = atom({
    key: 'classroomNotesState',
    default: null,
});

export const classroomSnippetsState = atom({
    key: 'classroomSnippetsState',
    default: null,
});

// raw state from the database
export const classroomLayoutDataState = atom({
    key: 'classroomLayoutDataState',
    default: null,
});