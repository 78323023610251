import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function DeleteConfirmation({ isOpen, onClose, onConfirm, title, text, isDeleting }) {
    const [titleValue, setTitleValue] = useState('Delete Sentence');
    const [contents, setContents] = useState('Are you sure you want to delete this sentence? This action cannot be undone.');
    const [isDeletingValue, setIsDeletingValue] = useState(isDeleting); 
    const cancelRef = useRef();

    const { t } = useTranslation()

    useEffect(() => {
        setIsDeletingValue(isDeleting);
    }, [isDeleting]);

    useEffect(() => {
        if(!t) {
            return
        }

        if (title) {
            setTitleValue(title);
        } else {
            setTitleValue(t('sentences.delete_sentence'))
        }

        if (text) {
            setContents(text);
        } else {
            setContents(t('sentences.delete_sentence_confirmation'))
        }
    }, [title, text, t]);

    return (
        <AlertDialog data-test='delete-alert-dialog' isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay data-test='delete-alert-overlay'>
                <AlertDialogContent data-test='delete-content' bgImage="url('/icons/dialog.png')" bgSize="cover" bgRepeat="no-repeat" borderColor='dialogBorder' >
                    <AlertDialogHeader data-test='delete-alert-header' fontSize="lg" fontWeight="bold" color='white'>
                        {titleValue}
                    </AlertDialogHeader>
                    <AlertDialogBody color='white' data-test='delete-alert-body'>
                        {contents}
                    </AlertDialogBody>
                    <AlertDialogFooter data-test='delete-alert-footer'>
                        <Button isDisabled={isDeletingValue} ref={cancelRef} onClick={onClose} data-test='cancel-button'>
                            { t('modal.cancel') }
                        </Button>
                        <Button isLoading={isDeletingValue} colorScheme="red" onClick={onConfirm} ml={3} data-test='delete-button'>
                            { t('sentences.delete') }
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export default DeleteConfirmation;