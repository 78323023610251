import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Image, Text, useToast, Center } from '@chakra-ui/react';
import { uploadReviewStoryImage } from '../admin/manage-stories.service';
import { use } from 'i18next';

// onUploadImage should return a promise
const ImageUploadComponent = ({ onImageUploaded, onUploadImage, displayImageURL, onUploadingChanged }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageURL, setImageURL] = useState(null); // Store the uploaded image URL here
    const [imagePreview, setImagePreview] = useState(null); // For showing local preview
    const [isUploading, setIsUploading] = useState(false);

    const maxDim = '84px'

    const toast = useToast();

    useEffect(() => {
        setImageURL(displayImageURL);

        console.log('displayImageURL: ', displayImageURL)
    }, [displayImageURL]);

    useState(() => {
        onUploadingChanged?.(isUploading)
    }, [isUploading])

    // Handle file selection
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            setSelectedFile(file);
            setImagePreview(URL.createObjectURL(file)); // Show local preview

        } else {
            toast({
                title: "Invalid file type",
                description: "Please select a valid PNG or JPG image",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    };

    // Handle image upload
    const handleUpload = async () => {
        if (!selectedFile) {
            toast({
                title: "Please select a file before uploading",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }

        if (!onUploadImage) {
            return
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64Image = reader.result //.split(',')[1]; // Get the base64 string

            try {
                setIsUploading(true);
                const imageURLPath = await onUploadImage(base64Image)

                toast({
                    title: "Image uploaded successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                setSelectedFile(null);
                setImagePreview(null);
                onImageUploaded?.(imageURLPath); // Notify parent component
            } catch (error) {
                toast({
                    title: "Error uploading image",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } finally {
                setIsUploading(false);
            }
        };
        reader.readAsDataURL(selectedFile); // Read the file as base64
    };

    return (
        <Box textAlign="center" color='white' maxW="md" mx="auto" mt={5}>
            {/* Display Image */}
            <Box display='flex' flexDirection='row' justifyContent='space-between' w='100%'>
                {imageURL &&
                    <Box>
                        <Text>Uploaded Image</Text>
                        <Center>
                            <Image src={imageURL} alt="Uploaded" boxSize={maxDim} objectFit="cover" mb={4} />
                        </Center>
                    </Box>}
                {imagePreview && <Box>
                    <Text>Selected image</Text>
                    <Center>
                        <Image src={imagePreview} alt="Selected" boxSize={maxDim} objectFit="cover" mb={4} />
                    </Center>
                </Box>
                }

            </Box>


            {/* Select Image File */}
            <Input
                isDisabled={isUploading}
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileSelect}
                mb={4}
            />

            {/* Upload Button */}
            <Button isLoading={isUploading} onClick={handleUpload} colorScheme="blue" isDisabled={!selectedFile}>
                Upload
            </Button>
        </Box>
    );
};

export default ImageUploadComponent;
