import { IconButton, Image, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { randomID } from "../utils/utils"
import { convertTextToSpeech } from "../api/tts.service"
import AudioPlayer from "./audioplayer"

function SpeechButton({ text, languageCode, onError, onClick, onPlay }) {
    const [textValue, setTextValue] = useState(text ?? '')
    const [base64Audio, setBase64Audio] = useState(null)
    const [base64AudioUpdateRef, setBase64AudioUpdateRef] = useState('updateRef')
    const [isConverting, setConverting] = useState(false)
    const toast = useToast()

    useEffect(() => {
        setBase64Audio(null)
        setTextValue(text ?? '')
        console.log('Text is ' + textValue)
    }, [text])

    async function onPlaySound() {
        onClick?.()
        console.log('text value ' + JSON.stringify(textValue, null, 2))
        if (!textValue || !textValue?.trim()) {
            return
        }

        if (base64Audio) {
            setBase64AudioUpdateRef(randomID())
            return
        }

        try {
            setConverting(true)
            let audioContent = await convertTextToSpeech(textValue, languageCode)
            console.log('audio content ' + audioContent)
            setBase64Audio(audioContent)

            onPlay?.()
        } catch (e) {
            console.log('error ' + e.message)
            if (onError) {
                onError(e)
            } else {
                toast({
                    description: e.message ?? "Error playing sound",
                    position: 'top',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

        } finally {
            setConverting(false)
        }
    }

    return (<>
        <IconButton
            isDisabled={!textValue}
            width='20px'
            height='20px'
            colorScheme="clear"
            onClick={onPlaySound}
            isLoading={isConverting}
            data-test='speech-button'
            icon={<Image width='20px'
                height='20px' style={{ filter: 'invert(100%)' }} colorScheme='white' src="/icons/speak.png" />}></IconButton>
        <AudioPlayer base64={base64Audio} updateRef={base64AudioUpdateRef} />
    </>
    )

}

export default SpeechButton;