import { push, ref, serverTimestamp, set, update } from "@firebase/database";
import { database } from "./firebaseConfig";

export const addDocument = (name, userID, languageCode) => {
    const reference = push(ref(database, `documents/${userID}/${languageCode}`))
    const newDocument = {
        id: reference.key,
        name: name,
        languageCode,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp()
    };
    
    return set(reference, newDocument).then(() => {
        // return reference key
        return reference.key
    })
}

export const deleteDocument = (documentID, userID, languageCode) => {
    const reference = ref(database, `documents/${userID}/${languageCode}/${documentID}`)
    let document = {
        deleted_at: serverTimestamp()
    }    
    return update(reference, document)
}

export const editDocument = (documentID, userID, languageCode, name) => {
    const reference = ref(database, `documents/${userID}/${languageCode}/${documentID}`)
    const updatedDocument = {
        name: name,
        updated_at: serverTimestamp()
    }
    
    return update(reference, updatedDocument)
}