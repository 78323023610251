import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import { updateProfile } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";
import { Box, Button, Input, Text, VStack, Spinner } from "@chakra-ui/react";

import { auth } from "../api/firebaseConfig";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../utils/utils";
import { Constants } from "../utils/constants";
import { handleEnterKeyPress } from "../utils/helper";
import { logEvent_RegisterDialog_RegisterError } from "../analytics/analytics";

function RegisterContent() {
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const [error, setError] = useState("");

  const { t } = useTranslation();

  const emailRef = React.useRef(null);

  const handleRegister = async () => {
    setError("");

    let trimmedDisplayName = displayName.trim();
    if (trimmedDisplayName.length < Constants.DISPLAY_NAME_MIN_LENGTH) {
      setError(
        t("register.display_name_length_error", {
          length: Constants.DISPLAY_NAME_MIN_LENGTH,
        }),
      );
      return;
    }
    if (!dateOfBirth) {
      setError(t("register.date_of_birth_required"));
      return;
    }

    if (!validateEmail(email)) {
      setError(t("register.invalid_email"));
      return;
    }

    if (password !== confirmPassword) {
      setError(t("register.password_mismatch"));
      return;
    }

    let minLength = Constants.PASSWORD_MIN_LENGTH;
    if (password.length < minLength) {
      setError(t("register.password_length_error", { length: minLength }));
      return;
    }

    setSubmitting(true);
    try {
      let userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      console.log("Creating user with:", { email, password });

      await updateProfile(userCredentials.user, {
        displayName: trimmedDisplayName,
      });

      const db = getDatabase();
      const userRef = ref(db, "users/" + userCredentials.user.uid);

      let dict = {};
      dict["dateOfBirth"] = dateOfBirth;

      await update(userRef, dict);
    } catch (error) {
      console.error("Error registering user:", error);
      logEvent_RegisterDialog_RegisterError(
        error,
        email,
        dateOfBirth,
        displayName,
      );

      if (error.code === "auth/email-already-in-use") {
        setError(t("register.email_already_in_use"));
      } else {
        setError(error.message);
      }

      console.log("Setting error message:", error.message);

      setTimeout(() => {
        emailRef?.current?.focus();
      }, 100);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold" p={2} mb={2} color="white">
        {t("register.register")}
      </Text>
      <VStack ml={2} colow="white" spacing={1}>
        <Input
          autoFocus
          color="white"
          mb={3}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder={t("register.display_name")}
          onKeyDown={(e) => handleEnterKeyPress(e, handleRegister)}
        />
        <Input
          ref={emailRef}
          color="white"
          mb={3}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("register.email")}
          onKeyDown={(e) => handleEnterKeyPress(e, handleRegister)}
        />
        <Input
          color="white"
          mb={3}
          type="date"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          placeholder={t("register.dob")}
          onKeyDown={(e) => handleEnterKeyPress(e, handleRegister)}
        />
        <Input
          color="white"
          mb={3}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value.trim())}
          placeholder={t("register.password")}
          onKeyDown={(e) => handleEnterKeyPress(e, handleRegister)}
        />
        <Input
          color="white"
          mb={3}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value.trim())}
          placeholder={t("register.confirm_password")}
          onKeyDown={(e) => handleEnterKeyPress(e, handleRegister)}
        />

        <Button
          w="full"
          colorScheme="green"
          mt={2}
          onClick={handleRegister}
          disabled={isSubmitting}
          sx={{
            opacity: isSubmitting ? 0.5 : 1,
            cursor: isSubmitting ? "not-allowed" : "pointer",
          }}
        >
          {isSubmitting ? (
            <Spinner size="sm" color="white" />
          ) : (
            <>{t("register.register")}</>
          )}
        </Button>
        {error && (
          <Text color="red.500" mt={2} mb={1}>
            {error}
          </Text>
        )}
      </VStack>
    </Box>
  );
}

export default RegisterContent;
