import { atom } from "recoil";

export const cellSizeState = atom({
    key: 'cellSizeState',
    default: {},
});

export const renderTableRequestedEventState = atom({
    key: 'renderTableRequestedEventState',
    default: null,
});
