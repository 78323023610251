import { getDatabase, ref, get, set, update } from "firebase/database";

async function checkUserLanguages(userID) {
  const db = getDatabase();
  let path = "users/" + userID;
  console.log("path " + path);
  const userRef = ref(db, path);
  const snapshot = await get(userRef);

  if (snapshot.exists()) {
    const { userLanguage, learningLanguage } = snapshot.val();
    console.log("check call userLanguage " + userLanguage);
    console.log("check call learningLanguage " + learningLanguage);
    if (userLanguage && learningLanguage)
      return { userLanguage, learningLanguage };
  }

  return null;
}

export const checkUserLanguagesAndRedirect = async (userID) => {
  console.log("checkUserLanguagesAndRedirect " + userID);
  let languages = await checkUserLanguages(userID);

  if (!languages?.userLanguage || !languages?.learningLanguage) {
    let chooseLanguageHref = "/choose-language";
    if (!window.location.href.endsWith(chooseLanguageHref)) {
      window.location.href = chooseLanguageHref;
    }
  } else {
    console.log("set userLanguage to " + languages.userLanguage);
    localStorage.setItem("check else userLanguage", languages.userLanguage);
    localStorage.setItem(
      "check else learningLanguage",
      languages.learningLanguage
    );
  }

  return languages;
};

export const getUserLanguageCodeFromLocalStorage = () => {
  return localStorage.getItem("userLanguage");
};

export const updateLanguages = async (
  userID,
  userLanguage,
  learningLanguage
) => {
  console.log("update languages " + userLanguage + " " + learningLanguage);
  if (userID) {
    const db = getDatabase();
    const userRef = ref(db, "users/" + userID);

    let dict = {};

    // Check if userLanguage is an object, if so, extract the code
    if (userLanguage) {
      const userLanguageCode =
        typeof userLanguage === "object" ? userLanguage.code : userLanguage;
      dict["userLanguage"] = userLanguageCode;
    }

    // Check if learningLanguage is an object, if so, extract the code
    if (learningLanguage) {
      const learningLanguageCode =
        typeof learningLanguage === "object"
          ? learningLanguage.code
          : learningLanguage;
      dict["learningLanguage"] = learningLanguageCode;
    }

    await update(userRef, dict);
  }

  // Now let's ensure we're setting strings in the localStorage
  if (userLanguage) {
    const userLanguageCode =
      typeof userLanguage === "object" ? userLanguage.code : userLanguage;
    console.log("save userLanguage " + userLanguageCode);
    localStorage.setItem("userLanguage", userLanguageCode);
  }

  if (learningLanguage) {
    const learningLanguageCode =
      typeof learningLanguage === "object"
        ? learningLanguage.code
        : learningLanguage;
    localStorage.setItem("learningLanguage", learningLanguageCode);
  }
};

// export const updateLanguages = async (userID, userLanguage, learningLanguage) => {
//     console.log('update languages ' + userLanguage + ' ' + learningLanguage)
//     if(userID) {
//         const db = getDatabase()
//         const userRef = ref(db, 'users/' + userID);

//         let dict = {}
//         if (userLanguage) {
//             dict['userLanguage'] = userLanguage
//         }

//         if (learningLanguage) {
//             dict['learningLanguage'] = learningLanguage
//         }

//         await update(userRef, dict);
//     }

//     if(userLanguage) {
//         console.log('save userlanguage ' + userLanguage)
//         localStorage.setItem('userLanguage', userLanguage);
//     }

//     if(learningLanguage) {
//         localStorage.setItem('learningLanguage', learningLanguage);
//     }
// }
