import { logEvent as firebaseLogEvent } from 'firebase/analytics';
import { analytics } from '../api/firebaseConfig'

// Utility function to log events
const logEvent = (eventName, eventParams) => {
  firebaseLogEvent(analytics, eventName, eventParams);
};

// Event logging functions
export const logEvent_LoginDialog_ClickLogin = () => {
  logEvent('login_dialog_click_login');
};

export const logEvent_LoginDialog_LoginFailed = (error) => {
  logEvent('login_dialog_login_failed', { error: error.message, code: error.code });
};

// single login success event; provider is passed as a parameter(it's set on analytics object in setUserProperties) 
export const logEvent_LoginDialog_LoginSuccess = () => {
  logEvent('login_dialog_login_success');
};

export const logEvent_LoginDialog_ClickForgotPassword = () => {
  logEvent('login_dialog_click_forgot_password');
};

export const logEvent_LoginDialog_ClickRegisterButton = () => {
  logEvent('login_dialog_click_register_button');
};

export const logEvent_LoginDialog_ClickGoogleLogin = () => {
  logEvent('login_dialog_click_google_login');
};

export const logEvent_LoginDialog_GoogleLoginFailed = (error) => {
  logEvent('login_dialog_google_login_failed', { error: error.message, code: error.code });
};

export const logEvent_LoginDialog_ClickAppleLogin = () => {
  logEvent('login_dialog_click_apple_login');
};

export const logEvent_LoginDialog_AppleLoginFailed = (error) => {
  logEvent('login_dialog_apple_login_failed', { error: error.message, code: error.code });
};

export const logEvent_LoginDialog_ClickTwitterLogin = () => {
  logEvent('login_dialog_click_twitter_login');
};

export const logEvent_LoginDialog_TwitterLoginFailed = (error) => {
  logEvent('login_dialog_twitter_login_failed', { error: error.message, code: error.code });
};

export const logEvent_ForgotPassword_ValidationSuccess = () => {
  logEvent('forgot_password_validation_success');
};

export const logEvent_ForgotPassword_ValidationFailed = (email) => {
  logEvent('forgot_password_validation_failed', { email });
};

export const logEvent_ForgotPassword_SendEmailFailed = (error, email) => {
  logEvent('forgot_password_send_email_failed', { error: error.message, code: error.code, email });
};

export const logEvent_RegisterDialog_RegisterError = (error, email, dob, displayName) => {
  logEvent('register_dialog_register_error', { error: error.message, code: error.code, email, dob, displayName });
};

export const logEvent_RegisterDialog_RegisterSuccess = () => {
  logEvent('register_dialog_register_success');
};

export const logEvent_Sentences_ClickAddSentence = () => {
  logEvent('sentences_click_add_sentence');
};

export const logEvent_Sentences_AddSentenceError = (error) => {
  logEvent('sentences_add_sentence_error', { error: error.message, code: error.code });
};

export const logEvent_Sentences_ClickDeleteSentence = () => {
  logEvent('sentences_click_delete_sentence');
};

export const logEvent_DeleteSentenceDialog_Show = () => {
  logEvent('delete_sentence_dialog_show');
};

export const logEvent_DeleteSentenceDialog_Close = () => {
  logEvent('delete_sentence_dialog_close');
};

export const logEvent_DeleteSentenceDialog_ClickSend = () => {
  logEvent('delete_sentence_dialog_click_send');
};

export const logEvent_DeleteSentenceDialog_Success = () => {
  logEvent('delete_sentence_dialog_success');
};

export const logEvent_DeleteSentenceDialog_Error = (error, documentID, sentenceID) => {
  logEvent('delete_sentence_dialog_error', { error: error.message, code: error.code, documentID, sentenceID });
};

export const logEvent_TranslateWord_Success = (text, fromCode, toCode) => {
  logEvent('translate_word_success', { text, from_code: fromCode, to_code: toCode });
};

export const logEvent_TranslateWord_ClickCopy = () => {
  logEvent('translate_word_click_copy');
};

export const logEvent_TranslateWord_ClickClear = () => {
  logEvent('translate_word_click_clear');
};

export const logEvent_TranslateWord_ClickPronounceSuccess = () => {
  logEvent('translate_word_click_pronounce_success');
};

export const logEvent_TranslateWord_ClickPronounceError = (error, text, fromCode, toCode) => {
  logEvent('translate_word_click_pronounce_error', { error: error.message, code: error.code, text, from_code: fromCode, to_code: toCode });
};

export const logEvent_TranslateWord_ClickCreateSnippet = () => {
  logEvent('translate_word_click_create_snippet');
};

export const logEvent_DictionarySearch_Success = (term, languageCode, userLanguageCode, elapsedTime) => {
  logEvent('dictionary_search_success', { term, language_code: languageCode, user_language_code: userLanguageCode, elapsed_time: elapsedTime});
};

export const logEvent_DictionarySearch_Error = (error, term, languageCode, userLanguageCode, elapsedTime) => {
  logEvent('dictionary_search_error', { term, language_code: languageCode, user_language_code: userLanguageCode, error: error.message, code: error.code, elapsed_time: elapsedTime });
};

export const logEvent_CheckSentenceDialog_Open = () => {
  logEvent('check_sentence_dialog_open');
};

export const logEvent_CheckSentenceDialog_Close = () => {
  logEvent('check_sentence_dialog_close');
};

export const logEvent_CheckSentenceDialog_CheckUserTranslationSuccess = () => {
  logEvent('check_sentence_dialog_check_user_translation_success');
};

export const logEvent_CheckSentenceDialog_CheckUserTranslationError = (error, requestJSON) => {
  logEvent('check_sentence_dialog_check_user_translation_error', { error: error.message, code: error.code, requestJSON });
}

export const logEvent_CheckSentenceDialog_CheckSentenceSuccess = () => {
  logEvent('check_sentence_dialog_check_sentence_success');
}

export const logEvent_CheckSentenceDialog_CheckSentenceError = (error, requestJSON) => {
  logEvent('check_sentence_dialog_check_sentence_error', { error: error.message, code: error.code, requestJSON });
}

export const logEvent_CheckSentenceDialog_Error = (error, requestJSON) => {
  logEvent('check_sentence_dialog_error', { error: error.message, code: error.code, requestJSON });
};

export const logEvent_CheckAllSentencesDialog_Open = () => {
  logEvent('check_all_sentences_dialog_open');
};

export const logEvent_CheckAllSentencesDialog_Close = () => {
  logEvent('check_all_sentences_dialog_close');
};

export const logEvent_CheckAllSentencesDialog_Success = () => {
  logEvent('check_all_sentences_dialog_success');
};

export const logEvent_CheckAllSentences_Error = (error, requestJSON) => {
  logEvent('check_all_sentences_error', { error: error.message, code: error.code, requestJSON });
};

export const logEvent_Writing_CreateDocumentSuccess = () => {
  logEvent('writing_create_document_success');
};

export const logEvent_Writing_CreateDocumentError = (error, languageCode, title) => {
  logEvent('writing_create_document_error', { error: error.message, code: error.code, language_code: languageCode, title });
};

export const logEvent_Writing_LoadMoreClick = (languageCode, currentItemsCount) => {
  logEvent('writing_load_more_click', { language_code: languageCode, currentItemsCount });
};

export const logEvent_Writing_DocumentsLoaded = (languageCode) => {
  logEvent('writing_documents_loaded', { language_code: languageCode });
};

export const logEvent_Classrooms_CreateClassroomSuccess = (languageCode) => {
  logEvent('classrooms_create_classroom_success', { language_code: languageCode });
};

export const logEvent_Classrooms_CreateClassroomError = (error) => {
  logEvent('classrooms_create_classroom_error', { error: error.message, code: error.code });
};

export const logEvent_Classroom_AddClassroomSnippetSuccess = () => {
  logEvent('classroom_add_classroom_snippet_success');
};

export const logEvent_UserSnippets_WordDetails_SeeExamplesSuccess = (word, languageCode) => {
  logEvent('user_snippets_word_details_see_examples_success', { word, language_code: languageCode });
}

export const logEvent_UserSnippets_WordDetails_SeeExamplesError = (error, word, languageCode) => {
  logEvent('user_snippets_word_details_see_examples_error', { error: error.message, code: error.code, word, language_code: languageCode });
}

export const logEvent_UserSnippets_WordDetails_PronounceSuccess = (word, languageCode) => {
  logEvent('user_snippets_word_details_pronounce_success', { word, language_code: languageCode });
}

export const logEvent_UserSnippets_WordDetails_PronounceError = (error, word, languageCode) => {
  logEvent('user_snippets_word_details_pronounce_error', { error: error.message, code: error.code, word, language_code: languageCode });
}

export const logEvent_AddSnippet_SearchDictionarySuccess = (term, languageCode, userLanguageCode, elapsedTime) => {
  logEvent('add_snippet_dialog_search_dictionary_success', { term, language_code: languageCode, user_language_code: userLanguageCode, elapsed_time: elapsedTime }); 
}

export const logEvent_AddSnippet_SearchDictionaryError = (error, term, languageCode, userLanguageCode, elapsedTime) => {
  logEvent('add_snippet_dialog_search_dictionary_error', { error: error.message, code: error.code, term, language_code: languageCode, user_language_code: userLanguageCode, elapsed_time: elapsedTime });
}