import { copyJSONObject } from "../utils/utils"

export function findStoryMapping(story, mappingID) {
    
    for(let sentence of story.sentences) {
        let mappings = sentence.mappings ?? [] // mappings can be null in new_line objects
        for(let mapping of mappings) {
            if(mapping.id === mappingID) {
                return mapping
            }
        }
    }

    console.log(`Mapping ${mappingID} not found in story ${JSON.stringify(story, null, 2)}`)
    return null
}

export function findStorySnippet(story, snippetID) {
    
    for(let sentence of story.sentences) {
        let mappings = sentence.mappings ?? [] // mappings can be null in new_line objects
        for(let mapping of mappings) {
            let snippets = mapping.snippets ?? []
            for(let snippet of snippets) {
                if(snippet.id === snippetID) {
                    return snippet
                }
            }
        }
    }

    console.log(`Snippet ${snippetID} not found in story ${JSON.stringify(story, null, 2)}`)
    return null
}

export function replaceStoryMapping(story, mappingID, newMapping) {
    let copy = copyJSONObject(story)
    for(let sentence of copy.sentences) {
        let mappings = sentence.mappings ?? []
        for(let i = 0; i < mappings.length; i++) {
            if(mappings[i].id === mappingID) {
                sentence.mappings[i] = newMapping; // Modify the array directly
                return copy; // Exit after replacing
            }
        }
    }

    return copy
}
