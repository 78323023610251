import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleEnterKeyPress } from "../utils/helper";

function DeleteConfirmationModal({ isOpen, onClose, onConfirm }) {
  const [deleteWords, setDeleteWords] = useState(["delete"]);
  const [inputValue, setInputValue] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // handle special cases for now (cyrillic)
    let deleteWord = t("sentences.delete").toLowerCase();
    if (deleteWord === "избриши") {
      setDeleteWords(["избриши", "izbrisi"]);
    } else {
      setDeleteWords([deleteWord]);
    }

    if (isOpen) {
      setIsDeleting(false);
      setInputValue("");
    }
    setDialogOpen(isOpen);
  }, [isOpen]);

  const onDeleteConfirm = () => {
    setIsDeleting(true);
    onConfirm();
  };
  const isValidDeleteWord = deleteWords.some(
    (str) => str.toLowerCase() === inputValue.toLowerCase()
  );

  const handleKeyDown = (e) => {
    if (isValidDeleteWord) {
      handleEnterKeyPress(e, onDeleteConfirm);
    }
  };

  return (
    <Modal isOpen={isDialogOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgImage="url('/icons/dialog.png')"
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <ModalHeader color="white">
          {t("sentences.delete_document")}
        </ModalHeader>
        <ModalBody color="white">
          {t("sentences.confirm_delete_document", { delete: deleteWords[0] })}
          <Input
            mt={4}
            placeholder={deleteWords[0]}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} isDisabled={isDeleting}>
            {t("modal.cancel")}
          </Button>
          <Button
            colorScheme="red"
            mr={3}
            isLoading={isDeleting}
            onClick={onDeleteConfirm}
            isDisabled={
              deleteWords.filter((str) => {
                return str.toLowerCase() === inputValue.toLowerCase();
              }).length === 0
            }
          >
            {t("sentences.delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteConfirmationModal;
