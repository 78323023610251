import { atom } from "recoil";

// a flag that is false by default, but the pages can set it to true if the page is valid to
// allow header and footer to be rendered
export const isPageValidState = atom({
    key: 'pageValidState',
    default: false,
});

export const mergeDataState = atom({
    key: 'mergeDataState',
    default: {},
});