import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import { t, use } from "i18next";
import { auth, signInWithGooglePopup } from "../api/firebaseConfig";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { validateEmail } from "../utils/utils";
import { handleEnterKeyPress } from "../utils/helper";
import { logEvent_ForgotPassword_SendEmailFailed } from "../analytics/analytics";

function ForgotPassword({ onOpen }) {
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  const emailRef = useRef(null);

  let toast = useToast();

  const handleSend = () => {
    setError(null);

    if (!validateEmail(email)) {
      setError(t("register.invalid_email"));
      return;
    }

    setIsSending(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        // toast({
        //     title: t('forgot_password.title'),
        //     description: t('forgot_password.success', { email: email }),
        //     status: "success",
        //     position: 'top',
        //     duration: 7000,
        //     isClosable: true,
        // })
        setDone(true);
        setTimeout(() => {
          emailRef.current.focus();
        }, 100);
      })
      .catch((error) => {
        logEvent_ForgotPassword_SendEmailFailed(error, email)
        // extract error code from parentheses(full message is 'FirebaseError: Firebase: Error (auth/user-not-found).')
        let errorCode = error.message.match(/\(([^)]+)\)/)[1];
        switch (errorCode) {
          case "auth/user-not-found":
            setError(t("login.user_not_found"));
            break;
          case "auth/invalid-email":
            setError(t("login.invalid_email"));
            break;
          default:
            setError(error.message);
            break;
        }
        setTimeout(() => {
          emailRef.current.focus();
        }, 100);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  useEffect(() => {
    handlePopoverOpen();
  }, [onOpen]);

  const handlePopoverOpen = () => {
    setTimeout(() => {
      setError(null);
      setDone(false);
      setEmail("");
      emailRef.current.focus();
    }, 100);
  };

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" p={2} mb={0} color="white">
        {t("forgot_password.title")}
      </Text>
      <VStack ml={2} color="white" spacing={4}>
        <Text color="white">{t("forgot_password.description")}</Text>
        <Input
          isDisabled={done}
          ref={emailRef}
          color="white"
          placeholder={t("login.email_placeholder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => handleEnterKeyPress(e, handleSend)}
        />
        {!done && (
          <>
            <Button
              colorScheme="blue"
              w="full"
              isLoading={isSending}
              onClick={handleSend}
            >
              {t("forgot_password.send")}
            </Button>
            <Text color="red.500">{error}</Text>
          </>
        )}
        {done && (
          <Text color="white">
            {t("forgot_password.success", { email: email })}
          </Text>
        )}
      </VStack>
    </>
  );
}

export default ForgotPassword;
