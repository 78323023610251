import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Text, VStack, Divider } from "@chakra-ui/react";
import GoogleSignInButton from "../auth/google-button";
import { t, use } from "i18next";
import { auth, signInWithGooglePopup } from "../api/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { validateEmail } from "../utils/utils";
import { Constants } from "../utils/constants";
import { handleEnterKeyPress } from "../utils/helper";
import { logEvent_LoginDialog_ClickGoogleLogin, logEvent_LoginDialog_ClickLogin, logEvent_LoginDialog_LoginFailed } from "../analytics/analytics";

function LoginContent({ onRegister, onForgotPassword, onOpen }) {
  const [email, setEmail] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [password, setPassword] = useState("");
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function onGoogleAuth() {
    try {
      logEvent_LoginDialog_ClickGoogleLogin()
      const response = await signInWithGooglePopup();
      console.log(response);
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        return;
      }
      console.log("Google auth error occurred: " + error.message);
    }
  }

  const handleLogin = () => {
    setLoginError(null);

    if (!validateEmail(email)) {
      setLoginError(t("register.invalid_email"));
      return;
    }

    let minLength = Constants.PASSWORD_MIN_LENGTH;
    if (password.length < minLength) {
      setLoginError(t("register.password_length_error", { length: minLength }));
      return;
    }

    setIsLoggingIn(true);
    logEvent_LoginDialog_ClickLogin()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Successfully logged in
        // onLoginSuccess(userCredential.user)
      })
      .catch((error) => {
        logEvent_LoginDialog_LoginFailed(error)

        switch (error.code) {
          case "auth/user-not-found":
            setLoginError(t("login.user_not_found"));
            break;
          case "auth/wrong-password":
            setLoginError(t("login.wrong_password"));
            break;
          case "auth/invalid-email":
            setLoginError(t("login.invalid_email"));
            break;
          case "auth/missing-password":
            setLoginError(t("login.missing_password"));
            break;
          default:
            setLoginError(error.message);
            break;
        }

        console.error("Error logging in:", error);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  useEffect(() => {
    handlePopoverOpen();
  }, [onOpen]);

  const handlePopoverOpen = () => {
    setTimeout(() => {
      if (!email || !validateEmail(email)) {
        setLoginError(null);
        emailRef.current.focus();
      } else if (!password) {
        passwordRef.current.focus();
      }
    }, 100);
  };

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" p={2} mb={2} color="white">
        {t("header.login")}
      </Text>
      <VStack ml={2} color="white" spacing={4}>
        <Input
          data-test="email-input"
          ref={emailRef}
          color="white"
          placeholder={t("login.email_placeholder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => handleEnterKeyPress(e, handleLogin)}
        />
        <VStack w="full" spacing={2}>
          <Input
            data-test="password-input"
            ref={passwordRef}
            color="white"
            placeholder={t("login.password_placeholder")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => handleEnterKeyPress(e, handleLogin)}
          />
          <Button
            data-test="forgot-password-button"
            alignSelf="flex-end"
            onClick={onForgotPassword}
            variant="link"
            colorScheme="blue"
          >
            {t("register.forgot_password")}
          </Button>
        </VStack>

        <Button
          colorScheme="blue"
          w="full"
          isLoading={isLoggingIn}
          onClick={handleLogin}
          data-test="login-action-button"
        >
          {t("login.login_button")}
        </Button>
        <GoogleSignInButton onSignIn={onGoogleAuth} />
        <Divider />
        <Text color="white" fontSize="sm" alignSelf="center">
          {t("register.no_account_yet")}{" "}
          <Button data-test='register-button' onClick={onRegister} variant="link" colorScheme="blue">
            {t("login.register_link")}
          </Button>
        </Text>
        <Text color="red.500">{loginError}</Text>
      </VStack>
    </>
  );
}

export default LoginContent;
