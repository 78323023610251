import { httpsCallable } from "@firebase/functions";
import { database, functions } from "./firebaseConfig";
import { get, ref } from "@firebase/database";
import { translateText } from "./translation.service";

export async function fetchExamples(term, termLanguageCode, userLanguageCode, ignoreKeys, tryCache = true) {
    if(tryCache) {
        let cachedExamples = await fetchCachedExamples(term, termLanguageCode, userLanguageCode)
        console.log('cachedExamples ' + JSON.stringify(cachedExamples, null, 2))
        if(cachedExamples) {
            return cachedExamples
        }
    }
    const fetchExamples = httpsCallable(functions, 'recommendations-get_example_word')
    return (await fetchExamples({
        descriptor: { term: term },
        termLanguageCode,
        userLanguageCode,
        ignoreKeys
    }))?.data
}

export async function fetchCachedExamples(term, termLanguageCode, userLanguageCode) {

    const snapshot = await get(ref(
        database,
        `word_examples/${termLanguageCode}/${term}/`,
    ))

    let examples = []
    let dict = snapshot.val()
    console.log('examples dict ' + JSON.stringify(dict, null, 2))
    if (snapshot.exists() && dict && typeof dict === 'object') {
        for (const key in dict) {
            let exampleObject = dict[key]
            let text = exampleObject.text
            let translation = exampleObject.translations?.[userLanguageCode]
            console.log(`text ${text} translation ${translation} exampleObject ${JSON.stringify(exampleObject, null, 2)}`)
            if (!text || !translation) {
                continue
            }

            examples.push({ id: key, text, translation })
            // if (exampleObject.translations?.[userLanguageCode]) {
            //     examples.push(exampleObject)
            // } else {
            //     // missing translation
            //     function removeTags(input) {
            //         return input.replace(/<[^>]*>/g, '');
            //     }
            //     let translated = await translateText(removeTags(text), 'en', userLanguageCode)
                
            // }
        }

        if (examples.length > 0) {
            console.log('Returning examples ' + JSON.stringify(examples, null, 2))
            return examples
        }
    }

    return null
}