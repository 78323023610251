import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebaseConfig";

// returns a base64 encoded audio string(mp3)
export const convertTextToSpeech = async (text, languageCode) => {
    const synthesizeSpeech = httpsCallable(functions, 'tts-synthesize_speech');
    let data = {
        text,
        languageCode
    }

    console.log('Request ' + JSON.stringify(data))
    const result = await synthesizeSpeech(data)

    console.log('Response ' + JSON.stringify(result.data))

    return result.data.audioContent
}
