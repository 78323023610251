import {
  Box,
  Button,
  HStack,
  Image,
  Spinner,
  Text,
  useToast,
  Show,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import {
  classroomIDState,
  classroomPermissionsState,
} from "../../state/classroom-state";
import React, { useEffect, useRef, useState } from "react";
import {
  isAuthenticationFinishedState,
  userLanguageState,
  userState,
} from "../../state/user-state";
import {
  getClassroomSessionID,
  handleClassroomJoinRequest,
} from "../../api/classroom.service";
import { JoinClassroomAction, JoinClassroomUserState } from "./classroom-model";
import { capitalize, focusHTMLElementDelayed } from "../../utils/utils";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  List,
  ListItem,
} from "@chakra-ui/react";
import { set } from "@firebase/database";
import { useTranslation } from "react-i18next";
import { ClassroomRoleEnum } from "./classroom-role";
import { motion } from "framer-motion";

function AdminHeaderPanel() {
  const [classroomID, setClassroomID] = useRecoilState(classroomIDState);
  const [isAuthenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [user, setUser] = useRecoilState(userState);
  const [permissionsState, setPermissionsState] = useRecoilState(
    classroomPermissionsState
  );
  const [submittingKeys, setSubmittingKeys] = useState({});

  const [isAdmin, setIsAdmin] = useState(false);

  const [userList, setUserList] = useState([]);

  const [role, setRole] = useState(null);
  const [hasAlert, setHasAlert] = useState(false);
  const [numberOfUsers, setNumberOfUsers] = useState(0);

  const rootRef = React.useRef(null);

  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    if (!classroomID || !permissionsState || !isAuthenticationFinished) {
      return;
    }
    let userKey = null;

    if (user?.uid) {
      userKey = user.uid;
    } else {
      userKey = getClassroomSessionID(classroomID);
    }

    if (!userKey) {
      setUserList([]);
      return;
    }

    let permissions = permissionsState;
    let hasAlert = false;
    let role = "";

    let hasPermissions =
      permissions[userKey]?.state === JoinClassroomUserState.ACCEPTED ?? false;

    if (!hasPermissions) {
      setRole(null);
      setUserList([]);
      return;
    }

    let isAdmin = permissions[userKey] && permissions[userKey].isOwner;

    setIsAdmin(isAdmin);
    let usersList = [];
    for (let key in permissions) {
      let dict = permissions[key];
      if (key === userKey) {
        let roleText = t(`classrooms.role_${dict.role}`);
        if (roleText) {
          role = roleText;
        } else {
          role = capitalize(dict.role);
        }
      }

      if (isAdmin) {
        if (dict.state === JoinClassroomUserState.REJECTED) {
          continue;
        } else if (dict.state === JoinClassroomUserState.PENDING) {
          hasAlert = true;
        }
      } else {
        if (
          dict.state === JoinClassroomUserState.REJECTED ||
          dict.state === JoinClassroomUserState.PENDING
        ) {
          continue;
        }
      }

      let user = { ...dict };
      user.key = key;
      usersList.push(user);
    }

    const sortedUsers = usersList.sort((a, b) => {
      if (a.state !== b.state) {
        // Sort by state
        if (a.state === "pending") {
          return -1; // a should come before b
        } else {
          return 1; // b should come before a
        }
      } else if (a.role !== b.role) {
        // Sort by state
        if (a.role === ClassroomRoleEnum.TEACHER) {
          return -1; // a should come before b
        } else {
          return 1; // b should come before a
        }
      } else {
        // States are the same, sort by name
        if (a.name < b.name) {
          return -1; // a should come before b
        } else if (a.name > b.name) {
          return 1; // b should come before a
        } else {
          return 0; // order remains unchanged
        }
      }
    });
    setUserList(sortedUsers);

    setRole(role);
    setHasAlert(isAdmin && hasAlert);
    setNumberOfUsers(usersList.length);
  }, [
    permissionsState,
    user,
    classroomID,
    isAuthenticationFinished,
    userLanguage,
    t,
  ]);

  useEffect(() => {
    console.log(
      `classroomID ${classroomID} role ${role} hasAlert ${hasAlert} numberOfUsers ${numberOfUsers}`
    );
  }, [classroomID, role, hasAlert, numberOfUsers]);

  async function handleAllowJoin(key, action) {
    let parent = rootRef.current;
    for (let i = 0; i < 5; i++) {
      if (parent) {
        focusHTMLElementDelayed(parent, 400);
        parent = parent?.parentElement;
      }
    }
    setSubmittingKeys({ ...submittingKeys, [key]: true });
    let joinAction = action
      ? JoinClassroomAction.ACCEPT
      : JoinClassroomAction.REJECT;
    try {
      console.log(
        "calling handleJoin with key " +
          key +
          " action " +
          joinAction +
          " classroomID " +
          classroomID
      );
      await handleClassroomJoinRequest(classroomID, key, joinAction);
    } catch (error) {
      toast({
        title: t("classrooms.error_allow_join"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmittingKeys({ ...submittingKeys, [key]: false });
    }
  }

  const animationArray = () => {
    let scaleDown = 0.85;
    let scaleUp = 1.15;
    let arr = [1, scaleUp, scaleDown];
    for (let i = 0; i < 100; i++) {
      arr.push(scaleUp, scaleDown);
    }
    return arr;
  };

  const animationScale = hasAlert ? animationArray() : 1;

  return (
    <Box>
      {classroomID && role && numberOfUsers && (
        <Box
          color="white"
          h="100%"
          borderWidth={1}
          borderColor="gray.100"
          borderRadius={8}
        >
          <HStack h="100%">
            <Popover>
              <PopoverTrigger>
                <motion.div
                  style={{ willChange: "transform" }} // Optimizing for performance
                  animate={{
                    scale: animationScale, // Scale animation based on hasAlert
                  }}
                  transition={{
                    duration: 200, // Transition duration
                    loop: Infinity, // Loop infinitely
                    repeatType: "loop",
                  }}
                >
                  <Box
                    h="30px"
                    w="30px"
                    m={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%" // Makes it a circle
                    backgroundColor={hasAlert ? "red" : "lightblue"}
                    color={hasAlert ? "white" : "black"}
                    cursor="pointer"
                  >
                    <Text style={{ fontWeight: "bold" }}>{numberOfUsers}</Text>
                  </Box>
                </motion.div>
              </PopoverTrigger>

              <PopoverContent
                bgImage="url('/icons/dialog.png')"
                bgSize="cover"
                bgRepeat="no-repeat"
                borderColor="dialogBorder"
              >
                <PopoverBody color="white">
                  <Text ref={rootRef} fontSize="xl" mb={2} textAlign="center">
                    {t("classrooms.user_list_title")}
                  </Text>
                  <List>
                    {userList.map((user) => (
                      <ListItem key={user.key} mb={2}>
                        <HStack justifyContent="space-between">
                          <HStack>
                            <Image
                              src={
                                user.role === ClassroomRoleEnum.STUDENT
                                  ? "/icons/student.png"
                                  : "/icons/teacher.png"
                              }
                              alt={user.role}
                              width="32px"
                              height="32px"
                            />
                            {isAdmin && (
                              <Box
                                display="inline-block"
                                borderRadius="50%"
                                backgroundColor={
                                  user.state === JoinClassroomUserState.PENDING
                                    ? "yellow"
                                    : "green"
                                }
                                width="10px"
                                height="10px"
                                marginRight={1}
                              />
                            )}
                            <Text>{user.name}</Text>
                          </HStack>

                          {user.state === "pending" && (
                            <Box>
                              {submittingKeys[user.key] ? (
                                <Spinner />
                              ) : (
                                <>
                                  <Button
                                    variant="solid"
                                    colorScheme="green"
                                    size="sm"
                                    marginRight="5px"
                                    onClick={() => {
                                      handleAllowJoin(user.key, true);
                                    }}
                                  >
                                    {t("classrooms.allow_to_join")}
                                  </Button>
                                  <Button
                                    variant="solid"
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => {
                                      handleAllowJoin(user.key, false);
                                    }}
                                  >
                                    {t("classrooms.reject_join")}
                                  </Button>
                                </>
                              )}
                            </Box>
                          )}
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Show above="sm">
              <Box>
                <Text mr={2}>{role}</Text>
              </Box>
            </Show>
          </HStack>
        </Box>
      )}
    </Box>
  );
}

export default AdminHeaderPanel;
