
// clasroom state
export const ClassroomState = {
    CREATED: 'created',
    ACTIVE: 'active',
    FINISHED: 'finished'
};

// everyone-with-link, invited-users
export const ClassroomJoinRule = {
    EVERYONE_WITH_LINK: 'everyone_with_link',
    INVITED_USERS: 'invited_users'
}

export const SharedConstants = {
    Dictionary: {
        Tense: {
            Spanish: {
                "infinitive": "Infinitive",
                "gerund": "Gerund",
                "present": "Present",
                "imperfect": "Imperfect",
                "preterite": "Preterite",
                "future": "Future",
                "past_participle": "Past Participle",
                "conditional": "Conditional",
                "subjunctive_present": "Subjunctive Present",
                "subjunctive_imperfect_ra": "Subjunctive Imperfect (ra)",
                "subjunctive_imperfect_se": "Subjunctive Imperfect (se)",
                "subjunctive_future": "Subjunctive Future",
                "imperative_affirmative": "Imperative Affirmative",
                "imperative_negative": "Imperative Negative",
            },
            Russian: [
                { key: "infinitive",
                  value: "Infinitive"
                },
                { key: "present",
                  value: "Present"
                },
                { key: "past",
                  value: {
                    "value": "past",
                    "feminine": "Feminine",
                    "masculine": "Masculine",
                    "neuter": "Neuter",
                    "plural": "Plural"
                    }
                },
                { key: "future",
                  value: "Future"
                },
                { key: "participle",
                  value: {
                    "value": "Participle",
                    "past": {
                        "value": "Past",
                        "active": "Active",
                        "passive": "Passive",
                        "adverbial": "Adverbial"
                    },
                    "present": {
                        "value": "Present",
                        "active": "Active",
                        "advertial": "Adverbial"
                    }
                    }
                },
                { key: "imperative",
                  value: {
                    "value": "Imperative",
                    "singular": "Singular",
                    "plural": "Plural"
                    }
                },
            ]
        }
    }
}
