import { httpsCallable } from "@firebase/functions";
import { functions, database } from "./firebaseConfig";
import { get, onValue, ref, serverTimestamp, set, update } from "@firebase/database";
import { Constants } from "../utils/constants";
import { copyJSONObject, numberOfComponentsInLayoutString, randomID } from "../utils/utils";
import { loadSnippets, parseSnippetsDict } from "./sentences.service";
import { findSnippetHeaderValues, findSnippetTextValues, localizeSnippetModels, prepareSnippets } from "../utils/snippet-utils";
import { ClassroomRoleEnum } from "../components/classrooms/classroom-role";

export const ClassroomSnippetsContentType = {
    ALL: 'all', // user snippets including snippets shown in other components
    OTHER: 'other', // snippets not shown in other components; you can have only one of these
    SELECTED: 'selected', // selected snippets
    CLASSROOM: 'classroom' // classroom snippets
}

export const ClassroomLayoutComponentType = {
    NOTES: 'notes',
    SENTENCES: 'sentences',
    NOTES_SENTENCES: 'notes_sentences',
    SNIPPETS: 'snippets',
    DICTIONARY_SEARCH: 'dictionary_search',
    TRANSLATE_WORD: 'translate_word'
}

export function createClassroom(classroomName, role, languageCode) {
    const createClassroomCall = httpsCallable(functions, 'classrooms-create_classroom')
    return createClassroomCall({ name: classroomName, role, learningLanguageCode: languageCode })
}

export function requestToJoinClassroom(classroomID, role, name) {
    const requestToJoinClassroomCall = httpsCallable(functions, 'classrooms-request_to_join_classroom')
    return requestToJoinClassroomCall({ classroomKey: classroomID, role, name })
}

export function handleClassroomJoinRequest(classroomID, sessionID, action) {
    const handleJoinRequestCall = httpsCallable(functions, 'classrooms-manage_join_request')
    return handleJoinRequestCall({ action, classroomID, requestKey: sessionID })
}

export const saveClassroomSentences = async (classroomID, sentences) => {
    if(!classroomID) {
        console.error('saveClassroomSentences called with no classroomID')
        return
    }

    const documentRef = ref(database, `classroom/${classroomID}/sentences`)

    const sentencesDict = {}
    sentences.forEach((sentence, index) => {
        sentencesDict[index] = sentence
    })
    return set(documentRef, sentencesDict)
}

export function fetchAnonymousUserClassroomLayout(role) {
    // fetch layout from local storage
    let key = role === ClassroomRoleEnum.TEACHER ? Constants.LocalStorage.ANONYMOUS_USER_CLASSROOM_LAYOUT_TEACHER : Constants.LocalStorage.ANONYMOUS_USER_CLASSROOM_LAYOUT_STUDENT

    let layout = localStorage.getItem(key)

    if(!layout) {
        // fetch default layout
        layout = role === ClassroomRoleEnum.TEACHER ? Constants.DynamicLayout.DEFAULT_TEACHER_LAYOUT : Constants.DynamicLayout.DEFAULT_STUDENT_LAYOUT
    }

    console.log('Returning anonymous user layout ' + JSON.stringify(layout))
    return layout
}

export async function fetchUserClassroomLayout(classroomID, userID, role) {
    
    try {
        let data = await fetchClassroomByID(classroomID)
        let layout = null
        
        let languageCode = data?.languageCode ?? 'en'

        layout = await fetchClassroomLayout(userID, languageCode, role)
        
        if(!layout) {
            layout = role === ClassroomRoleEnum.TEACHER ? Constants.DynamicLayout.DEFAULT_TEACHER_LAYOUT : Constants.DynamicLayout.DEFAULT_STUDENT_LAYOUT
        }

        console.log('returning lay ' + JSON.stringify(layout))

        return layout
        
    } catch (error) {
        console.error("Error fetching classroom:", error);
    }
}

export async function createLayout(allSnippets, allClassSnippets, layout, userRole, dynamicLayoutComponentDataStateSetter, t) {
    let snippets = copyJSONObject(allSnippets)
    let classSnippets = copyJSONObject(allClassSnippets)
    console.log('createLayoutSnippets ' + JSON.stringify(snippets) + ' class snippets ' + JSON.stringify(classSnippets))
    let snippetsLayoutData = {}
    // get sort option and sort order for each layout component from recoil state
    let count = numberOfComponentsInLayoutString(layout.layout)
    for(let i = 0; i < count; i++) {
        // let sortOption = localStorage.getItem(Constants.LocalStorage.SNIPPETS_SORT_OPTION_DYNAMIC(i)) ?? Constants.SORT_OPTION_DEFAULT
        // let sortOrder = localStorage.getItem(Constants.LocalStorage.SNIPPETS_SORT_ORDER_DYNAMIC(i)) ?? Constants.SORT_ORDER_DEFAULT

        let componentData = layout.components[i]               

        snippetsLayoutData[i] = {
            // sortOption,
            // sortOrder,
        }

        if(componentData.type === ClassroomLayoutComponentType.SNIPPETS && componentData.filter) {
            let componentSnippets = []

            snippetsLayoutData[i].canEditSnippets = true
            switch(componentData.filter) {
                case ClassroomSnippetsContentType.SELECTED:
                    let items = componentData.items ?? []
                    componentSnippets = snippets.filter((snippet) => items.includes(snippet.id))
                    break;
                case ClassroomSnippetsContentType.ALL:
                    componentSnippets = [ ...snippets]
                    break;
                case ClassroomSnippetsContentType.CLASSROOM:
                    let classroomSnippets = classSnippets ?? {}
                    for (let key in classroomSnippets) {
                        let snippet = classroomSnippets[key]
                        // if(!snippet.id) {
                        //     snippet.id = key
                        // }
                        componentSnippets.push(snippet)
                    }
                    snippetsLayoutData[i].titleKey = 'classrooms.classroom_snippets_title'
                    snippetsLayoutData[i].canEditSnippets = userRole === ClassroomRoleEnum.TEACHER
                    snippetsLayoutData[i].classroomSnippets = true
                    break;
                default:
                    componentSnippets = []
                    break;
            }   

            snippetsLayoutData[i].snippets = prepareSnippets(componentSnippets, t)
        }
    }

    // call recoil state setter for dynamicLayoutComponentDataState
    dynamicLayoutComponentDataStateSetter(snippetsLayoutData)
}

// fetches JSON from the realtime database path /classrooms/{classroomID}
export async function fetchClassroomByID(classroomID) {
    console.log('fetchClassroomByID ' + classroomID)
    let reference = ref(database, 'classroom/' + classroomID);
    try {
        const snapshot = await get(reference);
        if (snapshot.exists()) {
            let dict = snapshot.val() ?? {};
            if(checkIsClassroomValid(dict)) {
                console.log('Success, returning classroom ' + JSON.stringify(dict))
                return Promise.resolve(dict);
            }
        } else {
            console.error("Classroom not found, snapshot null");
        }
        console.log('opaaa x')
        return Promise.resolve(null);
    } catch (error) {
        console.error("Error fetching classroom:", error);
        return Promise.resolve(null);
    }
}

/*** LAYOUT ***/

export async function fetchClassroomLayout(userID, languageCode, role) {
    let key = role === ClassroomRoleEnum.TEACHER ? 'teacher_layout' : 'student_layout'
    let reference = ref(database, `user_classroom_options/${userID}/${languageCode}/${key}`)
    try {
        const snapshot = await get(reference);
        return snapshot.val() ?? null;
    } catch (error) {
        console.error("Error fetching classroom layout:", error);
        return null;
    }
}

export async function updateClassroomLayout(userID, languageCode, layoutString, components) {
    
    if(typeof layout !== 'string') {
        console.error("updateClassroomLayout called with invalid layout");
        return;
    }

    let reference = ref(database, `user_classroom_options/${userID}/${languageCode}`)

    // validate components
    for(let component of components) {
        let type = component.type;
        if(!ClassroomLayoutComponentType[type]) {
            console.error("updateClassroomLayout called with invalid component type");
            return;
        }
    }

    // TODO: Validate that number of components matches the layout string (e.g. if layout is '112', there should be 4 components)

    let dict = {
        layout: layoutString,
        components: components
    }
    return update(reference, dict);
}

// validate layout

export async function validateLayoutComponent(component) {
    // let type = component.type;
    // let filter = component.filter;

    // if(!type || !filter) {
    //     console.error("Invalid component - missing type or filter");
    //     return false;
    // }

    // if(!ClassroomLayoutComponentType[type]) {
    //     console.error("Invalid component type");
    //     return false;
    // }

    // if(!ClassroomSnippetsContentType[filter]) {
    //     return false;
    // }

    return true;
}


export async function updateNotes(classroomID, notes) {
    console.log('updateNotes' + classroomID + ' ' + notes)

    if (!classroomID) {
        console.error("updateNotes called with no classroomID");
        return;
    }

    let reference = ref(database, 'classroom/' + classroomID);
    let dict = {
        notes: notes
    }
    return update(reference, dict);
}

export function observeAdminClassroomPermissions(classroomID, callback) {
    if (!classroomID) {
        console.error("observeClassroom called with no classroomID");
        return;
    }
    let path = `/classroom_permissions/${classroomID}`;
    return observePath(path, callback)
}

export function observeClassroomPermissions(classroomID, sessionKey, callback) {
    if (!classroomID || !sessionKey) {
        console.error("observeClassroom called with no classroomID or sessionKey");
        return;
    }
    let path = `/classroom_permissions/${classroomID}/${sessionKey}`;
    console.log('observeClassroomPermissions ' + path)
    return observePath(path, callback)
}

export function observeClassroomSentences(classroomID, callback) {
    if (!classroomID) {
        console.error("observeClassroom called with no classroomID");
        return;
    }
    let path = `classroom/${classroomID}/sentences`
    return observePath(path, callback)
}

export function observeClassroomNotes(classroomID, callback) {
    if (!classroomID) {
        console.error("observeClassroom called with no classroomID");
        return;
    }
    let path = `classroom/${classroomID}/notes`
    return observePath(path, callback)
}

export function observeClassroom(classroomID, callback) {
    if (!classroomID) {
        console.error("observeClassroom called with no classroomID");
        return;
    }
    let path = `classroom/${classroomID}`
    return observePath(path, callback)
}

export function observeClassroomSnippets(classroomID, callback) {
    if (!classroomID) {
        console.error("observeClassroom called with no classroomID");
        return;
    }
    let path = `classroom/${classroomID}/snippets`
    console.log('Observing classroom snippets ' + path)
    return observePath(path, callback)
}

export function observePath(path, callback) {
    console.log('observePath ' + path)
    let reference = ref(database, path)
    return onValue(reference, (snapshot) => {
        console.log(`onValue (${path}) ` + JSON.stringify(snapshot.val()))
        let dict = null
        if (snapshot.exists()) {
            dict = snapshot.val() ?? {}
        } 
        if(!callback) {
            console.error('observePath called with no callback')
        }
        callback?.(dict);
    });
}

function checkIsClassroomValid(dict) {
    return true
}

/*** Classroom snippets  ***/

export function addEditClassroomSnippet(classroomID, snippet, snippetID) {
    console.log('addEditClassroomSnippet ' + classroomID + ' ' + JSON.stringify(snippet))
    let reference = ref(database, `classroom/${classroomID}/snippets`)
    let key = snippetID ?? randomID()
    
    // return Promise.resolve()
    return update(reference, { [key]: snippet }).then(() => {
        snippet.id = key
        return snippet
    })
}

export function deleteClassroomSnippet(classroomID, snippetKey) {
    let path = `classroom/${classroomID}/snippets`
    let reference = ref(database, path)

    // delete refence at path
    return update(reference, { [snippetKey]: null });
}

export function mergeClassrooms(classroomsSessionsDict) {
    const mergeCall = httpsCallable(functions, 'classrooms-merge_classrooms')
    return mergeCall({ classrooms: classroomsSessionsDict})
}

/*** Classroom session ID local storage (for unauthed users)  ***/

export function getClassroomSessionID(classroomID) {
    let sessionsString = localStorage.getItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT) ?? '{}'
    let sessionsDict = JSON.parse(sessionsString) ?? {}

    return sessionsDict[classroomID]
}

export function saveClassroomSessionID(classroomID, sessionID) {
    let sessionsString = localStorage.getItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT) ?? '{}'
    let sessionsDict = JSON.parse(sessionsString) ?? {}

    sessionsDict[classroomID] = sessionID

    localStorage.setItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT, JSON.stringify(sessionsDict))
}

// session management

export async function getClassroomPermissionsForSession(sessionID) {
    const checkPermissionsCall = httpsCallable(functions, 'classrooms-get_classroom_permissions')
    try {
        return (await checkPermissionsCall({ session: sessionID })).data
    } catch(err) {
        return Promise.resolve(null)
    }
}

export async function getClassroomPermissionsForLoggedInUser() {
    const checkPermissionsCall = httpsCallable(functions, 'classrooms-get_classroom_permissions')
    try {
        return (await checkPermissionsCall()).data
    } catch(err) {
        return Promise.resolve(null)
    }
}