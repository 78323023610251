
import { useEffect, useState } from "react";
import SnippetModal from "../snippets/snippet-modal";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState, userState } from "../state/user-state";
import { addSnippet, editSnippet, loadSnippets } from "../api/sentences.service";
import { showSnippetsModalState, localUserSnippetsState, snippetsNeedUpdateState, snippetsState } from "../state/snippets-state";
import { useTranslation } from "react-i18next";
import { addEditClassroomSnippet } from "../api/classroom.service";
import { reload } from "firebase/auth";
import { isDefined, randomID } from "../utils/utils";

// export const AddSnippetModalType = {
//     CREATE_NEW: 'CREATE_NEW', // shows when user clicks on plus button in snippets header
//     FROM_DICTIONARY: 'FROM_DICTIONARY', // shows when user clicks on a 'Create Snippet' in dictionary search
//     FROM_TRANSLATION: 'FROM_TRANSLATION', // shows when user clicks on a 'Create Snippet' after performing a translation
//     EDIT_EXISTING: 'EDIT_EXISTING' // shows when user clicks on a 'Edit Snippet' in the snippet table
// }

// loads data from global modelState, initializes isShowing and passes it to the SnippetModal component
// and handles createSnippet call by passing the model to the uploadSnippet function;
// model is the data from the SnippetModal component
function AddSnippetModal() {
    const [modalState, setShowingSnippetsModal] = useRecoilState(showSnippetsModalState)
    const [isShowing, setIsShowing] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [isSubmitting, setSubmitting] = useState(false)
    const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState);
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);

    const { t } = useTranslation()

    useEffect(() => {
        setIsShowing(modalState?.isShowing)
        setModalType(modalState?.type)
        console.log('modal state type: ', modalState?.type)
    }, [modalState])

    const onCloseSnippetsModal = () => {
        let state = { ...modalState }
        state.isShowing = false
        setShowingSnippetsModal(state)
    }

    return <SnippetModal
        customTitle={modalState?.title}
        isOpen={isShowing && isDefined(modalType)}
        type={modalType}
        onClose={onCloseSnippetsModal}
        cellModel={modalState?.models}
        languageCode={learningLanguage?.code}
        userLanguageCode={userLanguage?.code}
        snippetKey={modalState?.editSnippetID}
        loading={isSubmitting}
    />

}

export default AddSnippetModal;