import { Constants } from "../utils/constants"

// clear data
export const clearLocalSentences = () => {
    localStorage.removeItem('sentences')
}

export const clearLocalSnippets = () => {
    localStorage.removeItem('snippets')
}

export const clearLocalClassrooms = () => {
    localStorage.removeItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT)
}

// has local data

export const hasLocalSentences = () => {
    const sentences = JSON.parse(localStorage.getItem('sentences')) || []

    return sentences.length > 0
}

export const hasLocalSnippets = () => {
    const snippets = JSON.parse(localStorage.getItem('snippets')) || {}

    for(let key in snippets) {
        let languageSnippetsDictionary = snippets[key]

        if(Object.keys(languageSnippetsDictionary).length > 0) {
            return true
        }
    }

    return false
}

export const hasLocalClassrooms = () => {
    const classrooms = JSON.parse(localStorage.getItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT)) || {}
    console.log('local classrooms ', classrooms)
    return Object.keys(classrooms).length > 0
}

export const hasAnyLocalData = () => {
    return hasLocalSentences() || hasLocalSnippets() || hasLocalClassrooms()
}

// load local data

// get local snippets for all languages
export const loadLocalSnippets = () => {
    const snippets = JSON.parse(localStorage.getItem('snippets')) || {}
    return snippets
}

export function printLocalData() {
    console.log('Local Data:')
    console.log('sentences', JSON.parse(localStorage.getItem('sentences')))
    console.log('snippets', JSON.parse(localStorage.getItem('snippets')))
    console.log('classrooms', JSON.parse(localStorage.getItem(Constants.LocalStorage.CLASSROOM_SESSIONS_DICT)))
}