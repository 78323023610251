import React from "react";
import { Box, Flex, Icon, IconButton, Spinner, Text, Link } from "@chakra-ui/react";
import { IoIosAddCircle } from "react-icons/io";
import { TbPin } from "react-icons/tb";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

function parseText(text, linkData, onLinkClick) {
  const regex = /<(\w+)>(.*?)<\/\1>/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    const [fullMatch, tag, content] = match;
    const startIndex = match.index;
    const endIndex = regex.lastIndex;

    // Push the text before the match
    if (startIndex > lastIndex) {
      parts.push(text.slice(lastIndex, startIndex));
    }
    // Process the match based on the tag
    if (linkData[tag]) {
      parts.push(
        <Link
          key={`${tag}-${content}`}
          color="blue.400"
          onClick={() => {
            console.log('Link clicked:', content);
            onLinkClick?.(linkData[tag]);
          }}
          cursor="pointer"
        >
          {content}
        </Link>
      );
    } else {
      parts.push(
        <Link
          key={`${tag}-${content}`}
          color="blue.500"
          on
        >
          {content}
        </Link>
      );
    }

    lastIndex = endIndex;
  }

  // Push the remaining text
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
}

function SnippetHeader({
  text,
  expandedState,
  toggleSnippetCollapsed,
  canCollapse,
  isAddingSnippet,
  onAddSnippetClick,
  cellModel,
  canAddSnippetItem,
  showAddSnippetButton,
  renderMoreMenu,
  links,
  canPinSnippet,
  onLinkClick,
}) {
  return (
    <Box backgroundColor="darkPurple" m={2} p={1} borderRadius="md">
      <Flex align="center" justifyContent="space-between">
        {canAddSnippetItem && (
          <>
            {isAddingSnippet && (
              <Spinner color="white" width="24px" height="24px" mr={2} ml={2} />
            )}
            {!isAddingSnippet && (
              <IconButton
                color="lightGreen"
                width="32px"
                height="32px"
                p={0}
                m={0}
                icon={<IoIosAddCircle fontSize="18px" />}
                _hover={{ bg: "midPurple" }}
                onClick={() => {
                  onAddSnippetClick?.(cellModel);
                }}
                aria-label="Add to User Snippets"
                variant="ghost"
              />
            )}
          </>
        )}
        <Text
          ml={showAddSnippetButton ? 0 : 3}
          fontWeight="bold"
          color="white"
          fontSize="lg"
          flexGrow={1}
          style={{ userSelect: links ? "none" : "auto" }}
        >
          {parseText(text, links, onLinkClick)}
        </Text>
        <IconButton
          color="white"
          width={"24px"}
          height={"24px"}
          icon={expandedState ? <ChevronUpIcon /> : <ChevronDownIcon />}
          style={{ cursor: canCollapse ? "pointer" : "default" }}
          opacity={canCollapse ? 1 : 0}
          _hover={{ bg: "midPurple" }}
          onClick={() => {
            if (canCollapse) {
              toggleSnippetCollapsed?.();
            }
          }}
          aria-label="Toggle Subsection"
          variant="ghost"
          ml="auto"
        />

        {cellModel?.pinned_at && (
          <Icon
            as={TbPin}
            color="customRed"
            style={{
              marginRight: "8px",
              marginLeft: "8px",
            }}
          />
        )}
        {renderMoreMenu(cellModel.id, cellModel.pinned_at, canPinSnippet)}
      </Flex>
    </Box>
  );
}

export default SnippetHeader;
