import {
  Box,
  Button,
  Input,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  VStack,
  Image,
  IconButton,
  HStack,
  useDisclosure,
  useToast,
  Avatar,
  Divider,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  Flex,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Drawer,
  MenuIcon,
} from "@chakra-ui/react";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import { auth, signInWithGooglePopup } from "../api/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useRef, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  ArrowBackIcon,
  DeleteIcon,
  EditIcon,
  InfoIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import DeleteConfirmationModal from "../dialogs/confirm-delete-modal";
import AddDocumentModal from "../documents/add-document-dialog";
import { deleteDocument, editDocument } from "../api/documents.service";
import { useTranslation } from "react-i18next";
import GoogleSignInButton from "../auth/google-button";
import { validateEmail } from "../utils/utils";
import { Constants } from "../utils/constants";
import RegisterPopover from "./login-register";
import LoginRegisterPopover from "./login-register";
import AdminHeaderPanel from "./classrooms/admin-header-panel";
import { useRecoilState } from "recoil";
import { isPageValidState } from "../state/app-state";
import { TiThMenu } from "react-icons/ti";
import { documentDataState } from "../state/document-state";

function Header({ hideNavigation }) {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
  const [document, setDocument] = useRecoilState(documentDataState);
  const [links, setLinks] = useState([
    { key: "documents.writing", url: "/documents" },
    { key: "classrooms.header_title", url: "/classrooms" },
    // { key: "stories.page_title", url: "/stories" },
    // { key: "Manage Stories", url: "/admin/manage-stories" },
  ]);

  return (
    <>
      {isMobile ? (
        <MobileHeader
          data-test='mobile-header'
          links={links}
          document={document}
          hideNavigation={hideNavigation}
        />
      ) : (
        <DesktopHeader
          data-test='desktop-header'
          links={links}
          document={document}
          hideNavigation={hideNavigation}
        />
      )}
    </>
  );
}

function MobileHeader({ onLoginSuccess, document, hideNavigation, links }) {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [user] = useAuthState(auth);
  const onClose = () => setShowSideMenu(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onLinkClick = (key) => {
    navigate(key);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <Flex
      align="center"
      justify="space-between"
      p={1}
      bg="darkPurple"
      maxH="64px"
    >
      <Image
        data-test="logo-image"
        ml={1}
        src="/icons/lrnr_logo.png"
        alt="LRNR Logo"
        maxH="50px"
        onClick={() => {
          if (user) {
            navigate("/documents", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }}
      />
      <AdminHeaderPanel height="50px" />

      <IconButton
        data-test="menu-button"
        mr={1}
        colorScheme="none"
        aria-label="Open menu"
        icon={<TiThMenu size="24px" color="white" />}
        onClick={() => setShowSideMenu(true)}
      />

      <Drawer isOpen={showSideMenu} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent backgroundColor="darkPurple">
          <DrawerCloseButton color="white" />
          <DrawerHeader>
            {user && (
              <Box>
                <Flex direction="column" alignItems="center">
                  <Avatar width="128px" height="128px" />
                  <Text color="white">{user.displayName || ""}</Text>
                </Flex>
              </Box>
            )}

            {!user && (
              <Box align="center" width="100%">
                <Flex direction="column" alignItems="center">
                  <LoginRegisterPopover
                    triggerRef={
                      <IconButton
                        data-test="login-register-button"
                        variant="unstyled"
                        width="128px"
                        height="128px"
                        aspectRatio={1}
                        icon={<Avatar width="100%" height="100%" />}
                      />
                    }
                  />
                  <LoginRegisterPopover
                    triggerRef={
                      <Button variant="unstyled" color="white">
                        {t("header.login_register")}
                      </Button>
                    }
                  />
                </Flex>
              </Box>
            )}
          </DrawerHeader>
          <DrawerBody>
            <Flex direction="column">
              {links.map((link) => (
                <Box>
                  <Button
                    fontWeight="bold"
                    w="100%"
                    justifyContent="flex-start"
                    variant="unstyled"
                    textAlign="start"
                    color="white"
                    onClick={() => {
                      onLinkClick(link.url);
                    }}
                  >
                    {t(link.key)}
                  </Button>
                  <Divider />
                </Box>
              ))}
              {user && (
                <Button
                  data-test="logout-button"
                  w="100%"
                  justifyContent="flex-start"
                  variant="ghost"
                  color="red"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  {t("header.logout")}
                </Button>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

function DesktopHeader({ onLoginSuccess, document, hideNavigation, links }) {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [isPageValid, setPageValid] = useRecoilState(isPageValidState);
  const [email, setEmail] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [password, setPassword] = useState("");
  const [currentDocument, setCurrentDocument] = useState(null);
  const [isDeleteDocumentOpen, setIsDeleteDocumentOpen] = useState(false);
  const [isEditDocumentOpen, setIsEditDocumentOpen] = useState(false);
  const [hideNavigationBar, setHideNavigationBar] = useState(false);

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();

  let toast = useToast();
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false); // Set loading to false when user state is determined
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  useEffect(() => {
    setHideNavigationBar(hideNavigation);
  }, [hideNavigation]);

  useEffect(() => {
    console.log("set current document " + JSON.stringify(document));
    setCurrentDocument(document);
  }, [document]);

  // Function to handle the transition from login to register popover
  const handleTransitionToRegister = () => {
    onLoginClose(); // Close the login popover
    setTimeout(() => onRegisterOpen(), 200); // Slight delay to ensure sequence
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const onDeleteDocument = async () => {
    // Delete document
    // Redirect to documents page
    try {
      await deleteDocument(
        currentDocument.id,
        user.uid,
        currentDocument.languageCode
      );
      setCurrentDocument(null);
      window.location.href = "/documents";
    } catch (error) {
      toast({
        title: "Error deleting document",
        description: error.message,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDocumentOpen(false);
    }
  };

  const onDocumentEdit = async (name) => {
    try {
      await editDocument(
        currentDocument.id,
        user.uid,
        currentDocument.languageCode,
        name
      );
      let newDocument = { ...currentDocument };
      newDocument.name = name;
      setCurrentDocument(newDocument);
    } catch (error) {
      toast({
        title: "Error editing document",
        description: error.message,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsEditDocumentOpen(false);
    }
  };

  async function onGoogleAuth() {
    try {
      const response = await signInWithGooglePopup();
      console.log(response);
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        return;
      }
      console.log("Google auth error occurred: " + error.message);
    }
  }

  return (
    <Box
      zIndex={10}
      as="header"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      pl={4}
      pr={4}
      bg="darkPurple"
      h="64px"
      position="relative"
    >
      {/* Logo */}
      <Image
        src="/icons/lrnr_logo.png"
        alt="LRNR Logo"
        maxH="64px"
        onClick={() => {
          if (user) {
            navigate("/documents", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }}
      />
      {isPageValid && (
        <>
          {!loading && (
            <>
              {currentDocument?.name && currentDocument?.languageCode && (
                <Box
                  ml={4}
                  border="1px solid grey"
                  borderRadius="8px"
                  p={2}
                  h="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Box ml={1} width="32px" height="100%">
                    <Image
                      src={`/icons/flag-${currentDocument.languageCode}.png`}
                      alt={currentDocument.languageCode}
                      width="100%"
                      height="100%"
                    />
                  </Box>

                  <Box
                    ml={3}
                    maxWidth="110px"
                    minWidth="110px"
                    overflow="hidden"
                    my={2}
                  >
                    <Text
                      fontWeight={600}
                      color="white"
                      lineHeight="tight"
                      noOfLines={2} // This will ensure the text does not go beyond two lines
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // Limits the text to two lines
                        overflow: "hidden",
                      }}
                    >
                      {currentDocument.name}
                    </Text>
                  </Box>
                  <IconButton
                    data-test="edit-document-button"
                    mb={1}
                    size="md"
                    icon={<EditIcon />}
                    variant="unstyled"
                    color="lightGrey"
                    aria-label="Edit"
                    onClick={() => {
                      setIsEditDocumentOpen(true);
                    }}
                  />
                  <IconButton
                    data-test="delete-document-button"
                    mb={1}
                    icon={<DeleteIcon />}
                    variant="unstyled"
                    color="customRed"
                    aria-label="Delete"
                    onClick={() => {
                      setIsDeleteDocumentOpen(true);
                    }}
                  />
                </Box>
              )}

              <Box
                as="nav"
                display="flex"
                justifyContent="flex-start"
                w="100%"
                alignItems="center"
                ml={5}
                p={4}
                bg="darkPurple"
                h="64px"
                position="relative"
              >
                {user && !hideNavigationBar && (
                  <Box display="flex" flexDirection="row" gap={4}>
                    {links.map((link) => (
                      <RouterLink to={link.url}>
                        <Text color="white">{t(link.key)}</Text>
                      </RouterLink>
                    ))}
                  </Box>
                )}
              </Box>

              {user ? (
                <HStack>
                  <AdminHeaderPanel height="50px" />

                  <Menu>
                    <MenuButton
                      colorScheme=""
                      as={IconButton}
                      aria-label="Options"
                      icon={<Avatar width="32px" height="32px" />}
                      variant="solid"
                    />
                    <MenuList backgroundColor="darkPurple">
                      <MenuItem
                        data-test="user-profile-button"
                        backgroundColor="#00000000"
                        color="white"
                        icon={<SettingsIcon />}
                        onClick={() => {
                          window.location.href = "/profile";
                        }}
                        _hover={{ backgroundColor: "purple" }} // Change 'hoverColor' to your desired color
                      >
                        {t("header.user_profile")}
                      </MenuItem>
                      <MenuItem
                        data-test="logout-button"
                        backgroundColor="#00000000"
                        color="red"
                        icon={<ArrowBackIcon />}
                        onClick={handleLogout}
                        _hover={{ backgroundColor: "purple" }} // Change 'hoverColor' to your desired color
                      >
                        {t("header.logout")}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              ) : (
                <HStack>
                  <AdminHeaderPanel height="50px" />
                  <LoginRegisterPopover
                    triggerRef={
                      <Button data-test='login-register-button' colorScheme="green" pl={5} pr={5}>
                        {t("header.login_register")}
                      </Button>
                    }
                  />
                </HStack>
              )}
            </>
          )}
        </>
      )}

      {currentDocument && (
        <>
          <DeleteConfirmationModal
            isOpen={isDeleteDocumentOpen}
            onClose={() => setIsDeleteDocumentOpen(false)}
            onConfirm={onDeleteDocument}
          />
          <AddDocumentModal
            title={t("sentences.edit_document")}
            buttonTitle={t("modal.edit")}
            isOpen={isEditDocumentOpen}
            onClose={() => {
              setIsEditDocumentOpen(false);
            }}
            onCreate={onDocumentEdit}
            text={currentDocument.name}
          />
        </>
      )}
    </Box>
  );
}

export default Header;
