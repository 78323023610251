import {
  get,
  onValue,
  push,
  ref,
  serverTimestamp,
  set,
  update,
} from "@firebase/database";
import { database } from "./firebaseConfig";
import { randomID } from "../utils/utils";
import { convertDictionaryEntryToSnippetModel, mergeSnippetWithTransltion, removeAccents } from "../utils/snippet-utils";
import { Constants } from "../utils/constants";

export const updateSentences = async (
  sentences,
  userID,
  documentID,
  languageCode,
) => {
  let sentencesCopy = [...sentences];
  if (userID && documentID) {
    const documentRef = ref(
      database,
      `documents/${userID}/${languageCode}/${documentID}/sentences`,
    );
    const sentencesDict = {};
    sentencesCopy.forEach((sentence, index) => {
      sentencesDict[`${sentence.id}`] = sentence.text;
    });
    return update(documentRef, sentencesDict);
  } else {
    // save to local storage

    // sentences should be in format ["sentence1", "sentence2"] but an array of objects might be passed [{"id": "1", "text": "sentence1"}, {"id": "2", "text": "sentence2"}]
    // if that's the case, convert it to an array of strings

    if (sentencesCopy.length > 0 && typeof sentencesCopy[0] === "object") {
      sentencesCopy = sentencesCopy.map((sentence) => sentence.text);
    }

    console.log(
      `Save local sentences ${languageCode} ${JSON.stringify(sentencesCopy)}`,
    );
    localStorage.setItem("sentences", JSON.stringify(sentencesCopy));
    return Promise.resolve();
  }
};

export const saveSentences = async (
  sentences,
  userID,
  documentID,
  languageCode,
) => {
  if (userID && documentID) {
    const documentRef = ref(
      database,
      `documents/${userID}/${languageCode}/${documentID}/sentences`,
    );

    const sentencesDict = {};
    sentences.forEach((sentence, index) => {
      sentencesDict[index] = sentence;
    });

    return set(documentRef, sentencesDict);
  } else {
    // save to local storage
    console.log(
      `Save local sentences ${languageCode} ${JSON.stringify(sentences)}`,
    );
    localStorage.setItem("sentences", JSON.stringify(sentences));
    return Promise.resolve();
  }
};

export const loadSentences = async (userID, documentID, languageCode) => {
  console.log(
    "loadSentences " + userID + " " + documentID + " " + languageCode,
  );
  if (userID && documentID) {
    let path = `documents/${userID}/${languageCode}/${documentID}`;
    console.log("path " + path);
    const documentRef = ref(database, path);
    const snapshot = await get(documentRef);
    if (snapshot.exists()) {
      let doc = snapshot.val() ?? {};

      console.log("Loaded document" + JSON.stringify(doc));
      let dict = doc?.sentences ?? {};
      let sentences = [];
      for (let key in dict) {
        sentences.push(dict[key]);
      }
      doc.sentences = sentences;
      return doc;
    } else {
      console.log("Document not found");
      return null;
    }
  } else {
    console.log("loading sentences from local storage");
    const sentences = JSON.parse(localStorage.getItem("sentences")) || {};
    console.log(
      `loaded local sentences ${languageCode}: ${JSON.stringify(sentences)}`,
    );
    return {
      sentences,
    };
  }
};

export const addSnippets = async (snippets, userID, languageCode) => {
  let promises = [];
  for (let snippet of snippets) {
    promises.push(addSnippet(snippet, userID, languageCode).catch((e) => { }));
  }

  return Promise.all(promises);
};

export const addEditSnippet = async (snippetID, snippet, userID, languageCode) => {
  const preparedSnippet = prepareSnippetForSaving(snippet);
  console.log('Prepared snippet', preparedSnippet)
  
  if(snippetID) {
    console.log('edit snippet' + snippetID)
    return editSnippet(snippetID, null, preparedSnippet, userID, languageCode)
  } else {
    console.log('add snippet')
    return addSnippet(preparedSnippet, userID, languageCode)
  }
}

export const addSnippet = async (snippet, userID, languageCode, customKey) => {
  let snippetCopy = prepareSnippetForSaving(snippet);

  if (userID) {
    console.log("Saving snippet for user with ID " + userID + " to database");
    snippetCopy.createdAt = serverTimestamp();
    let newSnippetRef = null;
    if (customKey) {
      newSnippetRef = ref(
        database,
        `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/${customKey}`,
      );
    } else {
      newSnippetRef = push(
        ref(database, `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/`),
      );
    }
    
    console.log('addSnippet set ' + JSON.stringify(snippetCopy))

    return set(newSnippetRef, snippetCopy).then(() => {
      snippet.id = newSnippetRef.key;
      return snippet;
    });
  } else {
    // save to local storage
    console.log("Saving snippet to local storage");
    let snippetsDictionary = null;
    try {
      snippetsDictionary = JSON.parse(localStorage.getItem("snippets")) || {};
    } catch (error) {
      console.error("Error parsing snippets from local storage", error);
      snippetsDictionary = {};
    }
    const snippets = snippetsDictionary[languageCode] || {};
    let key = customKey ?? randomID();
    snippets[key] = snippetCopy;
    snippetsDictionary[languageCode] = snippets;
    let snippetsString = JSON.stringify(snippetsDictionary);
    console.log("Saved snippets string to local storage " + snippetsString);
    localStorage.setItem("snippets", snippetsString);
    return Promise.resolve();
  }
};

export const editSnippet = async (
  snippetID,
  classroomID,
  snippet,
  userID,
  languageCode,
) => {
  let snippetCopy = prepareSnippetForSaving(snippet);

  if (userID) {
    let path = `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/${snippetID}`
    const snippetRef = ref(
      database,
      path,
    );
    console.log(`writing ${snippetCopy} to path ${path}`)
    return set(snippetRef, snippetCopy);
  } else {
    console.log('User not logged in, saving to local storage')
    // save to local storage
    const snippetsDictionary =
      JSON.parse(localStorage.getItem("snippets")) || {};
    const snippets = snippetsDictionary[languageCode] || {};
    snippets[snippetID] = snippetCopy;
    snippetsDictionary[languageCode] = snippets;
    localStorage.setItem("snippets", JSON.stringify(snippetsDictionary));
    return Promise.resolve();
  }
};

export function prepareSnippetForSaving(snippet) {
  console.log('prepareSnippetForSaving ' + JSON.stringify(snippet))
  let snippetCopy = { ...snippet };
  let keysToSave = ["pinned_at", "createdAt", "updatedAt", "link", "plainTextTerm", "plainTextTranslation", "customDefinition", "selectedDefinition", "definitionsLanguageCode", "selectedSections"];
  for (let key in snippetCopy) {
    if (!keysToSave.includes(key)) {
      delete snippetCopy[key];
    } else {
      if(snippetCopy[key] === undefined) {
        delete snippetCopy[key];
      }
    }
  }

  console.log('After filtering out keys ', JSON.stringify(snippetCopy))

  return snippetCopy;
}

export const observeSnippets = (userID, learningLanguageCode, userLanguageCode, callback) => {
  if (userID) {
    const snippetsRef = ref(database, `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${learningLanguageCode}`);
    console.log(
      "Observing snippets for user with ID " +
      userID +
      " language " +
      learningLanguageCode,
    );
    return onValue(snippetsRef, (snapshot) => {
      console.log("Snippets updated onValue");
      let dict = snapshot.val() ?? {};
      return fetchCachedSnippets(dict, userLanguageCode, learningLanguageCode).then((cachedSnippets) => {
        callback?.(cachedSnippets);
      });
    });
  } else {
    return Promise.resolve();
  }
};

// const loadNewSnippets = async (userID, languageCode) => {
//   let dict = {};
//   if (userID) {
//     const snippetsRef = ref(database, `user_snippets/${userID}/${languageCode}`);
//     const snapshot = await get(snippetsRef);
//     if (snapshot.exists()) {
//       dict = snapshot.val() ?? {};
//     }
//   } else {
//     let snippetsDictionary = null;
//     try {
//       snippetsDictionary = JSON.parse(localStorage.getItem("snippets")) || {};
//       console.log(
//         "loadSnippets dictionary " + JSON.stringify(snippetsDictionary),
//       );
//     } catch (error) {
//       console.error("Error parsing snippets from local storage", error);
//       snippetsDictionary = {};
//     }

//     dict = snippetsDictionary[languageCode] || {};
//     console.log(
//       `loadSnippets dictionary for language ${languageCode}: ${JSON.stringify(snippetsDictionary)}`,
//     );
//   }

//   // fetch cached snippets
//   let parsedSnippets = await fetchCachedSnippets(dict);
//   console.log("loadSnippets, returning " + JSON.stringify(parsedSnippets));
//   return parsedSnippets;
// }


const loadNewSnippetsTest = async (userID, learningLanguageCode, userLanguageCode) => {
  let userSnippets = {};
  if (userID) {
    const snapshot = await get(ref(database, `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${learningLanguageCode}`))
  
    if (snapshot.exists()) {
      userSnippets = snapshot.val() ?? {};
      console.log('loadNewSnippetsTest dict', JSON.stringify(userSnippets))
    } else {
      console.log('loadNewSnippetsTest no data')
    }
  } else {
    let snippetsDictionary = null;
    try {
      snippetsDictionary = JSON.parse(localStorage.getItem("snippets")) || {};
      console.log(
        "loadSnippets dictionary " + JSON.stringify(snippetsDictionary),
      );
    } catch (error) {
      console.error("Error parsing snippets from local storage", error);
      snippetsDictionary = {};
    }

    userSnippets = snippetsDictionary[learningLanguageCode] || {};
    console.log(
      `loadSnippets dictionary for language ${learningLanguageCode}: ${JSON.stringify(snippetsDictionary)}`,
    );
  }

  // fetch cached snippets
  let parsedSnippets = await fetchCachedSnippets(userSnippets, userLanguageCode, learningLanguageCode);
  console.log("loadSnippets, returning " + JSON.stringify(parsedSnippets));
  return parsedSnippets
};

export const loadSnippets = async (userID, learningLanguageCode, userLanguageCode) => {
  let userLanguage = ''
  // if(learningLanguageCode === 'en' && userLanguageCode === 'en') {
  //   throw new Error('Both learning and user language code are english')
  // } else 
  if (learningLanguageCode === userLanguageCode) {
    userLanguage = 'en'
  } else {
    userLanguage = userLanguageCode
  }

  let snippets = await loadNewSnippetsTest(userID, learningLanguageCode, userLanguage)
  return snippets
};

export const pinSnippet = async (snippetID, userID, languageCode) => {
  if (userID) {
    console.log(
      "Pinning snippet for user with ID " +
      userID +
      " language " +
      languageCode +
      " key " +
      snippetID,
    );
    const snippetRef = ref(
      database,
      `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/${snippetID}`,
    );
    return update(snippetRef, { pinned_at: serverTimestamp() });
  } else {
    const snippetsDictionary =
      JSON.parse(localStorage.getItem("snippets")) || {};
    const snippets = snippetsDictionary[languageCode] || {};
    snippets[snippetID].pinned_at = Date.now();
    snippetsDictionary[languageCode] = snippets;
    localStorage.setItem("snippets", JSON.stringify(snippetsDictionary));
    return Promise.resolve();
  }
};

export const unpinSnippet = async (snippetID, userID, languageCode) => {
  if (userID) {
    console.log(
      "Unpinning snippet for user with ID " +
      userID +
      " language " +
      languageCode +
      " key " +
      snippetID,
    );
    const snippetRef = ref(
      database,
      `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/${snippetID}`,
    );
    return update(snippetRef, { pinned_at: null });
  } else {
    const snippetsDictionary =
      JSON.parse(localStorage.getItem("snippets")) || {};
    const snippets = snippetsDictionary[languageCode] || {};
    snippets[snippetID].pinned_at = null;
    snippetsDictionary[languageCode] = snippets;
    localStorage.setItem("snippets", JSON.stringify(snippetsDictionary));
    return Promise.resolve();
  }
};

// async function fetchCachedSnippets(dict, userLanguageCode, learningLanguageCode) {
//   console.log('fetchCachedSnippets', dict, userLanguageCode, learningLanguageCode)  
//   let snippets = []
//   for (let snippetID in dict) {
//     let snippet = dict[snippetID];
//     if (snippet.link) {
//       let accentedTerm = snippet.link.accentedTerm;
//       let term = snippet.link.term
//       let wordType = snippet.link.wordType

//       if (!accentedTerm || !term || !wordType) {
//         // report error to backend by calling snippets-get_user_snippet_missing endpoint
//         console.error("Missing link data for snippet " + snippetID)
//         // TODO: Call endpoint for missing data
//       } else {
//         console.log('fetchCachedSnippets', accentedTerm, term, wordType)
//         const cachedSnippetRef = ref(
//           database,
//           `${PATH_DICTIONARY_CACHE}/${learningLanguageCode}/${term}/forms/${accentedTerm}/${wordType}`,
//         );
// // "test_dictionary_cache": {
// //         "ru": {
// //             "писать": {
// //                 "forms": {
// //                     "писа́ть": {
// //                         "verb": {

// // "term": "писать",
// //                         "accentedTerm": "писа́ть",
// //                         "language": "ru",
// //                         "wordType": "verb"
//         // let includedSectionKeys = Object.keys(snippet.sections ?? {});
//         let promises = []
//         let cachedSnapshotPromise = get(cachedSnippetRef);
//         promises.push(cachedSnapshotPromise);

//         if(userLanguageCode !== 'en') {
//           console.log('translation', learningLanguageCode, userLanguageCode, accentedTerm, wordType)
//           // fetch translation 
//           let translationPromise = ref(
//             database,
//             `${PATH_DICTIONARY_CACHE_TRANSLATION}/${learningLanguageCode}/${userLanguageCode}/forms/${accentedTerm}/${wordType}`,
//           );
//           promises.push(get(translationPromise));
//         }

//         let [cachedSnapshot, translationSnapshot] = await Promise.all(promises);
       
//         let snippet = mergeSnippetWithTransltion(cachedSnapshot, translationSnapshot)
//         snippets.push(snippet)
//         // if (cachedSnapshot.exists()) {
//         //   let d = cachedSnapshot.val() ?? {};
//         //   snippet.type = d.type;
//         //   snippet.items = d.items;
//         //   hideSnippetSections(snippet.items, includedSectionKeys);
//         // } else {
//         //   // report error to backend by calling snippets-get_user_snippet_missing endpoint
//         //   console.error("Missing cached data for snippet " + snippetID)
//         // }
//       }
//     } 
//     return snippets
//   }
//   //   else if (snippet.cached?.key && snippet.cached?.languageCode) {
//   //     const cachedSnippetRef = ref(
//   //       database,
//   //       `snippets_cache/${snippet.cached.key}/${snippet.cached.languageCode}`,
//   //     );

//   //     let includedSectionKeys = Object.keys(snippet.sections ?? {});
//   //     let cachedSnapshot = await get(cachedSnippetRef);
//   //     if (cachedSnapshot.exists()) {
//   //       let d = cachedSnapshot.val() ?? {};
//   //       snippet.type = d.type;
//   //       snippet.items = d.items;
//   //       hideSnippetSections(snippet.items, includedSectionKeys);
//   //     }
//   //   }
//   // }
// }

export async function fetchCachedSnippets(userSnippets, userLanguageCode, learningLanguageCode) {
  if(learningLanguageCode === 'en') {
    return fetchCachedSnippetsEnglish(userSnippets, userLanguageCode, learningLanguageCode)
  }
  console.log(`fetchCachedSnippets (${userLanguageCode}) (${learningLanguageCode}): ${JSON.stringify(userSnippets)}`);

  let snippets = [];
  let plainTextSnippets = [];
  let fetchPromises = [];
  
  for (let snippetID in userSnippets) {
    let snippet = userSnippets[snippetID];
    let snippetLanguageCode = snippet.definitionsLanguageCode ?? 'en'
    let isNonEnglishLanguage = snippetLanguageCode !== 'en'
    if (snippet.link) {
      let promises = []
      let accentedTerm = snippet.link.accentedTerm;
      let term = snippet.link.term;
      
      let wordType = snippet.link.wordType;

      if (!accentedTerm || !term || !wordType) {
        // report error to backend by calling snippets-get_user_snippet_missing endpoint
        console.error("Missing link data for snippet " + snippetID);
        // TODO: Call endpoint for missing data
      } else {
        let termWithoutAccent = removeAccents(term);

        // data is correct, fetch the snippet
        let path = `${Constants.DatabasePath.DICTIONARY_CACHE}/${learningLanguageCode}/${term}/forms/${accentedTerm}/${wordType}`
        const cachedSnippetRef = ref(
          database,
          path,
        );

        let cachedSnapshot = null
        let translationSnapshot = null

        promises.push(get(cachedSnippetRef).then((snapshot) => {
          cachedSnapshot = snapshot;
        }))

        if(isNonEnglishLanguage) {
          // fetch translation 
          let translatePath = `${Constants.DatabasePath.DICTIONARY_CACHE_TRANSLATION}/${learningLanguageCode}/${snippetLanguageCode}/${termWithoutAccent}/${accentedTerm}/${wordType}`
          console.log('isNonEnglishLanguage path ' + translatePath)
          const translationRef = ref(
            database,
            translatePath
          );
          
          promises.push(get(translationRef).then((snapshot) => {
            translationSnapshot = snapshot;
          }))
        }

        let promise = Promise.all(promises).then(() => {
          if(isNonEnglishLanguage) {
            // merge with tranlsation
            if(translationSnapshot?.exists()) {
              return mergeSnippetWithTransltion(cachedSnapshot.val(), translationSnapshot.val());
            } else {
              console.error('Error fetching translation for ' + snippetID);
              // TODO: call backend to translate
              return cachedSnapshot.val();
            }
          } else {
            // english
            return cachedSnapshot.val()
          }
        }).then(dictionaryEntry => {
          return convertDictionaryEntryToSnippetModel(snippetID, dictionaryEntry, snippet, learningLanguageCode);
        }).catch((error) => {
          console.error('Error fetching cached snippet', error);
        })
        fetchPromises.push(promise);
      
      }
    } else {
      snippet.id = snippetID;
      plainTextSnippets.push(snippet);
      console.log('fetchCachedSnippets Missing link subobject for snippet ' + snippetID);
    }
  }

  // Wait for all fetch promises to resolve
  snippets = await Promise.all(fetchPromises);

  return snippets.concat(plainTextSnippets);
}

const fetchCachedSnippetsEnglish = async (userSnippets, userLanguageCode, learningLanguageCode) => {
  console.log(`fetchCachedSnippetsEnglish (${learningLanguageCode}): ${JSON.stringify(userSnippets)}`);
    let snippets = [];
    let plainTextSnippets = [];
    let fetchPromises = [];
    
    for (let snippetID in userSnippets) {
      let snippet = userSnippets[snippetID];
      console.log('fetchCachedSnippetID', snippet)
      if (snippet.link) {
        console.log('fetchCachedLink', snippet.link)
        let promises = []
        let accentedTerm = snippet.link.accentedTerm;
        let term = snippet.link.term;
        let definitionsLanguageCode = snippet.definitionsLanguageCode
        let wordType = snippet.link.wordType;
  
        if (!accentedTerm || !term || !wordType || !definitionsLanguageCode) {
          // report error to backend by calling snippets-get_user_snippet_missing endpoint
          console.error("Missing link data for snippet " + snippetID);
          // TODO: Call endpoint for missing data
        } else {
          console.log('fetchCached fields', accentedTerm, term, wordType)
          let termWithoutAccent = removeAccents(term);
  
          // data is correct, fetch the snippet
          let path = `${Constants.DatabasePath.DICTIONARY_CACHE_ENGLISH}/${definitionsLanguageCode}/${term}/forms/${wordType}`
          console.log('Fetching english link at path ' + path)
          const cachedSnippetRef = ref(
            database,
            path,
          );
  
          let cachedSnapshot = null
  
          promises.push(get(cachedSnippetRef).then((snapshot) => {
            cachedSnapshot = snapshot;
          }))
  
          let promise = Promise.all(promises).then(() => {
            console.log('promise ' + cachedSnapshot.val() + ' at path ' + path)
              return cachedSnapshot.val();
          }).then(dictionaryEntry => {
            return convertDictionaryEntryToSnippetModel(snippetID, dictionaryEntry, snippet, learningLanguageCode);
          }).catch((error) => {
            console.error('Error fetching cached snippet', error);
          })
          fetchPromises.push(promise);
        
        }
      } else {
        console.log('wtf Missing link subobject for snippet ' + snippetID);
        snippet.id = snippetID;
        plainTextSnippets.push(snippet);
        console.log('missing Missing link subobject for snippet ' + snippetID);
      }
    }
  
    // Wait for all fetch promises to resolve
    snippets = await Promise.all(fetchPromises);
  
    return snippets.concat(plainTextSnippets);
  }

// async function fetchCachedSnippets(dict) {
//   let promises = [];
//   for (let key in dict) {
//     let snippet = dict[key];
//     if (snippet.cached?.key && snippet.cached?.languageCode) {
//       const cachedSnippetRef = ref(
//         database,
//         `snippets_cache/${snippet.cached.key}/${snippet.cached.languageCode}`,
//       );

//       let includedSectionKeys = Object.keys(snippet.sections ?? {});
//       promises.push(
//         get(cachedSnippetRef).then((cachedSnapshot) => {
//           if (cachedSnapshot.exists()) {
//             let d = cachedSnapshot.val() ?? {};
//             snippet.type = d.type;
//             snippet.items = d.items;
//             hideSnippetSections(snippet.items, includedSectionKeys);
//           }
//         }),
//       );
//     }
//   }
//   await Promise.all(promises);

//   let snippets = parseSnippetsDict(dict);

//   return snippets;
// }

export function parseSnippetsDict(dict) {
  console.log("Parsing snippets dict " + JSON.stringify(dict));
  let snippets = [];
  for (let key in dict ?? {}) {
    let snippet = dict[key];

    // check for invalid keys just in case
    if (!snippet) {
      delete snippets[key];
    } else {
      snippet.id = key;
      snippets.push(snippet);
    }
  }
  snippets.sort((a, b) => b.createdAt - a.createdAt);

  console.log("Parse snippets dict, returning " + JSON.stringify(snippets));
  return snippets;
}

function hideSnippetSections(dict, includedSections) {
  // recursively set hidden to true if id is not in included sections
  if (dict.id && !includedSections.includes(dict.id)) {
    dict.hidden = true;
  }

  for (let key in dict) {
    if (typeof dict[key] === "object") {
      hideSnippetSections(dict[key], includedSections);
    }
  }
}

function getIncludedSnippetSections(dict, includedSections) {
  // recursively set hidden to true if id is not in included sections
  if (dict.id && !dict.hidden) {
    includedSections.push(dict.id);
  }

  for (let key in dict) {
    if (typeof dict[key] === "object") {
      getIncludedSnippetSections(dict[key], includedSections);
    }
  }
}

 export const deleteSnippet = async (snippetKey, userID, languageCode) => {
  if (userID) {
    console.log(
      "Deleting snippet for user with ID " +
      userID +
      " language " +
      languageCode +
      " key " +
      snippetKey,
    );
    const snippetRef = ref(
      database,
      `${Constants.DatabasePath.USER_SNIPPETS}/${userID}/${languageCode}/${snippetKey}`,
    );
    return set(snippetRef, null);
  } else {
    console.log(
      "Deleting snippet from local storage " +
      languageCode +
      " key " +
      snippetKey,
    );
    // save to local storage
    const snippetsDictionary =
      JSON.parse(localStorage.getItem("snippets")) || {};
    const snippets = snippetsDictionary[languageCode] || {};
    delete snippets[snippetKey];

    snippetsDictionary[languageCode] = snippets;
    localStorage.setItem("snippets", JSON.stringify(snippetsDictionary));
    return Promise.resolve();
  }
}
