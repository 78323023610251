import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en', // default language
    fallbackLng: 'en', // use 'en' if detected lng is not available

    interpolation: {
      escapeValue: false, // react already safeguards from xss
    },

    backend: {
      loadPath: '/locales/{{lng}}.json', // load translations from /locales
    },
  }).then(() => {
    console.log('i18n initialized with language', i18n.language);
  }).catch((error) => {
    console.error('i18n initialization failed:', error);
  });

export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// i18n
//   .use(initReactI18next)
//   .use(Backend)
//   .init({
//     lng: 'en', // default language
//     fallbackLng: 'en', // use 'en' if detected lng is not available

//     interpolation: {
//       escapeValue: false, // react already safeguards from xss
//     },

//     backend: {
//       loadPath: '/locales/{{lng}}.json', // load translations from /locales
//       request: (options, url, payload, callback) => {
//         console.log(`Fetching translation file from: ${url}`);
//         fetch(url)
//           .then(response => {
//             if (!response.ok) {
//               throw new Error(`Failed to load ${url}`);
//             }
//             return response.json();
//           })
//           .then(data => {
//             console.log('Loaded translation data:', data);
//             callback(null, { status: 200, data });
//           })
//           .catch(error => {
//             console.error('Error loading translation data:', error);
//             callback(error, { status: 500 });
//           });
//       }
//     }
//   });

// export default i18n;
