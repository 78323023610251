// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectDatabaseEmulator, getDatabase } from "@firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB63na5-BdryiJ6eULyIPFbMAXGkcJpCIk",
  authDomain: "languagelearning-310010.firebaseapp.com",
  projectId: "languagelearning-310010",
  storageBucket: "languagelearning-310010.appspot.com",
  messagingSenderId: "82212479520",
  appId: "1:82212479520:web:45c53e0de7c8ba59481245",
  measurementId: "G-7PCSQPX32N"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const database = getDatabase(firebase)
export const auth = getAuth(firebase);
export const functions = getFunctions(firebase);
export const analytics = getAnalytics(firebase)

// Initialize Firebase Auth provider
const googleAuthProvider = new GoogleAuthProvider()
googleAuthProvider.setCustomParameters({   
  prompt : "select_account "
});

export const signInWithGooglePopup = () => signInWithPopup(auth, googleAuthProvider);

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}