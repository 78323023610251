import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { learningLanguageState, userState } from "../state/user-state";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Divider, IconButton, Input, Radio, RadioGroup, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import Header from "../components/header";
import Footer from "../components/footer";
import { loadStorySnippets } from "../api/translation.service";
import DictionarySearchResults from "../components/dictionary-search-result";
import { selectedStoryMappingState } from "../state/story-state";
import { copyJSONObject, deepEqual, isDefined, random8ID, randomID } from "../utils/utils";
import CopyToClipboardButton from "../components/copy-button";
import ChooseLanguageComponent from "../components/choose-language-component";
import { getLanguageWithCode, getStoryLanguageWithCode, LANGUAGES, LanguageVariants, StoryLanguages } from "../utils/languages";
import { uploadStory } from "../api/story.service";
import StoryDetails from "../stories/story-details";
import { isPageValidState } from "../state/app-state";
import ChooseLearningLanguage from "../components/choose-learning-language";
import ChooseLanguage from "../components/chooseLanguage";
import { Constants } from "../utils/constants";
import { addPlainTextStory, generateMappings, generateStoryText, generateStoryTextAndSaveToDB, getGenerateMappingStories, getReviewStories, publishStory, submitGeneratedMappings, updateReviewStory, uploadReviewStoryImage } from "./manage-stories.service";
import { showAddEditSnippetModal, showDefaultAddSnippetModal, showSnippetsModalState } from "../state/snippets-state";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { AddEditSnippetModalType, AddToArrayIndexStrategy } from "../snippets/snippet-modal";
import { manageStoriesListStoriesState, manageStoriesReviewSnippetModalSelectedMappingState, manageStoriesSelectedMappingIDState, manageStoriesSelectedStoryIDState, manageStoriesStoriesToUpdateState } from "./manage-stories-state";
import { findStoryMapping, replaceStoryMapping } from "./story-utils";
import DeleteConfirmation from "../dialogs/delete-confirmation";
import { set } from "@firebase/database";
import ImageUploadComponent from "../components/image-upload";
import FallbackImage from "../components/fallback-image";
import UploadImageModal from "../dialogs/upload-image-modal";

const gmt = {
    "languageCode": "es-MX",
    "text": "Dos personas hablan en la oficina sobre sus planes de descanso y trabajo.\n\nLuis: ¿Listo para el descanso?\nSara: Sí, ¡ya necesitaba un poco de tiempo libre!\nLuis: ¿Qué tal tu día hasta ahora?\nSara: Bien, aunque ha sido un poco lento. ¿Y el tuyo?\nLuis: Igual, tranquilo. Mucho trabajo, pero todo bien.\nSara: ¿Tienes planes para almorzar?\nLuis: Tal vez ir a la cafetería de la esquina. ¿Quieres venir?\nSara: Claro, suena bien. ¿Es caro?\nLuis: No mucho. Tienen menús baratos.\nSara: Perfecto, me gusta comer algo sencillo en el almuerzo.\nLuis: A mí también. Además, es rápido y tenemos que volver pronto.\nSara: ¿Te gusta la comida allí?\nLuis: Sí, es rica. ¿Y a ti?\nSara: Me gusta. Especialmente la ensalada.\nLuis: Yo prefiero los sándwiches.\nSara: Buena elección. Hoy quiero probar algo diferente.\nLuis: ¿Qué tal una sopa?\nSara: Suena bien. Me encanta la sopa.\nLuis: ¿A qué hora regresamos?\nSara: En media hora, creo. ¿Te parece?\nLuis: Sí, está bien. Así no llegamos tarde.\nSara: ¿Cómo van tus proyectos?\nLuis: Lento, pero avanzando. Mucho trabajo, ya sabes.\nSara: Sí, te entiendo. Mi proyecto también es complicado.\nLuis: Bueno, tranquilo. Lo importante es avanzar poco a poco.\nSara: Tienes razón. Paciencia es clave.\nLuis: Después del almuerzo, me siento más concentrado.\nSara: A mí también me ayuda. Así trabajo mejor.\nLuis: ¿Quieres algo de postre?\nSara: Tal vez. Depende de lo que tengan.\nLuis: Sí, vamos a ver qué hay.\nSara: Suena bien.",
    "dialect": null,
    "style": "informal",
    "title": "En la Oficina",
    "level": "A1",
    "difficulty": "beginner",
    "sentences": [
      {
        "mappings": [
          {
            "range": {
              "length": 3,
              "location": 0
            },
            "snippets": [
              {
                "text": "dos",
                "word_type": "numeral",
                "id": "Ig7Mgn2p"
              }
            ],
            "text": "Dos",
            "id": "zlCOU9Hw"
          },
          {
            "range": {
              "length": 8,
              "location": 4
            },
            "snippets": [
              {
                "text": "personas",
                "word_type": "noun",
                "id": "z5Q891hg"
              },
              {
                "text": "persona",
                "word_type": "noun",
                "id": "aveFRyiL"
              }
            ],
            "text": "personas",
            "id": "2lACszfU"
          },
          {
            "range": {
              "length": 6,
              "location": 13
            },
            "snippets": [
              {
                "count": "plural",
                "infinitive": "hablar",
                "person": 3,
                "tense": "present",
                "text": "hablan",
                "word_type": "verb",
                "id": "b4EnnQ9k"
              }
            ],
            "text": "hablan",
            "id": "qMmE4su7"
          },
          {
            "range": {
              "length": 2,
              "location": 20
            },
            "snippets": [
              {
                "text": "en",
                "word_type": "preposition",
                "id": "CckkF28U"
              }
            ],
            "text": "en",
            "id": "Kd4KuOTq"
          },
          {
            "range": {
              "length": 2,
              "location": 23
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "mvHZ1ldx"
              }
            ],
            "text": "la",
            "id": "uGIDVzL9"
          },
          {
            "range": {
              "length": 7,
              "location": 26
            },
            "snippets": [
              {
                "text": "oficina",
                "word_type": "noun",
                "id": "R3ChE1Xa"
              }
            ],
            "text": "oficina",
            "id": "ENywXyAi"
          },
          {
            "range": {
              "length": 5,
              "location": 34
            },
            "snippets": [
              {
                "text": "sobre",
                "word_type": "preposition",
                "id": "Etihz88C"
              }
            ],
            "text": "sobre",
            "id": "SXcfwOZf"
          },
          {
            "range": {
              "length": 3,
              "location": 40
            },
            "snippets": [
              {
                "text": "sus",
                "word_type": "pronoun",
                "id": "NYVT7cxT"
              }
            ],
            "text": "sus",
            "id": "4Rbgc759"
          },
          {
            "range": {
              "length": 6,
              "location": 44
            },
            "snippets": [
              {
                "text": "planes",
                "word_type": "noun",
                "id": "oHiSPvyf"
              },
              {
                "text": "plan",
                "word_type": "noun",
                "id": "pgZ1uvYI"
              }
            ],
            "text": "planes",
            "id": "CWuOwvAT"
          },
          {
            "range": {
              "length": 2,
              "location": 51
            },
            "snippets": [
              {
                "text": "de",
                "word_type": "preposition",
                "id": "8Lqmjr7c"
              }
            ],
            "text": "de",
            "id": "0mSeiowE"
          },
          {
            "range": {
              "length": 8,
              "location": 54
            },
            "snippets": [
              {
                "text": "descanso",
                "word_type": "noun",
                "id": "D7NXtQw4"
              }
            ],
            "text": "descanso",
            "id": "uYnKKAT0"
          },
          {
            "range": {
              "length": 1,
              "location": 63
            },
            "snippets": [
              {
                "text": "y",
                "word_type": "conjunction",
                "id": "sWid0X75"
              }
            ],
            "text": "y",
            "id": "BjPy2hRr"
          },
          {
            "range": {
              "length": 7,
              "location": 65
            },
            "snippets": [
              {
                "text": "trabajo",
                "word_type": "noun",
                "id": "EqzoaQyc"
              }
            ],
            "text": "trabajo",
            "id": "1MwMrfGI"
          }
        ],
        "text": "Dos personas hablan en la oficina sobre sus planes de descanso y trabajo.",
        "id": "aESRNhUN"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "CEwkplXu"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "Cga5GvzO"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "7OvTPJ8P"
              }
            ],
            "text": "Luis",
            "id": "gpuDhb7t"
          },
          {
            "range": {
              "length": 5,
              "location": 7
            },
            "snippets": [
              {
                "text": "listo",
                "word_type": "adjective",
                "id": "XmNLbhY7"
              }
            ],
            "text": "Listo",
            "id": "Lfm7iBLY"
          },
          {
            "range": {
              "length": 4,
              "location": 13
            },
            "snippets": [
              {
                "text": "para",
                "word_type": "preposition",
                "id": "WCnCllsy"
              }
            ],
            "text": "para",
            "id": "UCLqR9ia"
          },
          {
            "range": {
              "length": 2,
              "location": 18
            },
            "snippets": [
              {
                "text": "el",
                "word_type": "article",
                "id": "vn5OsxOR"
              }
            ],
            "text": "el",
            "id": "CopTB4VB"
          },
          {
            "range": {
              "length": 8,
              "location": 21
            },
            "snippets": [
              {
                "text": "descanso",
                "word_type": "noun",
                "id": "vuSpiJGX"
              }
            ],
            "text": "descanso",
            "id": "rc31JTBr"
          }
        ],
        "text": "Luis: ¿Listo para el descanso?",
        "id": "RApfumk9"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "p1BRaOCu"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "QHR6unuB"
              }
            ],
            "text": "Sara",
            "id": "6pNSbxCM"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "sí",
                "word_type": "interjection",
                "id": "2cJPICzH"
              }
            ],
            "text": "Sí",
            "id": "e9hQXyIw"
          },
          {
            "range": {
              "length": 2,
              "location": 11
            },
            "snippets": [
              {
                "text": "ya",
                "word_type": "adverb",
                "id": "BPYJh6rZ"
              }
            ],
            "text": "ya",
            "id": "i2dE2jkk"
          },
          {
            "range": {
              "length": 10,
              "location": 14
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "necesitar",
                "person": 1,
                "tense": "imperfect",
                "text": "necesitaba",
                "word_type": "verb",
                "id": "X7FhVZkZ"
              }
            ],
            "text": "necesitaba",
            "id": "AgaUk8fh"
          },
          {
            "range": {
              "length": 2,
              "location": 25
            },
            "snippets": [
              {
                "text": "un",
                "word_type": "determiner",
                "id": "FynUZPvp"
              }
            ],
            "text": "un",
            "id": "o8XhL5bl"
          },
          {
            "range": {
              "length": 4,
              "location": 28
            },
            "snippets": [
              {
                "text": "poco",
                "word_type": "noun",
                "id": "HUxPAsj3"
              }
            ],
            "text": "poco",
            "id": "fiCPo5X2"
          },
          {
            "range": {
              "length": 2,
              "location": 33
            },
            "snippets": [
              {
                "text": "de",
                "word_type": "preposition",
                "id": "bzr3MCjv"
              }
            ],
            "text": "de",
            "id": "w9xTCJMB"
          },
          {
            "range": {
              "length": 6,
              "location": 36
            },
            "snippets": [
              {
                "text": "tiempo",
                "word_type": "noun",
                "id": "TgUBx1g3"
              }
            ],
            "text": "tiempo",
            "id": "IZUBLYuY"
          },
          {
            "range": {
              "length": 5,
              "location": 43
            },
            "snippets": [
              {
                "text": "libre",
                "word_type": "adjective",
                "id": "2kz1Se9Y"
              }
            ],
            "text": "libre",
            "id": "wX5JApKw"
          }
        ],
        "text": "Sara: Sí, ¡ya necesitaba un poco de tiempo libre!",
        "id": "SnwGzyZq"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "0DJMoGe4"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "D9A8HxJF"
              }
            ],
            "text": "Luis",
            "id": "1JOWto6I"
          },
          {
            "range": {
              "length": 7,
              "location": 7
            },
            "snippets": [
              {
                "text": "qué tal",
                "word_type": "interjection",
                "id": "Zzehghop"
              },
              {
                "text": "qué",
                "word_type": "pronoun",
                "id": "wNh8mmmJ"
              },
              {
                "text": "tal",
                "word_type": "adverb",
                "id": "jHT7qe3C"
              }
            ],
            "text": "Qué tal",
            "id": "bM68iZ49"
          },
          {
            "range": {
              "length": 2,
              "location": 15
            },
            "snippets": [
              {
                "text": "tu",
                "word_type": "pronoun",
                "id": "n0aK88ik"
              }
            ],
            "text": "tu",
            "id": "rpqqxiwf"
          },
          {
            "range": {
              "length": 3,
              "location": 18
            },
            "snippets": [
              {
                "text": "día",
                "word_type": "noun",
                "id": "yWCZoK8v"
              }
            ],
            "text": "día",
            "id": "msPQDjs3"
          },
          {
            "range": {
              "length": 5,
              "location": 22
            },
            "snippets": [
              {
                "text": "hasta",
                "word_type": "preposition",
                "id": "ChfcN6Pt"
              }
            ],
            "text": "hasta",
            "id": "w4rt8Rvg"
          },
          {
            "range": {
              "length": 5,
              "location": 28
            },
            "snippets": [
              {
                "text": "ahora",
                "word_type": "adverb",
                "id": "XIpNCllA"
              }
            ],
            "text": "ahora",
            "id": "3A3AV3lE"
          }
        ],
        "text": "Luis: ¿Qué tal tu día hasta ahora?",
        "id": "yjqnQRlq"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "l1I0Sxjp"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "pUUsQs6B"
              }
            ],
            "text": "Sara",
            "id": "UIOizfUw"
          },
          {
            "range": {
              "length": 4,
              "location": 6
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "ETlAnI9g"
              }
            ],
            "text": "Bien",
            "id": "8xsnbhCA"
          },
          {
            "range": {
              "length": 6,
              "location": 12
            },
            "snippets": [
              {
                "text": "aunque",
                "word_type": "conjunction",
                "id": "JeSKNzAe"
              }
            ],
            "text": "aunque",
            "id": "yPY4zXZJ"
          },
          {
            "range": {
              "length": 2,
              "location": 19
            },
            "snippets": [
              {
                "text": "ha",
                "word_type": "verb",
                "infinitive": "haber",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "id": "c3AmRlzV"
              }
            ],
            "text": "ha",
            "id": "oaWQvTAf"
          },
          {
            "range": {
              "length": 4,
              "location": 22
            },
            "snippets": [
              {
                "text": "sido",
                "word_type": "participle",
                "gender": null,
                "infinitive": "ser",
                "id": "fARjGp5L"
              }
            ],
            "text": "sido",
            "id": "uy8y8U7U"
          },
          {
            "range": {
              "length": 2,
              "location": 27
            },
            "snippets": [
              {
                "text": "un",
                "word_type": "article",
                "id": "oVkyEroO"
              }
            ],
            "text": "un",
            "id": "DtEyaJBd"
          },
          {
            "range": {
              "length": 4,
              "location": 30
            },
            "snippets": [
              {
                "text": "poco",
                "word_type": "adjective",
                "id": "qc4CtUFl"
              }
            ],
            "text": "poco",
            "id": "gGLC3Bkk"
          },
          {
            "range": {
              "length": 5,
              "location": 35
            },
            "snippets": [
              {
                "text": "lento",
                "word_type": "adjective",
                "id": "gyLJFxn8"
              }
            ],
            "text": "lento",
            "id": "RXYGt3S3"
          },
          {
            "range": {
              "length": 1,
              "location": 43
            },
            "snippets": [
              {
                "text": "y",
                "word_type": "conjunction",
                "id": "0MW56ohl"
              }
            ],
            "text": "Y",
            "id": "IvsfN1XU"
          },
          {
            "range": {
              "length": 2,
              "location": 45
            },
            "snippets": [
              {
                "text": "el",
                "word_type": "article",
                "id": "ZHeoqvU3"
              }
            ],
            "text": "el",
            "id": "mOtBPHiW"
          },
          {
            "range": {
              "length": 4,
              "location": 48
            },
            "snippets": [
              {
                "text": "tuyo",
                "word_type": "pronoun",
                "id": "vaJ2bQd0"
              }
            ],
            "text": "tuyo",
            "id": "X1G9fcvA"
          }
        ],
        "text": "Sara: Bien, aunque ha sido un poco lento. ¿Y el tuyo?",
        "id": "gmK9jEHt"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "hlncXw51"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "BdiSIbbG"
              }
            ],
            "text": "Luis",
            "id": "hOMdXJgb"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "text": "igual",
                "word_type": "adjective",
                "id": "iEqEvckg"
              }
            ],
            "text": "Igual",
            "id": "G1XQ5eHi"
          },
          {
            "range": {
              "length": 9,
              "location": 13
            },
            "snippets": [
              {
                "text": "tranquilo",
                "word_type": "adjective",
                "id": "Q7tdYm5j"
              }
            ],
            "text": "tranquilo",
            "id": "jGiJ7vsY"
          },
          {
            "range": {
              "length": 5,
              "location": 24
            },
            "snippets": [
              {
                "text": "mucho",
                "word_type": "adjective",
                "id": "NcvDQfJR"
              }
            ],
            "text": "Mucho",
            "id": "J7pAhqdU"
          },
          {
            "range": {
              "length": 7,
              "location": 30
            },
            "snippets": [
              {
                "text": "trabajo",
                "word_type": "noun",
                "id": "Oz9Sx0gA"
              }
            ],
            "text": "trabajo",
            "id": "6FA1kakl"
          },
          {
            "range": {
              "length": 4,
              "location": 39
            },
            "snippets": [
              {
                "text": "pero",
                "word_type": "conjunction",
                "id": "ADqyXCww"
              }
            ],
            "text": "pero",
            "id": "TLtURBxT"
          },
          {
            "range": {
              "length": 4,
              "location": 44
            },
            "snippets": [
              {
                "text": "todo",
                "word_type": "pronoun",
                "id": "ygba7EVf"
              }
            ],
            "text": "todo",
            "id": "7tpMvN1R"
          },
          {
            "range": {
              "length": 4,
              "location": 49
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "ZqSDxTbS"
              }
            ],
            "text": "bien",
            "id": "YDbn1s14"
          }
        ],
        "text": "Luis: Igual, tranquilo. Mucho trabajo, pero todo bien.",
        "id": "Ckz4waN2"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "NLithsWq"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "l0UMdaun"
              }
            ],
            "text": "Sara",
            "id": "vtewW8NO"
          },
          {
            "range": {
              "length": 6,
              "location": 6
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "tener",
                "person": 2,
                "tense": "present",
                "text": "tienes",
                "word_type": "verb",
                "id": "Ke81wk8t"
              }
            ],
            "text": "tienes",
            "id": "N5UF78hn"
          },
          {
            "range": {
              "length": 6,
              "location": 14
            },
            "snippets": [
              {
                "text": "planes",
                "word_type": "noun",
                "id": "HXowPjnh"
              },
              {
                "text": "plan",
                "word_type": "noun",
                "id": "AI6AjbSV"
              }
            ],
            "text": "planes",
            "id": "sDR5dyLZ"
          },
          {
            "range": {
              "length": 4,
              "location": 21
            },
            "snippets": [
              {
                "text": "para",
                "word_type": "preposition",
                "id": "6mGtgyjY"
              }
            ],
            "text": "para",
            "id": "VL1sGsgs"
          },
          {
            "range": {
              "length": 8,
              "location": 26
            },
            "snippets": [
              {
                "text": "almorzar",
                "word_type": "verb",
                "infinitive": "almorzar",
                "id": "uB5RJR1Z"
              }
            ],
            "text": "almorzar",
            "id": "65NVXFSR"
          }
        ],
        "text": "Sara: ¿Tienes planes para almorzar?",
        "id": "mFPpmTf2"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "fAoqFa3N"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "vkOt93Cb"
              }
            ],
            "text": "Luis",
            "id": "1DtL7KiF"
          },
          {
            "range": {
              "length": 3,
              "location": 6
            },
            "snippets": [
              {
                "text": "tal",
                "word_type": "adverb",
                "id": "l2tzq9TE"
              }
            ],
            "text": "tal",
            "id": "3dcUN2tf"
          },
          {
            "range": {
              "length": 3,
              "location": 10
            },
            "snippets": [
              {
                "text": "vez",
                "word_type": "noun",
                "id": "XjWiaGNF"
              }
            ],
            "text": "vez",
            "id": "itY58KQ3"
          },
          {
            "range": {
              "length": 2,
              "location": 14
            },
            "snippets": [
              {
                "text": "ir",
                "word_type": "verb",
                "infinitive": "ir",
                "id": "eeBuQPic"
              }
            ],
            "text": "ir",
            "id": "6mlFBLsl"
          },
          {
            "range": {
              "length": 1,
              "location": 17
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "DTCkPae9"
              }
            ],
            "text": "a",
            "id": "LtuznyaN"
          },
          {
            "range": {
              "length": 2,
              "location": 19
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "HjH63bSW"
              }
            ],
            "text": "la",
            "id": "7NHemKwt"
          },
          {
            "range": {
              "length": 9,
              "location": 22
            },
            "snippets": [
              {
                "text": "cafetería",
                "word_type": "noun",
                "id": "0FEsTJtZ"
              }
            ],
            "text": "cafetería",
            "id": "veu4cxAf"
          },
          {
            "range": {
              "length": 2,
              "location": 32
            },
            "snippets": [
              {
                "text": "de",
                "word_type": "preposition",
                "id": "MJeG3D4s"
              }
            ],
            "text": "de",
            "id": "uUYy0sWj"
          },
          {
            "range": {
              "length": 2,
              "location": 35
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "fe67bBMg"
              }
            ],
            "text": "la",
            "id": "y72dw9al"
          },
          {
            "range": {
              "length": 7,
              "location": 38
            },
            "snippets": [
              {
                "text": "esquina",
                "word_type": "noun",
                "id": "IzQ93MOP"
              }
            ],
            "text": "esquina",
            "id": "gLBqhu8r"
          },
          {
            "range": {
              "length": 7,
              "location": 48
            },
            "snippets": [
              {
                "text": "quieres",
                "word_type": "verb",
                "infinitive": "querer",
                "person": 2,
                "tense": "present",
                "count": "singular",
                "id": "CMLYQohG"
              }
            ],
            "text": "quieres",
            "id": "y15GfJrc"
          },
          {
            "range": {
              "length": 5,
              "location": 56
            },
            "snippets": [
              {
                "text": "venir",
                "word_type": "verb",
                "infinitive": "venir",
                "id": "GWCA4BHM"
              }
            ],
            "text": "venir",
            "id": "6P1i7KQ5"
          }
        ],
        "text": "Luis: Tal vez ir a la cafetería de la esquina. ¿Quieres venir?",
        "id": "rMj0osIV"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "IdqH4bKy"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "mVbvgqm6"
              }
            ],
            "text": "Sara",
            "id": "iewWxPCc"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "text": "claro",
                "word_type": "interjection",
                "id": "MbYUaNM0"
              }
            ],
            "text": "Claro",
            "id": "iuKrUMfa"
          },
          {
            "range": {
              "length": 5,
              "location": 13
            },
            "snippets": [
              {
                "gender": "masculine",
                "infinitive": "sonar",
                "tense": "present",
                "text": "suena",
                "word_type": "verb",
                "id": "uuF3Vwew"
              }
            ],
            "text": "suena",
            "id": "1xB6CUF6"
          },
          {
            "range": {
              "length": 4,
              "location": 19
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "c52drYbl"
              }
            ],
            "text": "bien",
            "id": "OLaZ0lQ5"
          },
          {
            "range": {
              "length": 2,
              "location": 26
            },
            "snippets": [
              {
                "text": "es",
                "count": "singular",
                "infinitive": "ser",
                "person": 3,
                "tense": "present",
                "word_type": "verb",
                "id": "As9p3Fsz"
              }
            ],
            "text": "Es",
            "id": "XerLZb4X"
          },
          {
            "range": {
              "length": 4,
              "location": 29
            },
            "snippets": [
              {
                "text": "caro",
                "word_type": "adjective",
                "id": "X2GRGI3l"
              }
            ],
            "text": "caro",
            "id": "p3rIYhGy"
          }
        ],
        "text": "Sara: Claro, suena bien. ¿Es caro?",
        "id": "inVPh31i"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "lSgGsEp0"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "qyB5y5Yh"
              }
            ],
            "text": "Luis",
            "id": "4UBTbyZG"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "no",
                "word_type": "adverb",
                "id": "mLIDWDTv"
              }
            ],
            "text": "No",
            "id": "JESmJauZ"
          },
          {
            "range": {
              "length": 5,
              "location": 9
            },
            "snippets": [
              {
                "text": "mucho",
                "word_type": "adverb",
                "id": "2fgF5Ypg"
              }
            ],
            "text": "mucho",
            "id": "LyaO2Chv"
          },
          {
            "range": {
              "length": 6,
              "location": 16
            },
            "snippets": [
              {
                "person": 3,
                "tense": "present",
                "count": "plural",
                "infinitive": "tener",
                "text": "tienen",
                "word_type": "verb",
                "id": "VxkD8qFI"
              }
            ],
            "text": "Tienen",
            "id": "EqfziVO5"
          },
          {
            "range": {
              "length": 5,
              "location": 23
            },
            "snippets": [
              {
                "text": "menús",
                "word_type": "noun",
                "id": "8cIhdnID"
              },
              {
                "text": "menú",
                "word_type": "noun",
                "id": "RVUDt2c7"
              }
            ],
            "text": "menús",
            "id": "lgkEHi8p"
          },
          {
            "range": {
              "length": 7,
              "location": 29
            },
            "snippets": [
              {
                "text": "baratos",
                "word_type": "adjective",
                "id": "MrWbuKv1"
              },
              {
                "text": "barato",
                "word_type": "adjective",
                "id": "Nt7uf96W"
              }
            ],
            "text": "baratos",
            "id": "nazVqfRx"
          }
        ],
        "text": "Luis: No mucho. Tienen menús baratos.",
        "id": "bJGPfp6z"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "HuU8jMs9"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper noun",
                "id": "oeoaJIPP"
              }
            ],
            "text": "Sara",
            "id": "DCIsqvw8"
          },
          {
            "range": {
              "length": 8,
              "location": 6
            },
            "snippets": [
              {
                "text": "perfecto",
                "word_type": "adjective",
                "id": "9uisuAd8"
              }
            ],
            "text": "Perfecto",
            "id": "PzVdb4py"
          },
          {
            "range": {
              "length": 2,
              "location": 16
            },
            "snippets": [
              {
                "text": "me",
                "word_type": "pronoun",
                "id": "eZsgY0e6"
              }
            ],
            "text": "me",
            "id": "qEu9xvOa"
          },
          {
            "range": {
              "length": 5,
              "location": 19
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "gustar",
                "person": 1,
                "tense": "present",
                "text": "gusta",
                "word_type": "verb",
                "id": "dRQoxI04"
              }
            ],
            "text": "gusta",
            "id": "6tvRwyJ5"
          },
          {
            "range": {
              "length": 5,
              "location": 25
            },
            "snippets": [
              {
                "text": "comer",
                "word_type": "verb",
                "id": "jjKwx9rH"
              }
            ],
            "text": "comer",
            "id": "j9T19C8M"
          },
          {
            "range": {
              "length": 4,
              "location": 31
            },
            "snippets": [
              {
                "text": "algo",
                "word_type": "pronoun",
                "id": "FSInllvs"
              }
            ],
            "text": "algo",
            "id": "ZYRx2pBj"
          },
          {
            "range": {
              "length": 8,
              "location": 36
            },
            "snippets": [
              {
                "text": "sencillo",
                "word_type": "adjective",
                "id": "eM65qEOC"
              }
            ],
            "text": "sencillo",
            "id": "6lOvHFtw"
          },
          {
            "range": {
              "length": 2,
              "location": 45
            },
            "snippets": [
              {
                "text": "en",
                "word_type": "preposition",
                "id": "SMT9Anxm"
              }
            ],
            "text": "en",
            "id": "JHwHlGbD"
          },
          {
            "range": {
              "length": 2,
              "location": 48
            },
            "snippets": [
              {
                "text": "el",
                "word_type": "article",
                "id": "vEoJU0hj"
              }
            ],
            "text": "el",
            "id": "GEho284B"
          },
          {
            "range": {
              "length": 8,
              "location": 51
            },
            "snippets": [
              {
                "text": "almuerzo",
                "word_type": "noun",
                "id": "mvjFf3Oa"
              }
            ],
            "text": "almuerzo",
            "id": "oenVTktl"
          }
        ],
        "text": "Sara: Perfecto, me gusta comer algo sencillo en el almuerzo.",
        "id": "DOMxkve2"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "MaWRWMCB"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper noun",
                "id": "Rhbj4A4h"
              }
            ],
            "text": "Luis",
            "id": "UhS5Fs6l"
          },
          {
            "range": {
              "length": 1,
              "location": 6
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "YM5StYot"
              }
            ],
            "text": "A",
            "id": "BQx1ejuq"
          },
          {
            "range": {
              "length": 2,
              "location": 8
            },
            "snippets": [
              {
                "text": "mí",
                "word_type": "pronoun",
                "id": "VrzRvr12"
              }
            ],
            "text": "mí",
            "id": "CbdisTLt"
          },
          {
            "range": {
              "length": 7,
              "location": 11
            },
            "snippets": [
              {
                "text": "también",
                "word_type": "adverb",
                "id": "vI6ites0"
              }
            ],
            "text": "también",
            "id": "TqBpVVBG"
          },
          {
            "range": {
              "length": 6,
              "location": 20
            },
            "snippets": [
              {
                "text": "además",
                "word_type": "adverb",
                "id": "WvDMeMwa"
              }
            ],
            "text": "Además",
            "id": "J7bVv4SX"
          },
          {
            "range": {
              "length": 2,
              "location": 28
            },
            "snippets": [
              {
                "text": "es",
                "word_type": "verb",
                "infinitive": "ser",
                "person": 3,
                "tense": "present",
                "count": "singular",
                "id": "Su24nzEN"
              }
            ],
            "text": "es",
            "id": "VsFaDdiB"
          },
          {
            "range": {
              "length": 6,
              "location": 31
            },
            "snippets": [
              {
                "text": "rápido",
                "word_type": "adjective",
                "id": "v37cFRwG"
              }
            ],
            "text": "rápido",
            "id": "WQniY15U"
          },
          {
            "range": {
              "length": 1,
              "location": 38
            },
            "snippets": [
              {
                "text": "y",
                "word_type": "conjunction",
                "id": "uFSfpwIK"
              }
            ],
            "text": "y",
            "id": "RieB5iZK"
          },
          {
            "range": {
              "length": 7,
              "location": 40
            },
            "snippets": [
              {
                "text": "tenemos",
                "word_type": "verb",
                "infinitive": "tener",
                "person": 1,
                "tense": "present",
                "count": "plural",
                "id": "EX0VE9FU"
              }
            ],
            "text": "tenemos",
            "id": "MhBD8PuZ"
          },
          {
            "range": {
              "length": 3,
              "location": 48
            },
            "snippets": [
              {
                "text": "que",
                "word_type": "conjunction",
                "id": "IpE9rInZ"
              }
            ],
            "text": "que",
            "id": "BHtHbCIW"
          },
          {
            "range": {
              "length": 6,
              "location": 52
            },
            "snippets": [
              {
                "text": "volver",
                "word_type": "verb",
                "id": "NFhgNd29"
              }
            ],
            "text": "volver",
            "id": "9u1VElVK"
          },
          {
            "range": {
              "length": 6,
              "location": 59
            },
            "snippets": [
              {
                "text": "pronto",
                "word_type": "adverb",
                "id": "O80IkmWn"
              }
            ],
            "text": "pronto",
            "id": "NJgFN7Gj"
          }
        ],
        "text": "Luis: A mí también. Además, es rápido y tenemos que volver pronto.",
        "id": "jWruM0Vi"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "mzwaDzvo"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "pAKalgTz"
              }
            ],
            "text": "Sara",
            "id": "bI4hP9cK"
          },
          {
            "range": {
              "length": 2,
              "location": 7
            },
            "snippets": [
              {
                "text": "te",
                "word_type": "pronoun",
                "id": "wfEMl1f5"
              }
            ],
            "text": "Te",
            "id": "Oi3M3d98"
          },
          {
            "range": {
              "length": 5,
              "location": 10
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "gustar",
                "person": 3,
                "tense": "present",
                "text": "gusta",
                "word_type": "verb",
                "id": "CfeG9WgA"
              }
            ],
            "text": "gusta",
            "id": "r6nBMKWL"
          },
          {
            "range": {
              "length": 2,
              "location": 16
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "tNPciAZt"
              }
            ],
            "text": "la",
            "id": "TnrgbKjG"
          },
          {
            "range": {
              "length": 6,
              "location": 19
            },
            "snippets": [
              {
                "text": "comida",
                "word_type": "noun",
                "id": "9YG9Dw9U"
              }
            ],
            "text": "comida",
            "id": "I6sYsy76"
          },
          {
            "range": {
              "length": 4,
              "location": 26
            },
            "snippets": [
              {
                "text": "allí",
                "word_type": "adverb",
                "id": "U0Xp0jDQ"
              }
            ],
            "text": "allí",
            "id": "NCxGi8Nq"
          }
        ],
        "text": "Sara: ¿Te gusta la comida allí?",
        "id": "x2pfrrH1"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "Trkq1VDI"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "Cx9RbDrE"
              }
            ],
            "text": "Luis",
            "id": "uyFAMdTF"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "sí",
                "word_type": "interjection",
                "id": "UK5P6vis"
              }
            ],
            "text": "Sí",
            "id": "cATSY3QC"
          },
          {
            "range": {
              "length": 2,
              "location": 10
            },
            "snippets": [
              {
                "text": "es",
                "word_type": "verb",
                "person": 3,
                "count": "singular",
                "tense": "present",
                "infinitive": "ser",
                "id": "w76DbOzn"
              }
            ],
            "text": "es",
            "id": "DZvqBhoH"
          },
          {
            "range": {
              "length": 4,
              "location": 13
            },
            "snippets": [
              {
                "text": "rica",
                "word_type": "adjective",
                "id": "fN5uExrE"
              },
              {
                "text": "rico",
                "word_type": "adjective",
                "id": "U1NE3dhS"
              }
            ],
            "text": "rica",
            "id": "QFKO4Pbi"
          },
          {
            "range": {
              "length": 1,
              "location": 20
            },
            "snippets": [
              {
                "text": "y",
                "word_type": "conjunction",
                "id": "5TLVUigD"
              }
            ],
            "text": "Y",
            "id": "nWrKeLcN"
          },
          {
            "range": {
              "length": 1,
              "location": 22
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "9FyV0agx"
              }
            ],
            "text": "a",
            "id": "2RWm1AIv"
          },
          {
            "range": {
              "length": 2,
              "location": 24
            },
            "snippets": [
              {
                "text": "ti",
                "word_type": "pronoun",
                "id": "FFHKqGr8"
              }
            ],
            "text": "ti",
            "id": "9khwYtRS"
          }
        ],
        "text": "Luis: Sí, es rica. ¿Y a ti?",
        "id": "MivoBtwo"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "r0n6wGf5"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper noun",
                "id": "ZINMOt1j"
              }
            ],
            "text": "Sara",
            "id": "3JVa8RmE"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "me",
                "word_type": "pronoun",
                "id": "hjUzJpTz"
              }
            ],
            "text": "Me",
            "id": "keGnPtEe"
          },
          {
            "range": {
              "length": 5,
              "location": 9
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "gustar",
                "person": 3,
                "tense": "present",
                "text": "gusta",
                "word_type": "verb",
                "id": "23MVUPOw"
              }
            ],
            "text": "gusta",
            "id": "43A4wlGJ"
          },
          {
            "range": {
              "length": 13,
              "location": 16
            },
            "snippets": [
              {
                "text": "especialmente",
                "word_type": "adverb",
                "id": "AdJkAfLu"
              }
            ],
            "text": "Especialmente",
            "id": "G6rl7fnq"
          },
          {
            "range": {
              "length": 2,
              "location": 30
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "LZqOdPJf"
              }
            ],
            "text": "la",
            "id": "Iu8Vr0OK"
          },
          {
            "range": {
              "length": 8,
              "location": 33
            },
            "snippets": [
              {
                "text": "ensalada",
                "word_type": "noun",
                "id": "xX3TZIdw"
              }
            ],
            "text": "ensalada",
            "id": "hKZDBktv"
          }
        ],
        "text": "Sara: Me gusta. Especialmente la ensalada.",
        "id": "K99QDrKY"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "hUEryt8b"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper noun",
                "id": "XDSFGQ31"
              }
            ],
            "text": "Luis",
            "id": "1pXRY8AQ"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "yo",
                "word_type": "pronoun",
                "id": "YetU1WFj"
              }
            ],
            "text": "Yo",
            "id": "FbucDKKJ"
          },
          {
            "range": {
              "length": 8,
              "location": 9
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "preferir",
                "person": 1,
                "tense": "present",
                "text": "prefiero",
                "word_type": "verb",
                "id": "5A2h1U1e"
              }
            ],
            "text": "prefiero",
            "id": "6lHeRV89"
          },
          {
            "range": {
              "length": 3,
              "location": 18
            },
            "snippets": [
              {
                "text": "los",
                "word_type": "article",
                "id": "JvNcHWH6"
              }
            ],
            "text": "los",
            "id": "kJOByihM"
          },
          {
            "range": {
              "length": 10,
              "location": 22
            },
            "snippets": [
              {
                "text": "sándwiches",
                "word_type": "noun",
                "id": "wN9DjOvC"
              },
              {
                "text": "sándwich",
                "word_type": "noun",
                "id": "fh9ujcDI"
              }
            ],
            "text": "sándwiches",
            "id": "LqsCv2px"
          }
        ],
        "text": "Luis: Yo prefiero los sándwiches.",
        "id": "tiiDRud0"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "xOS8lDBa"
      },
      {
        "mappings": [
          {
            "range": {
              "location": 0,
              "length": 4
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "ANr6NV04"
              }
            ],
            "text": "Sara",
            "id": "CGukMRln"
          },
          {
            "range": {
              "location": 6,
              "length": 5
            },
            "snippets": [
              {
                "text": "buena",
                "word_type": "adjective",
                "id": "MrrZY69s"
              },
              {
                "text": "bueno",
                "word_type": "adjective",
                "id": "AGA9UjG6"
              }
            ],
            "text": "Buena",
            "id": "ieBOhDIY"
          },
          {
            "range": {
              "location": 12,
              "length": 8
            },
            "snippets": [
              {
                "text": "elección",
                "word_type": "noun",
                "id": "pQ8Vh97G"
              }
            ],
            "text": "elección",
            "id": "nmFryqu3"
          },
          {
            "range": {
              "location": 22,
              "length": 3
            },
            "snippets": [
              {
                "text": "hoy",
                "word_type": "adverb",
                "id": "goU31wS2"
              }
            ],
            "text": "Hoy",
            "id": "wix30mR9"
          },
          {
            "range": {
              "location": 26,
              "length": 6
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "querer",
                "person": 1,
                "tense": "present",
                "text": "quiero",
                "word_type": "verb",
                "id": "3zQ6BEY5"
              }
            ],
            "text": "quiero",
            "id": "j5NVMyqq"
          },
          {
            "range": {
              "location": 33,
              "length": 6
            },
            "snippets": [
              {
                "infinitive": "probar",
                "count": "singular",
                "person": 1,
                "tense": "present",
                "word_type": "verb",
                "text": "probar",
                "id": "eADrIvbF"
              }
            ],
            "text": "probar",
            "id": "BKrfIrcu"
          },
          {
            "range": {
              "location": 40,
              "length": 4
            },
            "snippets": [
              {
                "text": "algo",
                "word_type": "pronoun",
                "id": "uNhJb6XK"
              }
            ],
            "text": "algo",
            "id": "UMay6Xqo"
          },
          {
            "range": {
              "location": 45,
              "length": 9
            },
            "snippets": [
              {
                "text": "diferente",
                "word_type": "adjective",
                "id": "qXvw76bU"
              }
            ],
            "text": "diferente",
            "id": "LCQe4Fq7"
          }
        ],
        "text": "Sara: Buena elección. Hoy quiero probar algo diferente.",
        "id": "ULI3CIr8"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "tNIG1ois"
      },
      {
        "mappings": [
          {
            "range": {
              "location": 0,
              "length": 4
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "H2k6E2OV"
              }
            ],
            "text": "Luis",
            "id": "Z7KuQyIk"
          },
          {
            "range": {
              "length": 7,
              "location": 7
            },
            "snippets": [
              {
                "text": "qué tal",
                "word_type": "interjection",
                "id": "Asw6bMJg"
              },
              {
                "text": "qué",
                "word_type": "pronoun",
                "id": "gbojIRDP"
              },
              {
                "text": "tal",
                "word_type": "adverb",
                "id": "j1YBoiRO"
              }
            ],
            "text": "Qué tal",
            "id": "aTbNemQB"
          },
          {
            "range": {
              "location": 15,
              "length": 3
            },
            "snippets": [
              {
                "text": "una",
                "word_type": "article",
                "id": "ojMLrGrZ"
              },
              {
                "text": "un",
                "word_type": "article",
                "id": "YXsNeNm3"
              }
            ],
            "text": "una",
            "id": "4nEsS95I"
          },
          {
            "range": {
              "location": 19,
              "length": 4
            },
            "snippets": [
              {
                "text": "sopa",
                "word_type": "noun",
                "id": "7xTi76ta"
              }
            ],
            "text": "sopa",
            "id": "qwPnfYNq"
          }
        ],
        "text": "Luis: ¿Qué tal una sopa?",
        "id": "duUmwpBc"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "0zvurAVo"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "VtUKM0TQ"
              }
            ],
            "text": "Sara",
            "id": "gAwgPdAd"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "sonar",
                "person": 3,
                "tense": "present",
                "text": "suena",
                "word_type": "verb",
                "id": "47BKQu8s"
              }
            ],
            "text": "Suena",
            "id": "rOESMa7v"
          },
          {
            "range": {
              "length": 4,
              "location": 12
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "AueFeM0U"
              }
            ],
            "text": "bien",
            "id": "NFs9f0CP"
          },
          {
            "range": {
              "length": 2,
              "location": 18
            },
            "snippets": [
              {
                "text": "me",
                "word_type": "pronoun",
                "id": "S6nPy4UR"
              }
            ],
            "text": "Me",
            "id": "eqy6O89B"
          },
          {
            "range": {
              "length": 7,
              "location": 21
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "encantar",
                "person": 1,
                "tense": "present",
                "text": "encanta",
                "word_type": "verb",
                "id": "JPS1c5p5"
              }
            ],
            "text": "encanta",
            "id": "3KzMr5fx"
          },
          {
            "range": {
              "length": 2,
              "location": 29
            },
            "snippets": [
              {
                "text": "la",
                "word_type": "article",
                "id": "rBBJywGO"
              }
            ],
            "text": "la",
            "id": "UOMgtI6S"
          },
          {
            "range": {
              "length": 4,
              "location": 32
            },
            "snippets": [
              {
                "text": "sopa",
                "word_type": "noun",
                "id": "fWNDG5EH"
              }
            ],
            "text": "sopa",
            "id": "n1AmOOvj"
          }
        ],
        "text": "Sara: Suena bien. Me encanta la sopa.",
        "id": "rKQpyUV1"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "vL0eUjkz"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "aoAHoGNa"
              }
            ],
            "text": "Luis",
            "id": "BvSiPhW9"
          },
          {
            "range": {
              "length": 1,
              "location": 7
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "Z1g7OuYe"
              }
            ],
            "text": "A",
            "id": "oaj7MsOg"
          },
          {
            "range": {
              "length": 3,
              "location": 9
            },
            "snippets": [
              {
                "text": "qué",
                "word_type": "pronoun",
                "id": "qr4dRlPh"
              }
            ],
            "text": "qué",
            "id": "KbsOG0Wf"
          },
          {
            "range": {
              "length": 4,
              "location": 13
            },
            "snippets": [
              {
                "text": "hora",
                "word_type": "noun",
                "id": "PPcZ4qjG"
              }
            ],
            "text": "hora",
            "id": "4z4U38TB"
          },
          {
            "range": {
              "length": 10,
              "location": 18
            },
            "snippets": [
              {
                "count": "plural",
                "infinitive": "regresar",
                "person": 1,
                "tense": "present",
                "text": "regresamos",
                "word_type": "verb",
                "id": "Dh9Q5SiW"
              }
            ],
            "text": "regresamos",
            "id": "Ujz8MA0u"
          }
        ],
        "text": "Luis: ¿A qué hora regresamos?",
        "id": "q4THwEq3"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "6HJ1eZqe"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "PS5V5nwO"
              }
            ],
            "text": "Sara",
            "id": "tTT6FRLR"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "en",
                "word_type": "preposition",
                "id": "NJxdBlV9"
              }
            ],
            "text": "En",
            "id": "qataXEp6"
          },
          {
            "range": {
              "length": 5,
              "location": 9
            },
            "snippets": [
              {
                "text": "media",
                "word_type": "adjective",
                "id": "mcA8RBDp"
              },
              {
                "text": "medio",
                "word_type": "adjective",
                "id": "HtFItrJD"
              }
            ],
            "text": "media",
            "id": "exDZLC6Z"
          },
          {
            "range": {
              "length": 4,
              "location": 15
            },
            "snippets": [
              {
                "text": "hora",
                "word_type": "noun",
                "id": "ymN7lcGu"
              }
            ],
            "text": "hora",
            "id": "DcKg0tRs"
          },
          {
            "range": {
              "length": 4,
              "location": 21
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "creer",
                "person": 1,
                "tense": "present",
                "text": "creo",
                "word_type": "verb",
                "id": "aFKG8YaV"
              }
            ],
            "text": "creo",
            "id": "Ayt6vXeZ"
          },
          {
            "range": {
              "length": 2,
              "location": 28
            },
            "snippets": [
              {
                "text": "te",
                "word_type": "pronoun",
                "id": "lA23XAlp"
              }
            ],
            "text": "Te",
            "id": "LIiPuB1L"
          },
          {
            "range": {
              "length": 6,
              "location": 31
            },
            "snippets": [
              {
                "infinitive": "parecer",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "text": "parece",
                "word_type": "verb",
                "id": "XAtlevFH"
              }
            ],
            "text": "parece",
            "id": "u723su1d"
          }
        ],
        "text": "Sara: En media hora, creo. ¿Te parece?",
        "id": "YT19CELW"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "beyW8qLO"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "2bHhhUmL"
              }
            ],
            "text": "Luis",
            "id": "BSJ0qDMN"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "sí",
                "word_type": "interjection",
                "id": "4yQBH6Gb"
              }
            ],
            "text": "Sí",
            "id": "FTPv2sC4"
          },
          {
            "range": {
              "length": 4,
              "location": 10
            },
            "snippets": [
              {
                "infinitive": "estar",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "text": "está",
                "word_type": "verb",
                "id": "BlEnayHp"
              }
            ],
            "text": "está",
            "id": "7Zluag1R"
          },
          {
            "range": {
              "length": 4,
              "location": 15
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "xQTWxSrR"
              }
            ],
            "text": "bien",
            "id": "UoUCJef1"
          },
          {
            "range": {
              "length": 3,
              "location": 21
            },
            "snippets": [
              {
                "text": "así",
                "word_type": "adverb",
                "id": "1O8FvI3n"
              }
            ],
            "text": "Así",
            "id": "syE44thO"
          },
          {
            "range": {
              "length": 2,
              "location": 25
            },
            "snippets": [
              {
                "text": "no",
                "word_type": "adverb",
                "id": "hHGlvBOu"
              }
            ],
            "text": "no",
            "id": "3U424JH0"
          },
          {
            "range": {
              "length": 8,
              "location": 28
            },
            "snippets": [
              {
                "infinitive": "llegar",
                "tense": "present",
                "person": 1,
                "count": "plural",
                "text": "llegamos",
                "word_type": "verb",
                "id": "esaUANPP"
              }
            ],
            "text": "llegamos",
            "id": "mecexsk1"
          },
          {
            "range": {
              "length": 5,
              "location": 37
            },
            "snippets": [
              {
                "text": "tarde",
                "word_type": "adverb",
                "id": "bjUY4jmn"
              }
            ],
            "text": "tarde",
            "id": "xyl7yp06"
          }
        ],
        "text": "Luis: Sí, está bien. Así no llegamos tarde.",
        "id": "Q9paWtgZ"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "QVoNi6dc"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "RlYejoTg"
              }
            ],
            "text": "Sara",
            "id": "jTxOQQy3"
          },
          {
            "range": {
              "length": 4,
              "location": 7
            },
            "snippets": [
              {
                "text": "cómo",
                "word_type": "adverb",
                "id": "JFPhHbcK"
              }
            ],
            "text": "Cómo",
            "id": "HstrC61P"
          },
          {
            "range": {
              "length": 3,
              "location": 12
            },
            "snippets": [
              {
                "count": "plural",
                "infinitive": "ir",
                "person": 3,
                "tense": "present",
                "text": "van",
                "word_type": "verb",
                "id": "GQG7Q8Lg"
              }
            ],
            "text": "van",
            "id": "yrnQ2sVE"
          },
          {
            "range": {
              "length": 3,
              "location": 16
            },
            "snippets": [
              {
                "text": "tus",
                "word_type": "determiner",
                "id": "G5Ip8gOm"
              },
              {
                "text": "tu",
                "word_type": "determiner",
                "id": "c6UQRzez"
              }
            ],
            "text": "tus",
            "id": "TKa03ObK"
          },
          {
            "range": {
              "length": 9,
              "location": 20
            },
            "snippets": [
              {
                "text": "proyectos",
                "word_type": "noun",
                "id": "Sog7ICGK"
              },
              {
                "text": "proyecto",
                "word_type": "noun",
                "id": "Gl9VTsxH"
              }
            ],
            "text": "proyectos",
            "id": "Jy4Pdyhg"
          }
        ],
        "text": "Sara: ¿Cómo van tus proyectos?",
        "id": "8CKQJgdq"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "zdFrefFN"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "XwWhqEpN"
              }
            ],
            "text": "Luis",
            "id": "mzHeY0Py"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "text": "lento",
                "word_type": "adjective",
                "id": "uilMUfy1"
              }
            ],
            "text": "Lento",
            "id": "rUKMpHCT"
          },
          {
            "range": {
              "length": 4,
              "location": 13
            },
            "snippets": [
              {
                "text": "pero",
                "word_type": "conjunction",
                "id": "tETUVEkq"
              }
            ],
            "text": "pero",
            "id": "0qDxd0vi"
          },
          {
            "range": {
              "length": 9,
              "location": 18
            },
            "snippets": [
              {
                "text": "avanzando",
                "word_type": "participle",
                "id": "fIQjc9XJ"
              }
            ],
            "text": "avanzando",
            "id": "NiKMRlDL"
          },
          {
            "range": {
              "length": 5,
              "location": 29
            },
            "snippets": [
              {
                "text": "mucho",
                "word_type": "adjective",
                "id": "gZRsBMy4"
              }
            ],
            "text": "Mucho",
            "id": "4cu6U4On"
          },
          {
            "range": {
              "length": 7,
              "location": 35
            },
            "snippets": [
              {
                "text": "trabajo",
                "word_type": "noun",
                "id": "OK5z4AVB"
              }
            ],
            "text": "trabajo",
            "id": "Ckb9gmI7"
          },
          {
            "range": {
              "length": 2,
              "location": 44
            },
            "snippets": [
              {
                "text": "ya",
                "word_type": "adverb",
                "id": "ridMRoIr"
              }
            ],
            "text": "ya",
            "id": "PdFr8rPa"
          },
          {
            "range": {
              "length": 5,
              "location": 47
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "saber",
                "person": 2,
                "tense": "present",
                "text": "sabes",
                "word_type": "verb",
                "id": "LGPNkqca"
              }
            ],
            "text": "sabes",
            "id": "rnK1OAdq"
          }
        ],
        "text": "Luis: Lento, pero avanzando. Mucho trabajo, ya sabes.",
        "id": "GyadMK5t"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "RCVaLaHb"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper noun",
                "id": "MRV2oXh4"
              }
            ],
            "text": "Sara",
            "id": "3XOVTVGs"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "sí",
                "word_type": "adverb",
                "id": "asvZxOro"
              }
            ],
            "text": "Sí",
            "id": "nl9tXyjV"
          },
          {
            "range": {
              "length": 2,
              "location": 10
            },
            "snippets": [
              {
                "text": "te",
                "word_type": "pronoun",
                "id": "b4pf7hg9"
              }
            ],
            "text": "te",
            "id": "vL1k8Rza"
          },
          {
            "range": {
              "length": 8,
              "location": 13
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "entender",
                "person": 1,
                "tense": "present",
                "text": "entiendo",
                "word_type": "verb",
                "id": "Ou28e86v"
              }
            ],
            "text": "entiendo",
            "id": "V7r98abB"
          },
          {
            "range": {
              "length": 2,
              "location": 23
            },
            "snippets": [
              {
                "text": "mi",
                "word_type": "determiner",
                "id": "nASlJziU"
              }
            ],
            "text": "Mi",
            "id": "GHjXbGZR"
          },
          {
            "range": {
              "length": 8,
              "location": 26
            },
            "snippets": [
              {
                "text": "proyecto",
                "word_type": "noun",
                "id": "hCLxifAW"
              }
            ],
            "text": "proyecto",
            "id": "UIeQi287"
          },
          {
            "range": {
              "length": 7,
              "location": 35
            },
            "snippets": [
              {
                "text": "también",
                "word_type": "adverb",
                "id": "7mFwHQkj"
              }
            ],
            "text": "también",
            "id": "RiZmQecl"
          },
          {
            "range": {
              "length": 2,
              "location": 43
            },
            "snippets": [
              {
                "text": "es",
                "word_type": "verb",
                "infinitive": "ser",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "id": "erg9IcTB"
              }
            ],
            "text": "es",
            "id": "n1InFKK8"
          },
          {
            "range": {
              "length": 10,
              "location": 46
            },
            "snippets": [
              {
                "text": "complicado",
                "word_type": "adjective",
                "id": "SJYB1jsA"
              }
            ],
            "text": "complicado",
            "id": "X0uFRToG"
          }
        ],
        "text": "Sara: Sí, te entiendo. Mi proyecto también es complicado.",
        "id": "75FNlu4b"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "k74VRah3"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper noun",
                "id": "Ggnb3BrD"
              }
            ],
            "text": "Luis",
            "id": "sf3ZBfSQ"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "text": "bueno",
                "word_type": "adjective",
                "id": "OFPb7QGt"
              }
            ],
            "text": "Bueno",
            "id": "TUqg3NiE"
          },
          {
            "range": {
              "length": 9,
              "location": 13
            },
            "snippets": [
              {
                "text": "tranquilo",
                "word_type": "adjective",
                "id": "SWCOtHuo"
              }
            ],
            "text": "tranquilo",
            "id": "aSRNQWmy"
          },
          {
            "range": {
              "length": 2,
              "location": 24
            },
            "snippets": [
              {
                "text": "lo",
                "word_type": "pronoun",
                "id": "INJThXeY"
              }
            ],
            "text": "Lo",
            "id": "5rUY4xI4"
          },
          {
            "range": {
              "length": 10,
              "location": 27
            },
            "snippets": [
              {
                "text": "importante",
                "word_type": "adjective",
                "id": "OuWstYNs"
              }
            ],
            "text": "importante",
            "id": "boUQ5PDU"
          },
          {
            "range": {
              "length": 2,
              "location": 38
            },
            "snippets": [
              {
                "text": "es",
                "word_type": "verb",
                "infinitive": "ser",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "id": "LxbRZE6Y"
              }
            ],
            "text": "es",
            "id": "Z37TqwVd"
          },
          {
            "range": {
              "length": 7,
              "location": 41
            },
            "snippets": [
              {
                "text": "avanzar",
                "word_type": "verb",
                "infinitive": "avanzar",
                "id": "ON3E5Eas"
              }
            ],
            "text": "avanzar",
            "id": "m0R5r3Vt"
          },
          {
            "range": {
              "length": 4,
              "location": 49
            },
            "snippets": [
              {
                "text": "poco",
                "word_type": "adverb",
                "id": "Vdi9Fbcd"
              }
            ],
            "text": "poco",
            "id": "KE91Pims"
          },
          {
            "range": {
              "length": 1,
              "location": 54
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "61Zvq5qL"
              }
            ],
            "text": "a",
            "id": "sPX9TLUj"
          },
          {
            "range": {
              "length": 4,
              "location": 56
            },
            "snippets": [
              {
                "text": "poco",
                "word_type": "adverb",
                "id": "hByg9lsN"
              }
            ],
            "text": "poco",
            "id": "yH0OLPrc"
          }
        ],
        "text": "Luis: Bueno, tranquilo. Lo importante es avanzar poco a poco.",
        "id": "g7dnbdpA"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "AftnQWm7"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "TXZRBcCf"
              }
            ],
            "text": "Sara",
            "id": "uvhmb3mm"
          },
          {
            "range": {
              "length": 6,
              "location": 6
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "tener",
                "person": 2,
                "tense": "present",
                "text": "tienes",
                "word_type": "verb",
                "id": "XMcVMn27"
              }
            ],
            "text": "Tienes",
            "id": "KjYjhsS6"
          },
          {
            "range": {
              "length": 5,
              "location": 13
            },
            "snippets": [
              {
                "text": "razón",
                "word_type": "noun",
                "id": "E7mgLtc0"
              }
            ],
            "text": "razón",
            "id": "uW7LaBUL"
          },
          {
            "range": {
              "length": 9,
              "location": 20
            },
            "snippets": [
              {
                "text": "paciencia",
                "word_type": "noun",
                "id": "yunmCcdW"
              }
            ],
            "text": "Paciencia",
            "id": "ACPdCo1Y"
          },
          {
            "range": {
              "length": 2,
              "location": 30
            },
            "snippets": [
              {
                "text": "es",
                "count": "singular",
                "infinitive": "ser",
                "person": 3,
                "tense": "present",
                "word_type": "verb",
                "id": "NuZKDP2B"
              }
            ],
            "text": "es",
            "id": "atuwqr9M"
          },
          {
            "range": {
              "length": 5,
              "location": 33
            },
            "snippets": [
              {
                "text": "clave",
                "word_type": "noun",
                "id": "hkriItsk"
              }
            ],
            "text": "clave",
            "id": "7YeuHc2A"
          }
        ],
        "text": "Sara: Tienes razón. Paciencia es clave.",
        "id": "gB6sK1ip"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "2gxuIjwE"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "HPuwmkbe"
              }
            ],
            "text": "Luis",
            "id": "XHuEvAPg"
          },
          {
            "range": {
              "length": 10,
              "location": 6
            },
            "snippets": [
              {
                "text": "después de",
                "word_type": "preposition",
                "id": "JDIGHpjY"
              },
              {
                "text": "después",
                "word_type": "adverb",
                "id": "U9AP0WO6"
              }
            ],
            "text": "Después de",
            "id": "pbHcYC9E"
          },
          {
            "range": {
              "length": 3,
              "location": 14
            },
            "snippets": [
              {
                "text": "del",
                "word_type": "contraction",
                "id": "xDk3oPWD"
              }
            ],
            "text": "del",
            "id": "8gcl5a9r"
          },
          {
            "range": {
              "length": 8,
              "location": 18
            },
            "snippets": [
              {
                "text": "almuerzo",
                "word_type": "noun",
                "id": "PJ7ssiJ1"
              }
            ],
            "text": "almuerzo",
            "id": "xhqoZg8t"
          },
          {
            "range": {
              "length": 2,
              "location": 28
            },
            "snippets": [
              {
                "text": "me",
                "word_type": "pronoun",
                "id": "9QLR7QcL"
              }
            ],
            "text": "me",
            "id": "4hqhRDe5"
          },
          {
            "range": {
              "length": 6,
              "location": 31
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "sentir",
                "person": 1,
                "tense": "present",
                "text": "siento",
                "word_type": "verb",
                "id": "KJF5Hzsn"
              }
            ],
            "text": "siento",
            "id": "uFgq5IdG"
          },
          {
            "range": {
              "length": 3,
              "location": 38
            },
            "snippets": [
              {
                "text": "más",
                "word_type": "adverb",
                "id": "TD37tNvW"
              }
            ],
            "text": "más",
            "id": "kJmL4l99"
          },
          {
            "range": {
              "length": 11,
              "location": 42
            },
            "snippets": [
              {
                "text": "concentrado",
                "word_type": "adjective",
                "id": "55PFVPwY"
              }
            ],
            "text": "concentrado",
            "id": "iUF24r9E"
          }
        ],
        "text": "Luis: Después del almuerzo, me siento más concentrado.",
        "id": "chw1UxBh"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "mdc4rb3b"
      },
      {
        "mappings": [
          {
            "range": {
              "location": 0,
              "length": 4
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "hCreFNJ9"
              }
            ],
            "text": "Sara",
            "id": "ncwKvJOV"
          },
          {
            "range": {
              "location": 8,
              "length": 2
            },
            "snippets": [
              {
                "text": "mí",
                "word_type": "pronoun",
                "id": "R85B1ifj"
              }
            ],
            "text": "mí",
            "id": "8O413MyJ"
          },
          {
            "range": {
              "location": 11,
              "length": 7
            },
            "snippets": [
              {
                "text": "también",
                "word_type": "adverb",
                "id": "8YY4uzCf"
              }
            ],
            "text": "también",
            "id": "PVwQNZkN"
          },
          {
            "range": {
              "location": 12,
              "length": 1
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "clbAezGo"
              }
            ],
            "text": "a",
            "id": "Bao5PMc3"
          },
          {
            "range": {
              "location": 19,
              "length": 2
            },
            "snippets": [
              {
                "text": "me",
                "word_type": "pronoun",
                "id": "0LWU0tOj"
              }
            ],
            "text": "me",
            "id": "eli9NTWo"
          },
          {
            "range": {
              "location": 22,
              "length": 5
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "ayudar",
                "person": 3,
                "tense": "present",
                "text": "ayuda",
                "word_type": "verb",
                "id": "OeQrkgkx"
              }
            ],
            "text": "ayuda",
            "id": "QLavF7aG"
          },
          {
            "range": {
              "location": 29,
              "length": 3
            },
            "snippets": [
              {
                "text": "así",
                "word_type": "adverb",
                "id": "qc5mN6ij"
              }
            ],
            "text": "Así",
            "id": "3xLyhXcI"
          },
          {
            "range": {
              "location": 33,
              "length": 7
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "trabajar",
                "person": 1,
                "tense": "present",
                "text": "trabajo",
                "word_type": "verb",
                "id": "URX1OwRD"
              }
            ],
            "text": "trabajo",
            "id": "ZflsL4j6"
          },
          {
            "range": {
              "location": 41,
              "length": 5
            },
            "snippets": [
              {
                "text": "mejor",
                "word_type": "adverb",
                "id": "KXj1wiy4"
              }
            ],
            "text": "mejor",
            "id": "os8zWixz"
          }
        ],
        "text": "Sara: A mí también me ayuda. Así trabajo mejor.",
        "id": "IevGVKKd"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "qc9RoBYm"
      },
      {
        "mappings": [
          {
            "range": {
              "location": 0,
              "length": 4
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "ZVSJiMRV"
              }
            ],
            "text": "Luis",
            "id": "wyMLuDq0"
          },
          {
            "range": {
              "location": 6,
              "length": 8
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "querer",
                "person": 2,
                "tense": "present",
                "text": "quieres",
                "word_type": "verb",
                "id": "aVPaOjsq"
              }
            ],
            "text": "¿Quieres",
            "id": "tG2zdTdC"
          },
          {
            "range": {
              "location": 15,
              "length": 4
            },
            "snippets": [
              {
                "text": "algo",
                "word_type": "pronoun",
                "id": "oLe8NOJM"
              }
            ],
            "text": "algo",
            "id": "CLKvPNJt"
          },
          {
            "range": {
              "location": 20,
              "length": 2
            },
            "snippets": [
              {
                "text": "de",
                "word_type": "preposition",
                "id": "f9vGNrP3"
              }
            ],
            "text": "de",
            "id": "2QVVJ9m8"
          },
          {
            "range": {
              "location": 23,
              "length": 6
            },
            "snippets": [
              {
                "text": "postre",
                "word_type": "noun",
                "id": "2lUWwE1t"
              }
            ],
            "text": "postre",
            "id": "qrPI0qI7"
          }
        ],
        "text": "Luis: ¿Quieres algo de postre?",
        "id": "Loq7FPFX"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "SbvocWR0"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper_noun",
                "id": "TYxAn3an"
              }
            ],
            "text": "Sara",
            "id": "bMEkA2A7"
          },
          {
            "range": {
              "length": 3,
              "location": 6
            },
            "snippets": [
              {
                "text": "tal",
                "word_type": "adverb",
                "id": "XDfovynr"
              }
            ],
            "text": "Tal",
            "id": "meCO427T"
          },
          {
            "range": {
              "length": 3,
              "location": 10
            },
            "snippets": [
              {
                "text": "vez",
                "word_type": "noun",
                "id": "Q1dVyVtE"
              }
            ],
            "text": "vez",
            "id": "0Gewbxca"
          },
          {
            "range": {
              "length": 7,
              "location": 15
            },
            "snippets": [
              {
                "infinitive": "depender",
                "count": "singular",
                "person": 3,
                "tense": "present",
                "text": "depende",
                "word_type": "verb",
                "id": "oS3UV04R"
              }
            ],
            "text": "Depende",
            "id": "P8Bwh6hs"
          },
          {
            "range": {
              "length": 2,
              "location": 23
            },
            "snippets": [
              {
                "text": "de",
                "word_type": "preposition",
                "id": "Q56v4eDc"
              }
            ],
            "text": "de",
            "id": "kccRrDmn"
          },
          {
            "range": {
              "length": 6,
              "location": 26
            },
            "snippets": [
              {
                "text": "lo que",
                "word_type": "pronoun",
                "id": "qRGJG9p6"
              },
              {
                "text": "lo",
                "word_type": "pronoun",
                "id": "3GZe5pJt"
              },
              {
                "text": "que",
                "word_type": "conjunction",
                "id": "wL8Npsyq"
              }
            ],
            "text": "lo que",
            "id": "5b8fIABj"
          },
          {
            "range": {
              "length": 6,
              "location": 33
            },
            "snippets": [
              {
                "infinitive": "tener",
                "tense": "present",
                "person": 3,
                "count": "plural",
                "text": "tengan",
                "word_type": "verb",
                "id": "QwuL0u7m"
              }
            ],
            "text": "tengan",
            "id": "TgQhqGIP"
          }
        ],
        "text": "Sara: Tal vez. Depende de lo que tengan.",
        "id": "Lgxmo3u3"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "BUdlud8R"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Luis",
                "word_type": "proper_noun",
                "id": "VyFQZwzX"
              }
            ],
            "text": "Luis",
            "id": "zPh6T1yA"
          },
          {
            "range": {
              "length": 2,
              "location": 6
            },
            "snippets": [
              {
                "text": "sí",
                "word_type": "interjection",
                "id": "JLyht13C"
              }
            ],
            "text": "Sí",
            "id": "LAQ7TdQ0"
          },
          {
            "range": {
              "length": 5,
              "location": 10
            },
            "snippets": [
              {
                "infinitive": "ir",
                "tense": "present",
                "person": 1,
                "count": "plural",
                "text": "vamos",
                "word_type": "verb",
                "id": "RuR3iqcO"
              }
            ],
            "text": "vamos",
            "id": "NiuwWrkf"
          },
          {
            "range": {
              "length": 1,
              "location": 16
            },
            "snippets": [
              {
                "text": "a",
                "word_type": "preposition",
                "id": "7nM3f167"
              }
            ],
            "text": "a",
            "id": "RUqae2rO"
          },
          {
            "range": {
              "length": 3,
              "location": 18
            },
            "snippets": [
              {
                "infinitive": "ver",
                "word_type": "verb",
                "id": "IMoift8s"
              }
            ],
            "text": "ver",
            "id": "0byOPg4S"
          },
          {
            "range": {
              "length": 3,
              "location": 22
            },
            "snippets": [
              {
                "text": "qué",
                "word_type": "conjunction",
                "id": "6i5ARCOw"
              }
            ],
            "text": "qué",
            "id": "2pJ8zbsj"
          },
          {
            "range": {
              "length": 3,
              "location": 26
            },
            "snippets": [
              {
                "infinitive": "haber",
                "tense": "present",
                "person": 3,
                "count": "singular",
                "text": "hay",
                "word_type": "verb",
                "id": "W8FUC0QL"
              }
            ],
            "text": "hay",
            "id": "0JiM5gou"
          }
        ],
        "text": "Luis: Sí, vamos a ver qué hay.",
        "id": "BUjyZrRX"
      },
      {
        "new_line": true,
        "mappings": [],
        "id": "ljxCwjQw"
      },
      {
        "mappings": [
          {
            "range": {
              "length": 4,
              "location": 0
            },
            "snippets": [
              {
                "text": "Sara",
                "word_type": "proper noun",
                "id": "AeZrPdlU"
              }
            ],
            "text": "Sara",
            "id": "VIH9EeKO"
          },
          {
            "range": {
              "length": 5,
              "location": 6
            },
            "snippets": [
              {
                "count": "singular",
                "infinitive": "sonar",
                "person": 3,
                "tense": "present",
                "text": "suena",
                "word_type": "verb",
                "id": "3jNj0PSr"
              }
            ],
            "text": "Suena",
            "id": "jSxY2pge"
          },
          {
            "range": {
              "length": 4,
              "location": 12
            },
            "snippets": [
              {
                "text": "bien",
                "word_type": "adverb",
                "id": "RMpXDif5"
              }
            ],
            "text": "bien",
            "id": "mUK8RxjI"
          }
        ],
        "text": "Sara: Suena bien.",
        "id": "rISQAMqY"
      }
    ],
    "title_translations": {
      "en": "In the Office",
      "es-AR": "En la Oficina",
      "es-ES": "En la Oficina",
      "es-MX": "En la Oficina",
      "ru": "В Офисе",
      "fr": "Au Bureau"
    }
  }

const ManageStoryMode = {
    ADD_STORY: { id: "ADD_STORY", title: "Add Story" },
    GENERATE_MAPPINGS: { id: "GENERATE_MAPPINGS", title: "Gen. Mappings" },
    REVIEW: { id: "REVIEW", title: "Review" },
    all: () => [ManageStoryMode.ADD_STORY, ManageStoryMode.GENERATE_MAPPINGS, ManageStoryMode.REVIEW]
}

const AddStoryMode = {
    ADD_TEXT: { id: "ADD_TEXT", title: "Add Text" },
    GENERATE: { id: "GENERATE", title: "Generate" },
    GENERATE_AND_SAVE_TO_DB: { id: "GENERATE_AND_SAVE_TO_DB", title: "Generate and Save" },
    all: () => [AddStoryMode.ADD_TEXT, AddStoryMode.GENERATE, AddStoryMode.GENERATE_AND_SAVE_TO_DB]
}

const AddStoryLevel = {
    A1: { id: "A1", title: "A1" },
    A2: { id: "A2", title: "A2" },
    B1: { id: "B1", title: "B1" },
    B2: { id: "B2", title: "B2" },
    C1: { id: "C1", title: "C1" },
    C2: { id: "C2", title: "C2" },
    all: () => [AddStoryLevel.A1, AddStoryLevel.A2, AddStoryLevel.B1, AddStoryLevel.B2, AddStoryLevel.C1, AddStoryLevel.C2]
}

const colorSelectedStory = 'rgba(255, 255, 255, 0.25)'
const colorUnselectedStory = 'rgba(255, 255, 255, 0.05)'

function AdminManageStories() {

    // check sentence modal
    // state
    const [mode, setMode] = useState(ManageStoryMode.ADD_STORY.id);

    // Deleting
    const [deletingSnippetID, setDeletingSnippetID] = useState(null);
    const [isDeletingSnippet, setIsDeletingSnippet] = useState(false);

    // Update
    const [storiesToUpdate, setStoriesToUpdate] = useRecoilState(manageStoriesStoriesToUpdateState)
    const [isPublishing, setIsPublishing] = useState(false);
    const [isUpdatingData, setUpdatingData] = useState(false);
    const [canUpdateData, setCanUpdateData] = useState(false);
    // Data
    const [resultSnippets, setResultSnippets] = useState(null);
    // const [selectedMapping, setSelectedMapping] = useRecoilState(manageStoriesReviewSelectedMappingState)
    const [storyLanguage, setStoryLanguage] = useState(null);
    const [dictionaryLanguage, setDictionaryLanguage] = useState(null);

    // *** MODES ***
    // *** ADD STORY MODE ***
    const [addStoryMode, setAddStoryMode] = useState(AddStoryMode.ADD_TEXT.id)
    const [addStoryPlainText, setAddStoryPlainText] = useState('')
    const [addStoryGeneratedText, setAddStoryGeneratedText] = useState('')
    const [addStoryGeneratedIsSubmitting, setAddStoryGeneratedIsSubmitting] = useState(false)
    const [addStorySelectedLevel, setAddStorySelectedLevel] = useState(AddStoryLevel.A1.id)
    const [addStoryTitle, setAddStoryTitle] = useState('')
    const [addStoryIsGenerating, setAddStoryIsGenerating] = useState(false)
    const [addStoryIsSubmitting, setAddStoryIsSubmitting] = useState(false)
    const [addStoryCanSubmit, setAddStoryCanSubmit] = useState(false)

    // *** GENERATE MAPPINGS MODE ***
    const [genMappingsStories, setGenMappingsStories] = useState(null)
    const [selectedGenMappingStory, setSelectedGenMappingStory] = useState(null)
    const [selectedGenMappingStoryID, setSelectedGenMappingStoryID] = useState(null)
    const [generateMappingsText, setGenerateMappingsText] = useState(gmt)
    const [isSubmittingGenerateMappings, setIsSubmittingGenerateMappings] = useState(false)
    const [isGeneratingMappings, setIsGeneratingMappings] = useState(false)
    const [generateMappingsError, setGenerateMappingsError] = useState(null)
    const [localMappings, setLocalMappings] = useState({})

    // *** REVIEW MODE ***
    const { isOpen: isUploadReviewImageOpen, onOpen: onUploadReviewImageOpen, onClose: onUploadReviewImageClose } = useDisclosure()
    const { isOpen: isPublishOpen, onOpen: onPublishOpen, onClose: onPublishClose } = useDisclosure()
    const [reviewStories, setReviewStories] = useRecoilState(manageStoriesListStoriesState)
    const [selectedMappingID, setSelectedMappingID] = useRecoilState(manageStoriesSelectedMappingIDState)
    const [selectedReviewStoryID, setSelectedReviewStoryID] = useRecoilState(manageStoriesSelectedStoryIDState)
    const [selectedReviewStoryImageURL, setSelectedReviewStoryImageURL] = useState(null)
    const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);

    // grouping
    const [groupMode, setGroupMode] = useState(false);
    const [selectedGroupMappings, setSelectedGroupMappings] = useState([]);

    // Add/Edit Snippet modal
    const [selectedStoryMapping, setSelectedStoryMapping] = useRecoilState(manageStoriesReviewSnippetModalSelectedMappingState)
    const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
        showSnippetsModalState
    );

    // Page
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);
    const toast = useToast();
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState);
    const [chooseLanguageHeight, setChooseLanguageHeight] = useState(0);
    const chooseLanguageRef = useRef(null);

    const allModes = ManageStoryMode.all()
    const allAddStoryModes = AddStoryMode.all()

    const {
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser();

    useEffect(() => {
        console.log("Stories to update changed: ", JSON.stringify(storiesToUpdate, null, 2))
        setCanUpdateData(Object.keys(storiesToUpdate).length !== 0)
    }, [storiesToUpdate])

    useEffect(() => {
        let parentLanguage = null
        if (storyLanguage) {
            let code = LanguageVariants.parentLanguageForVariant(storyLanguage.code)
            if (code) {
                parentLanguage = getLanguageWithCode(code)
            }
        }

        setDictionaryLanguage(parentLanguage)
    }, [storyLanguage])

    useEffect(() => {
        setStoryLanguage(getStoryLanguageWithCode('es-MX'))

        // Update the height when the component mounts or ref changes
        if (chooseLanguageRef.current) {
            setChooseLanguageHeight(chooseLanguageRef.current.offsetHeight);
        }

        // Optional: add a resize observer to handle dynamic resizing
        const resizeObserver = new ResizeObserver(() => {
            if (chooseLanguageRef.current) {
                setChooseLanguageHeight(chooseLanguageRef.current.offsetHeight);
            }
        });

        if (chooseLanguageRef.current) {
            resizeObserver.observe(chooseLanguageRef.current);
        }
        // temp

        localMappings['671776e0eKEBBPooo'] = gmt

        return () => {
            if (chooseLanguageRef.current) {
                resizeObserver.unobserve(chooseLanguageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!storyLanguage?.code) {
            return;
        }
        if (mode === ManageStoryMode.REVIEW.id) {
            if (!reviewStories || reviewStories.length === 0) {
                // Create an async function and call it
                reloadReviewStories()
            }
        } else if (mode === ManageStoryMode.GENERATE_MAPPINGS.id) {
            if (!genMappingsStories || genMappingsStories.length === 0) {

                reloadGenMappingStories(); // Call the async function
            }
        }

    }, [mode, storyLanguage]);

    async function reloadReviewStories() {
        try {
            setSelectedReviewStoryID(null)
            setReviewStories(null)
            setSelectedMappingID(null)
            setSelectedStoryMapping(null)

            const stories = await getReviewStories(storyLanguage.code);
            setReviewStories(stories);
        } catch (error) {
            setReviewStories([]);
            toast({
                title: 'Error loading review stories',
                status: 'error'
            });
        }
    }

    async function reloadGenMappingStories() {
        try {
            setSelectedGenMappingStoryID(null)
            setGenMappingsStories(null)
            const stories = await getGenerateMappingStories(storyLanguage.code);
            setGenMappingsStories(stories);
        } catch (error) {
            setGenMappingsStories([]);
            toast({
                title: 'Error loading gm stories',
                status: 'error'
            });
        }
    }

    function getSelectedStory() {
        if (!selectedReviewStoryID || !reviewStories) {
            return null
        }

        return reviewStories.find((story) => story.id === selectedReviewStoryID)
    }

    function getSelectedMapping() {
        if (!selectedMappingID || !selectedReviewStoryID || !reviewStories) {
            return null
        }

        let selectedStory = reviewStories.find((story) => story.id === selectedReviewStoryID)

        let selectedMapping = null
        for (let sentence of selectedStory.sentences) {
            let mappings = sentence.mappings ?? []
            for (let mapping of mappings) {
                if (mapping.id === selectedMappingID) {
                    selectedMapping = mapping
                    break
                }
            }
        }

        return { mapping: selectedMapping, story: selectedStory }
    }

    useEffect(() => {
        setResultSnippets(null)

        // selectedMapping changed

        let mapping = getSelectedMapping()?.mapping

        let selectedStory = getSelectedMapping()?.story
        if (!mapping || !selectedStory) {
            return
        }

        console.log('onSelectMapping', mapping)

        let learningLanguageCode = dictionaryLanguage?.code

        if (!learningLanguageCode) {
            return
        }
        async function load() {
            try {
                setIsLoadingSnippet(true)

                let definitions = selectedStory.selected_definitions?.[userLanguage.code] ?? {}
                let mappingCopy = copyJSONObject(mapping)
                let { snippets, mappingChanged } = await loadStorySnippets(mappingCopy, definitions, learningLanguageCode, userLanguage.code, t)

                if (mappingChanged) {
                    console.log('Mapping changed, setting new mapping ' + JSON.stringify(mappingCopy, null, 2))
                    let newStory = copyJSONObject(selectedStory)
                    newStory = replaceStoryMapping(newStory, mapping.id, mappingCopy)
                    setDataChanged(newStory.id)
                    updateStory(newStory)
                }

                console.log('Result snippets ', JSON.stringify(snippets, null, 2))
                setResultSnippets(snippets)
            } catch (error) {
                console.error(error)
                setResultSnippets(null)
            } finally {
                setIsLoadingSnippet(false)
            }
        }

        load()

    }, [selectedMappingID, selectedReviewStoryID, storyLanguage, reviewStories])

    useEffect(() => {
        reloadReviewStoryImage()
        console.log('reviewStories or selectedReviewStoryID changed')
    }, [selectedReviewStoryID, reviewStories])

    function reloadReviewStoryImage() {
        let imageURL = null
        let story = getSelectedStory()
        if (story && story.imageURLPath) {
            imageURL = Constants.storageURLWithPath(story.imageURLPath)
        }

        console.log('Setting selected review story image URL to ' + imageURL + ' for story ' + story?.id)
        setSelectedReviewStoryImageURL(imageURL)
    }

    function onLanguageChanged(newLanguage) {
        setStoryLanguage(newLanguage)
        setGenMappingsStories(null)
        setReviewStories(null)
        setSelectedGenMappingStoryID(null)
        setSelectedGenMappingStory(null)
        setSelectedReviewStoryID(null)
        setSelectedMappingID(null)
        setSelectedStoryMapping(null)
    }

    function updateStory(newStory) {
        setSelectedReviewStoryID(newStory.id)
        let updatedStories = [...reviewStories]
        let idx = updatedStories.findIndex((story) => story.id === newStory.id)
        updatedStories[idx] = newStory
        setReviewStories(updatedStories)
    }

    const onSelectMapping = async (mapping) => {
        console.log('onSelectMapping called with ', JSON.stringify(mapping))
        if (groupMode) {
            setSelectedGroupMappings(mapping);
        } else {
            setSelectedMappingID(mapping?.id)
        }
    }

    function setDataChanged(storyID) {
        let stories = { ...storiesToUpdate }
        stories[storyID] = true
        setStoriesToUpdate(stories)
    }

    function onClickAddSnippet() {
        setSelectedStoryMapping(getSelectedMapping())
        showDefaultAddSnippetModal(setShowingSnippetsModal, t, null, null, AddEditSnippetModalType.ADD_TO_MAPPING(AddToArrayIndexStrategy.BEGINNING))
    }

    function onDeleteSnippetClicked(snippetID) {
        for (let idx in resultSnippets) {
            if (resultSnippets[idx].id === snippetID) {
                setDeletingSnippetID(snippetID)
                return
            }
        }
    }

    function doDeleteSelectedSnippet() {
        console.log('doDeleteSelectedSnippet', deletingSnippetID)
        let selectedMapping = getSelectedMapping()
        let mapping = selectedMapping?.mapping
        let selectedStory = selectedMapping?.story
        if (!mapping || !selectedStory || !isDefined(deletingSnippetID)) {
            console.error(`Invalid state to delete snippet ${deletingSnippetID} from mapping ${JSON.stringify(mapping, null, 2)} and story ${JSON.stringify(selectedStory, null, 2)}`)
            return
        }

        setDeletingSnippetID(null)
        setIsDeletingSnippet(false)

        // find index of snippetID in resultSnippets
        let idx = resultSnippets.findIndex((snippet) => snippet.id === deletingSnippetID)

        if (idx < 0) {
            console.error('Snippet not found with id ' + deletingSnippetID)
            return
        }

        let newStory = copyJSONObject(selectedStory)
        let copy = copyJSONObject(mapping)
        // remove snippet from snippets array with index idx
        copy.snippets = copy.snippets.filter((snippet, index) => index !== idx)
        console.log('New snippets ', JSON.stringify(copy.snippets, null, 2))
        // replace mapping
        newStory = replaceStoryMapping(newStory, mapping.id, copy)
        updateStory(newStory)
        onSelectMapping(copy)
        setDataChanged(newStory.id)
    }

    function onEditSnippet(snippetID) {
        // setSelectedMapping({ mapping: snippet.mapping, story: selectedStory })
        // setResultSnippets(null)
        let snippet = null
        let index = null
        for (let i = 0; i < resultSnippets.length; i++) {
            if (resultSnippets[i].id === snippetID) {
                snippet = resultSnippets[i]
                index = i
                break
            }
        }

        if (snippet && index !== null) {
            console.log('showing snippet modal for ' + snippetID)
            let searchModels = [snippet]
            setSelectedStoryMapping(getSelectedMapping())
            showAddEditSnippetModal(
                setShowingSnippetsModal,
                snippetID,
                null,
                t("sentences.edit_snippet_title"),
                null,
                searchModels,
                snippetID,
                null,
                null,
                snippet?.term,
                snippet?.definitionsLanguageCode,
                AddEditSnippetModalType.ADD_TO_MAPPING(AddToArrayIndexStrategy.REPLACE(index))
            )
        } else {
            console.error('Snippet not found with id ' + snippetID)
        }
    }

    async function doPublishStory() {
        if (isPublishing || selectedReviewStoryID === null) {
            return
        }

        if (canUpdateData) {
            toast({
                title: 'Please update stories before publishing',
                status: 'error'
            })
            return
        }

        try {
            setIsPublishing(true)

            let story = reviewStories.find((story) => story.id === selectedReviewStoryID)
            if (!story) {
                console.error('Story not found with id ' + selectedReviewStoryID)
                return
            }

            await publishStory(story.id, story.tempLanguageCode, story.level.toLowerCase())

            toast({
                title: 'Story published',
                status: 'success'
            })
            reloadReviewStories()

        } catch (e) {
            console.error('Error publishing', e)
            toast({
                title: 'Error publishing story: ' + e.message,
                status: 'error'
            })
        } finally {
            setIsPublishing(false)
            onPublishClose()
        }
    }

    async function onClickUpdate() {
        if (Object.keys(storiesToUpdate).length === 0) {
            return
        }

        setUpdatingData(true)

        let storyKeys = Object.keys(storiesToUpdate)
        let promises = storyKeys.map((storyID) => {
            let story = reviewStories.find((story) => story.id === storyID)
            if (!story) {
                console.error('Story not found with id ' + storyID)
                return Promise.resolve()
            }

            return updateReviewStory(story)
        })

        try {
            await Promise.all(promises)
            setStoriesToUpdate({})
            toast({
                title: 'Stories updated',
                status: 'success'
            })
        } catch (error) {
            console.error('Error updating stories', error)
            toast({
                title: 'Error updating stories',
                status: 'error'
            })
        } finally {
            setUpdatingData(false)
        }
    }

    // Add story

    useEffect(() => {
        setAddStoryCanSubmit(addStoryPlainText.trim().length > 10 && addStoryTitle.length > 2)
    }, [addStoryPlainText, addStoryTitle])

    async function onClickAddStoryGenerateAndSave() {
        if (addStoryIsGenerating) {
            return
        }

        setAddStoryIsGenerating(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()
        let languageCode = storyLanguage.code

        try {
            await generateStoryTextAndSaveToDB(languageCode, lowercasedLevel)

            await reloadGenMappingStories()
            toast({
                title: 'Story added. Open mappings tab to see the added story',
                status: 'success'
            })
        } catch (e) {
            console.error('Error generating story', e)
            toast({
                title: 'Error generating story ' + e,
                status: 'error'
            })
        } finally {
            setAddStoryIsGenerating(false)
        }
    }

    async function onClickAddStoryGenerate() {
        if (addStoryIsGenerating) {
            return
        }

        setAddStoryIsGenerating(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()
        let languageCode = storyLanguage.code

        try {
            let storyObject = await generateStoryText(languageCode, lowercasedLevel)
            // sleep for 2 seconds
            await new Promise(r => setTimeout(r, 2000))

            setAddStoryGeneratedText(JSON.stringify(storyObject, null, 2))
        } catch (e) {
            console.error('Error generating story', e)
            toast({
                title: 'Error generating story',
                status: 'error'
            })
        } finally {
            setAddStoryIsGenerating(false)
        }
    }

    async function onSubmitGeneratedStoryText() {
        if (addStoryGeneratedIsSubmitting) {
            return
        }

        setAddStoryGeneratedIsSubmitting(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()

        try {
            let story = JSON.parse(addStoryGeneratedText)
            if (story) {
                await addPlainTextStory(lowercasedLevel, storyLanguage.code, story)
                toast({
                    title: 'Story added',
                    status: 'success'
                })
                setGenMappingsStories(null)
                setAddStoryGeneratedText('')
            } else {
                throw new Error('Invalid story object')
            }

        } catch (e) {
            console.error('Error adding story', e)
            toast({
                title: 'Error adding story',
                status: 'error'
            })
        } finally {
            setAddStoryGeneratedIsSubmitting(false)
        }
    }

    async function onSubmitAddStoryPlainText() {
        if (addStoryIsSubmitting) {
            return
        }

        setAddStoryIsSubmitting(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()

        let story = {
            level: lowercasedLevel,
            title: addStoryTitle,
            text: addStoryPlainText,
        }

        try {
            await addPlainTextStory(lowercasedLevel, storyLanguage.code, story)
            toast({
                title: 'Story added',
                status: 'success'
            })
            setGenMappingsStories(null)
            setAddStoryPlainText('')
            setAddStoryTitle('')
        } catch (e) {
            console.error('Error adding story', e)
            toast({
                title: 'Error adding story',
                status: 'error'
            })
        } finally {
            setAddStoryIsSubmitting(false)
        }
    }

    // *** GENERATE MAPPINGS ***

    useEffect(() => {
        let errorText = validateGeneratedMappingsText(generateMappingsText)
        setGenerateMappingsError(errorText)

        console.log("Error text ", errorText)
    }, [generateMappingsText])

    useEffect(() => {
        let text = null
        if (selectedGenMappingStoryID) {
            console.log("selected id ", selectedGenMappingStoryID)
            let mappings = localMappings[selectedGenMappingStoryID]
            if (mappings) {
                text = JSON.stringify(mappings, null, 2)
            }
        }

        setGenerateMappingsText(text)

        let story = genMappingsStories?.find((story) => story.id === selectedGenMappingStoryID)
        setSelectedGenMappingStory(story)
    }, [selectedGenMappingStoryID])

    async function onClickGenerateMappings() {
        if (isSubmittingGenerateMappings) {
            return
        }

        if (!selectedGenMappingStoryID) {
            console.error('No story selected to generate mappings')
            return
        }

        let story = genMappingsStories?.find((story) => story.id === selectedGenMappingStoryID)

        if (!story) {
            console.error('Invalid state to generate mappings')
            return
        }

        try {
            setIsGeneratingMappings(true)

            const mappings = await generateMappings(storyLanguage.code, story.level, story.id)
            setLocalMappings({ ...localMappings, [story.id]: mappings })

            // update genMappingsStories with updated story
            let updatedStories = [...genMappingsStories]
            let idx = updatedStories.findIndex((s) => s.id === story.id)
            updatedStories[idx] = story
            setGenMappingsStories(updatedStories)

            setGenerateMappingsText(JSON.stringify(mappings, null, 2))

            setReviewStories(null)
            toast({
                title: 'Mappings generated',
                status: 'success'
            })
        } catch (e) {
            console.error('Error generating mappings', e)
            toast({
                title: 'Error generating mappings' + e.message,
                status: 'error'
            })
        } finally {
            setIsGeneratingMappings(false)
        }
    }

    async function onSubmitGenerateMappings() {
        if (isSubmittingGenerateMappings) {
            return
        }


        let story = genMappingsStories.find((story) => story.id === selectedGenMappingStoryID)

        if (!story || !generateMappingsText) {
            console.error('Invalid state to submit generate mappings')
            return
        }

        try {
            setIsSubmittingGenerateMappings(true)
            let mappingDict = JSON.parse(generateMappingsText)
            if (!mappingDict) {
                throw new Error('Invalid JSON')
            }
            await submitGeneratedMappings(storyLanguage.code, story.level, story.id, mappingDict)
            // success
            await reloadGenMappingStories()
        } catch (e) {
            console.error('Error submitting generate mappings', e)
            toast({
                title: 'Error submitting generate mappings',
                status: 'error'
            })
        } finally {
            setIsSubmittingGenerateMappings(false)
        }

    }

    // returns error string
    function validateGeneratedMappingsText(text) {
        try {
            let json = JSON.parse(text)
            if (json) {
                return validateGeneratedMappings(json)
            } else {
                return "Can't parse JSON"
            }
        } catch (e) {
            return "Can't parse JSON"
        }

    }

    function validateGeneratedMappings(json) {
        const validLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];
        const errors = [];

        // Validate root-level properties
        if (!json.languageCode) {
            errors.push("Missing languageCode.");
        }

        if (!validLevels.includes(json.level)) {
            errors.push(`Invalid level: ${json.level}. Allowed values: A1, A2, B1, B2, C1, C2.`);
        }

        if (!json.sentences || !Array.isArray(json.sentences)) {
            errors.push("Missing or invalid sentences array.");
        }

        if (!json.text || typeof json.text !== "string" || json.text.trim() === "") {
            errors.push("Invalid or empty root text property.");
        }

        if (!json.title || typeof json.title !== "string" || json.title.trim() === "") {
            errors.push("Invalid or empty root title property.");
        }

        // Validate sentences
        json.sentences.forEach((sentence, sentenceIndex) => {
            if (sentence.text) {
                if (typeof sentence.text !== "string" || sentence.text.trim() === "") {
                    errors.push(`Sentence ${sentenceIndex}: Invalid or empty text.`);
                }

                if (sentence.mappings && Array.isArray(sentence.mappings)) {
                    sentence.mappings.forEach((mapping, mappingIndex) => {
                        // Validate mapping object
                        if (!mapping.range || typeof mapping.range.length !== "number" || mapping.range.length < 0 ||
                            typeof mapping.range.location !== "number" || mapping.range.location < 0) {
                            errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}: Invalid range (length or location).`);
                        }

                        if (!mapping.snippets || !Array.isArray(mapping.snippets)) {
                            errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}: Missing or invalid snippets array.`);
                        } else {
                            mapping.snippets.forEach((snippet, snippetIndex) => {
                                // Validate snippet variants
                                if (snippet.text && snippet.word_type) {
                                    if (typeof snippet.text !== "string" || snippet.text.trim() === "" ||
                                        typeof snippet.word_type !== "string" || snippet.word_type.trim() === "") {
                                        errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid or empty text or word_type.`);
                                    }
                                } else if (snippet.infinitive) {
                                    // const requiredFields = ["count", "infinitive", "person", "tense", "text", "word_type"];
                                    // for (const field of requiredFields) {
                                    //     if (!snippet[field] || (typeof snippet[field] === "string" && snippet[field].trim() === "")) {
                                    //         errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Missing or invalid field '${field}' in snippet ${JSON.stringify(snippet, null, 2)}.`);
                                    //     }
                                    // }
                                    // if (typeof snippet.person !== "number") {
                                    //     errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid 'person' value.`);
                                    // }
                                } else {
                                    errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid snippet (must have either text/word_type or inflection fields).`);
                                }
                            });
                        }
                    });
                } else {
                    errors.push(`Sentence ${sentenceIndex}: Missing or invalid mappings array.`);
                }
            } else if (!sentence.new_line) {
                errors.push(`Sentence ${sentenceIndex}: Missing text or new_line property.`);
            }
        });

        return errors.length ? errors.join("\n\n") : null;
    }

    function onUploadReviewImage(base64Image) {
        let story = getSelectedStory()

        if (!story?.level) {
            throw new Error('Story not found')
        }

        return uploadReviewStoryImage(base64Image, selectedReviewStoryID, storyLanguage.code, story.level)
    }

    function onClickStoryImage(story) {
        setSelectedReviewStoryID(story.id)
        onUploadReviewImageOpen()
    }

    // grouping
    
    function onCombineIntoGroup() {
        let minIndex = -1
        let maxIndex = -1

        let keys = []

        let story = getSelectedStory()
        
        if(!story) {
            console.error('Story not found')
            return
        }

        let modelCopy = copyJSONObject(story)
        console.log("Iterating through mappings " + JSON.stringify(selectedGroupMappings))
        for (let mapping of selectedGroupMappings) {
            let firstCharacterIndex = mapping.range.location
            let lastCharacterIndex = mapping.range.location + mapping.range.length

            if (minIndex === -1 || firstCharacterIndex < minIndex) {
                console.log('minIndex is now ' + firstCharacterIndex)
                minIndex = firstCharacterIndex
            }

            if (maxIndex === -1 || lastCharacterIndex > maxIndex) {
                console.log('maxIndex is now ' + lastCharacterIndex)
                maxIndex = lastCharacterIndex
            }

            keys.push(mapping.id)
        }

        if (minIndex !== -1 && maxIndex !== -1) {
            let sentenceToFix = null
            console.log('keys to remove: ' + JSON.stringify(keys, null, 2))
            // for(let key in keys) {
            // remove from mappings array where .key property === key
            for (let sentence of modelCopy.sentences) {
                console.log('Pre filter mappings ' + JSON.stringify(sentence.mappings) + ' for sentence ' + JSON.stringify(sentence))
                if(sentence.mappings) {
                    let newMappings = sentence.mappings.filter((mapping) => !keys.includes(mapping.id))
                    console.log('Filtered new mappings ' + JSON.stringify(newMappings))
                    if (!sentenceToFix && newMappings.length !== sentence.mappings.length) {
                        console.log('sentenceToFix is ' + JSON.stringify(sentence, null, 2))
                        sentenceToFix = sentence
                        console.log('Assigning newMappings ' + JSON.stringify(newMappings) + ' instead of ' + JSON.stringify(sentence.mappings))
                        sentence.mappings = newMappings
                    }
                }
            }
            // }

            let combinedSnippets = []
            for (let mapping of selectedGroupMappings) {

                for (let snippet of mapping.snippets) {
                    let copy = copyJSONObject(snippet)
                    combinedSnippets.push(copy)
                }
                // delete copy.template
                // delete copy.range
                // delete copy.key
                // delete copy.other_snippets

                // let mappingOtherSnippets = mapping.other_snippets ?? {}
                // for (let key in mappingOtherSnippets) {
                //     otherSnippets[key] = copyJSONObject(mappingOtherSnippets[key])
                // }

                // otherSnippets[random8ID()] = copy
            }

            let combinedMapping = {
                id: random8ID(),
                text: sentenceToFix.text.slice(minIndex, maxIndex),
                // word_type: "proper_noun", // hardcoded for now 
                range: {
                    location: minIndex,
                    length: maxIndex - minIndex
                },
                snippets: combinedSnippets
            }

            sentenceToFix.mappings.push(combinedMapping)
            sentenceToFix.mappings = sentenceToFix.mappings.sort((a, b) => a.range.location - b.range.location)
            console.log('fixed model after combining: ' + JSON.stringify(modelCopy, null, 2))

            updateStory(modelCopy)
            // setFixedModel(copyJSONObject(modelCopy))
        } else {
            console.log('Failed, indices are ' + minIndex + ' ' + maxIndex)
        }

        setSelectedGroupMappings([])
        setGroupMode(false)
    }

    function onClickEnterGroupMode() {
        if(!groupMode) {
            setGroupMode(true)
        } else {
            // already in group mode, check count
            if(selectedGroupMappings.length === 0) {
                // leave group mode
                setGroupMode(false)
            } else {
                // combine
                onCombineIntoGroup()
            }
        }
    }

    return (
        <>
            <Box
                data-test="admin-manage-stories-page"
                h="100vh"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >

                <Header />
                <Box w='100%' h="calc(100% - 128px)">
                    <Box mt={1} mb={1} w='100%' display='flex' direction='column' alignItems='space-between'>
                        <ChooseLanguageComponent ref={chooseLanguageRef} languages={StoryLanguages.asArray()} preSelectedLanguage={storyLanguage} onChangeLanguage={(code) => {
                            onLanguageChanged(getStoryLanguageWithCode(code))
                        }} />
                        {mode === "REVIEW" && selectedReviewStoryID && <Box w='100%' display='flex' justifyContent='space-between'>
                            <Box></Box> {/* Empty Box to push the button to the right */}
                            <Box>
                                <Button mr={4}  colorScheme="green" onClick={onClickEnterGroupMode} isDisabled={isUpdatingData}>{groupMode ? (selectedGroupMappings.length === 0 ? "Leave Group Mode" : "Group Selected") : "Enter group mode"}</Button>
                                <Button mr={4} isLoading={isUpdatingData} colorScheme="blue" onClick={onClickUpdate} isDisabled={!canUpdateData || groupMode}>Update Review Stories</Button>
                                <Button mr={4} isLoading={isUpdatingData} colorScheme="red" onClick={onPublishOpen} isDisabled={canUpdateData || !selectedReviewStoryID || groupMode}>Publish</Button>
                            </Box>
                        </Box>
                        }
                    </Box>
                    <Box display='flex' h={`calc(100% - 40px)`}>

                        <Box h='100%' flex={5}>
                            <Tabs color='white' onChange={(index) => {
                                setMode(allModes[index].id)
                            }}>
                                <TabList>
                                    {allModes.map((mode) => {
                                        return <Tab key={mode.id}>{mode.title}</Tab>
                                    })}
                                </TabList>

                                <TabPanels>
                                    {allModes.map((mode) => {
                                        return <TabPanel key={mode.id}>
                                            {/* Tab Content */}

                                            {mode.id === "REVIEW" && <Box>
                                                {!reviewStories && <Spinner />}
                                                {reviewStories && reviewStories.length === 0 && <Center><Text>No stories to review</Text></Center>}
                                                {reviewStories && reviewStories.length > 0 && <Box>
                                                    {reviewStories.map((story) => {
                                                        let isSelected = selectedReviewStoryID === story.id
                                                        return <Box key={story.id} p={2} my={2} display='flex' flexDirection='row' borderRadius='md' cursor="pointer" backgroundColor={isSelected ? colorSelectedStory : colorUnselectedStory} onClick={() => setSelectedReviewStoryID(story.id)}>

                                                            <FallbackImage onClick={(e) => { onClickStoryImage(story) }} boxSize='80px' src={Constants.storageURLWithPath(story.imageURLPath)} fallbackSrc="/icons/story-placeholder-blue.png" />
                                                            <Box ml={2}>
                                                                <Text>Title: <b>{story.title}</b></Text>
                                                                <Text>Level: <b>{story.level}</b></Text>
                                                            </Box>
                                                        </Box>
                                                    }
                                                    )}
                                                </Box>}
                                            </Box>}

                                            {mode.id === "GENERATE_MAPPINGS" && <Box>
                                                {!genMappingsStories && <Spinner />}
                                                {genMappingsStories && genMappingsStories.length === 0 && <Center><Text>No stories</Text></Center>}
                                                {genMappingsStories && genMappingsStories.length > 0 && <Box>
                                                    {genMappingsStories.map((story) => {
                                                        let isSelected = selectedGenMappingStoryID === story.id
                                                        return <Box key={story.id} p={2} my={2} borderRadius='md' cursor="pointer" backgroundColor={isSelected ? colorSelectedStory : colorUnselectedStory} onClick={() => setSelectedGenMappingStoryID(story.id)}>
                                                            <Text color={story.reviewStory ? 'green' : 'yellow'}>Title: {story.title}</Text>
                                                            <Text>Level: {story.level}</Text>
                                                        </Box>
                                                    }
                                                    )}
                                                </Box>}
                                            </Box>}

                                            {mode.id === "ADD_STORY" && <Box>
                                                <Text>Level</Text>
                                                <Select mt={2} onChange={(e) => setAddStorySelectedLevel(e.target.value)} value={addStorySelectedLevel}>
                                                    {AddStoryLevel.all().map((level) => {
                                                        return <option key={level.id} value={level.id}>{level.title}</option>
                                                    }
                                                    )}
                                                </Select>
                                                <Text mt={2}>Title</Text>
                                                <Input mt={2} placeholder={"Title in " + storyLanguage?.name} value={addStoryTitle} onChange={(e) => setAddStoryTitle(e.target.value)} />
                                            </Box>}

                                        </TabPanel>
                                    })}
                                </TabPanels>
                            </Tabs>
                        </Box>
                        <Divider h='100%' orientation="vertical" />
                        <Box flex="20" >
                            {/* ADD STORY */}
                            {mode === ManageStoryMode.ADD_STORY.id && <Box>
                                <Box ml={4} color='white' display='flex' flexDirection='column' w='95%'>
                                    <RadioGroup value={addStoryMode} onChange={(value) => setAddStoryMode(value)}>
                                        {allAddStoryModes.map((mode, index) => {
                                            return <Radio ml={index !== 0 ? 4 : 0} value={mode.id}>
                                                <Text color="white">{mode.title}</Text>
                                            </Radio>
                                        })}
                                    </RadioGroup>
                                    {addStoryMode === AddStoryMode.ADD_TEXT.id && <Box mt={4}>
                                        <Text>Plain text:</Text>
                                        <Textarea mt={2} rows={16} value={addStoryPlainText} onChange={(e) => setAddStoryPlainText(e.target.value)} />

                                        <Button mt={4} colorScheme="blue" isLoading={addStoryIsSubmitting} isDisabled={!addStoryCanSubmit} onClick={() => {
                                            onSubmitAddStoryPlainText()
                                        }
                                        }>Submit</Button>
                                    </Box>}
                                    {addStoryMode === AddStoryMode.GENERATE.id && <Box mt={4}>
                                        <Button isLoading={addStoryIsGenerating} colorScheme="blue" onClick={onClickAddStoryGenerate}>{addStoryGeneratedText ? "Regenerate" : "Generate"}</Button>
                                        {addStoryGeneratedText && <Box>
                                            <Textarea isDisabled={addStoryIsGenerating} mt={2} rows={16} value={addStoryGeneratedText} onChange={(e) => setAddStoryGeneratedText(e.target.value)} />
                                            <Button mt={4} colorScheme="blue" isLoading={addStoryGeneratedIsSubmitting} onClick={() => { onSubmitGeneratedStoryText() }}>
                                                Submit
                                            </Button>
                                        </Box>
                                        }

                                    </Box>}
                                    {addStoryMode === AddStoryMode.GENERATE_AND_SAVE_TO_DB.id && <Box mt={4}>
                                        <Button isLoading={addStoryIsGenerating} colorScheme="blue" onClick={onClickAddStoryGenerateAndSave}>Generate and Save</Button>
                                    </Box>}
                                </Box>
                            </Box>}
                            {/* GENERATE MAPPINGS */}
                            {mode === ManageStoryMode.GENERATE_MAPPINGS.id && selectedGenMappingStoryID && genMappingsStories && <Box data-test='main-box' h='100%' py={2}>
                                <Box ml={4} color='white' display='flex' flexDirection='column' w='95%'>
                                    {generateMappingsText && <Box>
                                        <Text>Mappings JSON:</Text>
                                        <Textarea mt={2} rows={16} value={generateMappingsText} onChange={(e) => setGenerateMappingsText(e.target.value)} />

                                        <Button mt={4} colorScheme="blue" isLoading={isSubmittingGenerateMappings} isDisabled={isDefined(generateMappingsError)} onClick={() => {
                                            onSubmitGenerateMappings()
                                        }
                                        }>Submit</Button>
                                        {generateMappingsError && <Text color='red'>{generateMappingsError}</Text>}
                                    </Box>}

                                    {!generateMappingsText && <Box>
                                        <Textarea mt={2} rows={16} value={JSON.stringify(selectedGenMappingStory, null, 2)} />
                                        <Center><Button isLoading={isGeneratingMappings} colorScheme="blue" onClick={onClickGenerateMappings}>Generate Mappings</Button></Center>
                                    </Box>}
                                </Box>
                            </Box>
                            }
                            {/* REVIEW  */}
                            {mode === ManageStoryMode.REVIEW.id && selectedReviewStoryID && reviewStories && <Box data-test='main-box' h='100%' py={2}>
                                <Box display="flex" height="100%" >
                                    <Box flex="7" px="4" h='100%' overflow='scroll'>
                                        <Box w='100%' >
                                            <StoryDetails adminMode={groupMode} story={getSelectedStory()} onSelectMapping={onSelectMapping} hideBackToStories={true} />
                                        </Box>
                                    </Box>
                                    <Divider h='100%' opacity={0.2} orientation="vertical" />
                                    <Box flex="3" h='100%'>
                                        {getSelectedMapping()?.mapping?.text && <Box
                                            h='40px'
                                            direction='row'
                                            alignContent='center'
                                            borderRadius='md'
                                            backgroundColor='rgba(255, 255, 255, 0.1)'
                                            mx={2}
                                            px={2}
                                        >

                                            <Center>
                                                <Text fontSize='xl' fontWeight='bold' color='white'>{getSelectedMapping().mapping.text}</Text>
                                                {isLoadingSnippet && <Box ml={2}>
                                                    <Spinner color='gray' />

                                                </Box>}
                                                <IconButton
                                                    icon={<PlusSquareIcon />}
                                                    color='#42F57B'
                                                    backgroundColor="none"
                                                    colorScheme="none"
                                                    zIndex="1"
                                                    top={0}
                                                    height="100%"
                                                    _hover={{ bg: "lightPurple" }}
                                                    data-test='add-snippet-button'
                                                    onClick={onClickAddSnippet}
                                                ></IconButton>
                                            </Center>
                                        </Box>}
                                        {/* Content for the 30% width panel */}
                                        <Box overflowY="scroll" h='calc(100% - 36px)'>
                                            {resultSnippets && <DictionarySearchResults
                                                onEditSnippet={(snippetID) => { onEditSnippet(snippetID) }}
                                                onDeleteSnippet={(snippetID) => { onDeleteSnippetClicked(snippetID) }}
                                                canPinSnippet={false}
                                                highlightedText={getSelectedMapping()?.mapping?.text?.toLowerCase()}
                                                results={resultSnippets}
                                                classroomID={null}
                                                showsCreateButton={false}
                                            />}
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                <Footer />

            </Box>
            <DeleteConfirmation
                isOpen={deletingSnippetID}
                isDeleting={isDeletingSnippet}
                onClose={() => {
                    setDeletingSnippetID(null);
                }}
                onConfirm={() => {
                    doDeleteSelectedSnippet()
                }}
                title="Delete Snippet"
                text="Are you sure you want to delete this snippet? This action cannot be undone."
            />

            <UploadImageModal 
                isOpen={isUploadReviewImageOpen} 
                onClose={onUploadReviewImageClose} 
                onUploadImage={(base64Image) => {
                    return onUploadReviewImage(base64Image)
                }} 
                onImageUploaded={(imagePath) => {
                    console.log('Image uploaded! ', imagePath)
                    let story = getSelectedStory()
                    if (story) {
                        let newStory = copyJSONObject(story)
                        newStory.imageURLPath = imagePath
                        updateStory(newStory)

                        console.log('Setting new story image URL to ' + imagePath)
                    } else {
                        console.error('Story not found when image uploaded')
                    }
                }}
                imageURL={selectedReviewStoryImageURL}
                />

            <AlertDialog
                isOpen={isPublishOpen}
                onClose={onPublishClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Publish Story
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button isDisabled={isPublishing} onClick={onPublishClose}>
                                Cancel
                            </Button>
                            <Button isLoading={isPublishing} colorScheme='red' onClick={doPublishStory} ml={3}>
                                Publish
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default AdminManageStories;

