import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  Box,
  Flex,
  Text,
  Divider,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleEnterKeyPress } from "../utils/helper";
import dateFormat from "dateformat";

function AddDocumentModal({
  isLoading,
  title,
  isOpen,
  buttonTitle,
  onClose,
  text,
  onCreate,
  showRecommendedItems,
}) {
  const [name, setName] = useState("");
  const [okButtonTitle, setOkButtonTitle] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRecommendedItemsVal, setShowRecommendedItems] = useState(false);
  const [recommendedItems, setRecommendedItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (text) {
      setName(text);
    } else {
      setName("");
    }
  }, [text]);

  useEffect(() => {
    setShowRecommendedItems(showRecommendedItems);
  }, [showRecommendedItems]);

  const handleSelection = (item) => {
    setSelectedItem(item);
    setName(item.text);
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
      setName(text);
    }

    if (showRecommendedItemsVal) {
      const today = new Date();
      const userLocale = navigator.language || navigator.userLanguage;
      const formattedDateLocale = new Intl.DateTimeFormat(userLocale).format();
      const items = [
        {
          key: "document-date",
          text: `${t(
            "documents.add_doc_template_document"
          )} ${formattedDateLocale}`,
        },
        {
          key: "sentences-date",
          text: `${t(
            "documents.add_doc_template_sentences"
          )} ${formattedDateLocale}`,
        },
        {
          key: "entry-date",
          text: `${t(
            "documents.add_doc_template_entry"
          )} ${formattedDateLocale}`,
        },
        { key: "monthName-day", text: dateFormat(today, "mmmm d") },
        { key: "monthName-day-year", text: dateFormat(today, "mmmm d, yyyy") },
      ];

      setRecommendedItems(items);
    } else {
      setRecommendedItems(null);
    }
  }, [isOpen, text, showRecommendedItemsVal]);

  useEffect(() => {
    if (title) {
      setDialogTitle(title);
    } else {
      setDialogTitle(t("documents.create_new_document"));
    }
  }, [title, t]);

  useEffect(() => {
    if (buttonTitle) {
      setOkButtonTitle(buttonTitle);
    } else {
      setOkButtonTitle(t("modal.create"));
    }
  }, [buttonTitle, t]);

  const handleCreate = () => {
    if (name.trim()) {
      setLoading(true);
      onCreate(name);
      setName("");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} data-test='add-doc-modal'>
      <ModalOverlay />
      <ModalContent
        data-test='add-doc-modal-content'
        bgImage="url('/icons/dialog.png')"
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <ModalHeader data-test='add-doc-modal-header' color="white">{dialogTitle}</ModalHeader>
        <ModalCloseButton color="closeGrey" data-test='add-doc-modal-close-button' />
        <ModalBody>
          <FormControl data-test='main-form'>
            <Input
              data-test='add-doc-modal-input'
              autoFocus
              onKeyDown={(e) => {
                handleEnterKeyPress(e, handleCreate);
              }}
              color="white"
              placeholder={t("documents.add_document_placeholder")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          {recommendedItems && (
            <Box data-test='recommend-items-box'>
              <Divider my={4} />
              <Text color="white" mb={0} data-test='text-title'>
                {t("documents.templates_title")}
              </Text>
              <Flex overflowX="scroll" w="100%" py={2} data-test='content-flex'>
                {recommendedItems.map((item, index) => (
                  <Box
                    data-test={`box_${index}`}
                    key={index}
                    borderRadius="md"
                    borderWidth={selectedItem === item ? "2px" : "1px"}
                    borderColor={
                      selectedItem === item ? "blue.500" : "gray.200"
                    }
                    p={2}
                    m={1}
                    cursor="pointer"
                    onClick={() => handleSelection(item)}
                    whiteSpace="nowrap"
                  >
                    <Text
                      data-test='text'
                      color="white"
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      {item.text}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          )}
        </ModalBody>

        <ModalFooter data-test='footer'>
          <Button mr={3} isDisabled={isLoading} onClick={onClose} data-test='button-cancel'>
            {t("modal.cancel")}
          </Button>
          <Button
            data-test='button-ok'
            color="white"
            isLoading={loading}
            isDisabled={name.trim().toLowerCase().length === 0}
            colorScheme="blue"
            mr={3}
            onClick={handleCreate}
          >
            {okButtonTitle}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddDocumentModal;
