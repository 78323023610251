import { Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function FallbackImage({ src, fallbackSrc, borderRadius = 8, ...props }) {
    const [imgSrc, setImgSrc] = useState(src || fallbackSrc); // Initialize with src or fallbackSrc

    useEffect(() => {
        if (src) {
            setImgSrc(src);
            console.log(`Changing src to: ${src}`);
        } else {
            setImgSrc(fallbackSrc); // Fallback if src is null or empty
            console.log(`Src is null/empty, falling back to: ${fallbackSrc}`);
        }
    }, [src, fallbackSrc]); // Add fallbackSrc to dependency to handle its change

    return (
        <Image
            src={imgSrc}
            borderRadius={borderRadius}
            borderWidth="8px"
            borderColor="red"
            onError={() => {
                console.log(`Error loading image, setting fallback src: ${fallbackSrc}`);
                setImgSrc(fallbackSrc); // Fallback in case of loading error
            }}
            {...props}
        />
    );
}

export default FallbackImage;
