import { Box, Button, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function ComboBox({ disableEmpty, items, preSelectedItem, onItemSelect }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(preSelectedItem);

  useEffect(() => {
    setSelectedItem(preSelectedItem);
  }, [preSelectedItem]);

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    onItemSelect?.(item);
  };

  return (
    <Menu
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
    >
      <MenuButton
        as={Button}
        variant="outline"
        _hover={{ bg: "blue.800" }}
        _active={{ bg: "transparent" }}
        color="white"
      >
        <Box as="span" display="flex" alignItems="center">
          {selectedItem?.imageName && (
            <Image
              src={'/icons/' + selectedItem.imageName}
              width="20px"
              height="20px"
            />
          )}
          <Text ml={2}>{selectedItem?.title || "/"}</Text>
          <Icon as={isMenuOpen ? FaAngleUp : FaAngleDown} ml={2} />
        </Box>
      </MenuButton>
      <MenuList backgroundColor='midPurple'>
        {!disableEmpty && <MenuItem
            key='empty'
            onClick={() => handleItemSelect(null)}
            fontWeight="normal"
            bg="transparent"
            _hover={{ bg: "blue.800", }}
            
          >
            <Box as="span" display="flex" alignItems="center">
              <Text ml={2} color='transparent'>/</Text>
            </Box>
          </MenuItem>
    } 
        {items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleItemSelect(item)}
            fontWeight={item.id === selectedItem?.id ? "bold" : "normal"}
            color={item.id === selectedItem?.id ? "blue.600" : "black"}
            bg={item.id === selectedItem?.id ? "darkPurple" : "transparent"}
            _hover={{ bg: "blue.800", color: "white" }}
            _active={{ bg: "blue.700", color: "white" }}
          >
            <Box as="span" display="flex" alignItems="center">
              {item.imageName && (
                <Image
                  src={'/icons/' + item.imageName}
                  alt={item.title}
                  width="20px"
                  height="20px"
                />
              )}
              <Text ml={2} color='white'>{item.title}</Text>
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default ComboBox;
