import { ClassroomLayoutComponentType, ClassroomSnippetsContentType } from "../api/classroom.service";

export const Constants = {
  PAGE_TITLE_APP_NAME: "Learner.app",
  MEDIA_QUERY_MOBILE: "(max-width: 768px)",
  PASSWORD_MIN_LENGTH: 8,
  DISPLAY_NAME_MIN_LENGTH: 2,
  MAX_EXAMPLES: 2,
  PAGE_SIZE: 30,
  WRITING_MAX_SENTENCE_LENGTH: 300,
  SORT_OPTION_ALPHABETICALLY: "alphabetical",
  SORT_OPTION_BY_DATE: "date",
  SORT_OPTION_DEFAULT: "alphabetical",
  SORT_ORDER_ASC: "asc",
  SORT_ORDER_DESC: "desc",
  SORT_ORDER_DEFAULT: "asc",
  NOTES_TYPING_DELAY_MS: 500,
  LocalStorage: {
    SNIPPETS_SORT_ORDER: "snippetsSortOrder", // ASC DESC
    SNIPPETS_SORT_OPTION: "snippetsSortOption", // alphabetical / date
    SNIPPETS_SORT_ORDER_DYNAMIC: (index) => `snippetsSortOrderDynamic_${index}`, // ASC DESC
    SNIPPETS_SORT_OPTION_DYNAMIC: (index) =>
      `snippetsSortOptionDynamic_${index}`, // alphabetical / date

    ANONYMOUS_USER_CLASSROOM_LAYOUT_TEACHER:
      "anonymousUserClassroomLayoutTeacher",
    ANONYMOUS_USER_CLASSROOM_LAYOUT_STUDENT:
      "anonymousUserClassroomLayoutStudent",

    CLASSROOM_SESSIONS_DICT: "classroomSessionsDict",
  },
  DynamicLayout: {
    DEFAULT_STUDENT_LAYOUT: {
      layout: "211",
      components: {
        0: {
          type: ClassroomLayoutComponentType.TRANSLATE_WORD,
        },
        1: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.CLASSROOM,
        },
        2: {
          type: ClassroomLayoutComponentType.NOTES,
        },
        3: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.ALL,
        },
      },
    },
    DEFAULT_TEACHER_LAYOUT: {
      layout: "112",
      components: {
        0: {
          type: ClassroomLayoutComponentType.SNIPPETS,
          filter: ClassroomSnippetsContentType.CLASSROOM,
        },
        1: {
          type: ClassroomLayoutComponentType.NOTES,
        },
        2: {
          type: ClassroomLayoutComponentType.TRANSLATE_WORD,
        },
        3: {
          type: ClassroomLayoutComponentType.DICTIONARY_SEARCH,
        },
      },
    },
  },
  zIndex: {
    header: 10,
    toast: 1000,
  },
  DatabasePath: {
    DICTIONARY_CACHE: "dictionary_cache",
    DICTIONARY_CACHE_ENGLISH: "dictionary_cache_en",
    DICTIONARY_CACHE_TRANSLATION: "dictionary_cache_translation",
    USER_SNIPPETS: "user_snippets"
  },
  defaultBucketURL: `https://storage.googleapis.com/languagelearning-310010.appspot.com/`,
  storageURLWithPath: function(path) {
    return this.defaultBucketURL + path
  }
};
