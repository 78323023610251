import { CopyIcon } from "@chakra-ui/icons";
import { IconButton, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Constants } from "../utils/constants";

function CopyToClipboardButton({ text, onClick }) {
    const { t } = useTranslation()
    const toast = useToast()

    const onButtonClick = () => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard')
            let id = "copy-to-clipboard"
            if (!toast.isActive(id)) {
                console.log('showing toast')
                toast({
                    id: id,
                    description: t('sentences.copied_to_clipboard'),
                    position: 'top',
                    status: 'success',
                    duration: 1500,
                    isClosable: false,
                    zIndex: Constants.zIndex.toast
                })

            } else {
                console.log('toast already active')
            }
        }).catch(err => { })
        .finally(() => {
            onClick?.()
        })
    };

    return (
        <IconButton
            data-test="copy-to-clipboard-button"
            icon={<CopyIcon color='white' opacity='0.8' />}
            onClick={onButtonClick}
            isDisabled={text.trim().length === 0}
            variant="ghost"
            aria-label="Copy to Clipboard"
            _hover={{ bg: "darkPurpleHover" }}
            zIndex="1"
        />
    );
}

export default CopyToClipboardButton;