import { useEffect, useState } from "react";

const AudioPlayer = ({ base64, updateRef }) => {
    const [audio, setAudio] = useState(null);

    useEffect(() => {
        console.log('base64 ' + base64)
        setAudio(base64)
    }, [base64]);

    useEffect(() => {  
        if(!audio) {
            return
        }

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const playAudio = async (base64String) => {
            // Decode Base64 string and convert it into an ArrayBuffer
            const audioBuffer = await fetch("data:audio/mp3;base64," + base64String)
                .then(response => response.arrayBuffer());
    
            // Decode the audio data
            audioContext.decodeAudioData(audioBuffer, (buffer) => {
                const source = audioContext.createBufferSource();
                source.buffer = buffer;
                source.connect(audioContext.destination);
                source.start(0); // Play immediately
            }, (e) => { 
                console.log("Error decoding audio data: " + e.err);
            });
        };
       playAudio(audio)
    }, [audio, updateRef]);

    return (<></>);
}

export default AudioPlayer;