import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploadComponent from "../components/image-upload";

function UploadImageModal({ isOpen, onClose, onUploadImage, onImageUploaded, imageURL }) {
    const [isUploading, setIsUploading] = useState(false);    
    const [displayImageURL, setDisplayImageURL] = useState(imageURL);

    useEffect(() => {
        setDisplayImageURL(imageURL);
    }, [imageURL]);

    return (
        <AlertDialog data-test='upload-image-dialog' isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay data-test='upload-image-overlay'>
                <AlertDialogContent data-test='upload-image-content' bgImage="url('/icons/dialog.png')" bgSize="cover" bgRepeat="no-repeat" borderColor='dialogBorder' >
                    <AlertDialogHeader data-test='upload-image-header' fontSize="lg" fontWeight="bold" color='white'>
                        Upload image
                    </AlertDialogHeader>
                    <AlertDialogBody color='white' data-test='upload-image-body'>
                        <ImageUploadComponent onUploadImage={(base64Image) => {
                            return onUploadImage?.(base64Image)
                        }} onImageUploaded={(imagePath) => {
                            console.log('UploadImageModal, onImageUploaded: ', imagePath)
                            onImageUploaded?.(imagePath)
                            onClose?.()
                        }} displayImageURL={displayImageURL}
                        onUploadingChanged={(value) => { setIsUploading(value)}} />
                    </AlertDialogBody>
                    <AlertDialogFooter data-test='upload-image-footer'>
                        <Button isDisabled={isUploading} onClick={onClose} data-test='cancel-button'>
                            Close
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export default UploadImageModal;