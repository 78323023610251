import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  VStack,
  Text,
  useToast,
  Image,
  Icon,
} from "@chakra-ui/react";
import { getDatabase, ref, get } from "firebase/database";
import { onAuthStateChanged, getAuth } from "@firebase/auth";
import { LANGUAGES, getLanguageWithCode } from "../utils/languages";
import Header from "./header";
import { updateLanguages } from "../api/user-language.service";
import { useTranslation } from "react-i18next";
import SEO from "../seo/seo";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function ChooseLanguage() {
  const [userLanguage, setUserLanguage] = useState(null);
  const [learningLanguage, setLearningLanguage] = useState(null);
  const [userID, setUserID] = useState(null);
  const [finishedAuthenticatingUser, setFinishedAuthenticatingUser] =
    useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [hasLanguages, setHasLanguages] = useState(true);
  const [isUserLanguageMenuOpen, setIsUserLanguageMenuOpen] = useState(false);
  const [isLearningLanguageMenuOpen, setIsLearningLanguageMenuOpen] =
    useState(false);

  const db = getDatabase();
  const { t, i18n } = useTranslation();

  const toast = useToast();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setFinishedAuthenticatingUser(true);
      if (user) {
        setUserID(user.uid);
        const userRef = ref(db, "users/" + user.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const { userLanguage, learningLanguage } = snapshot.val();
          if (userLanguage && learningLanguage) {
            window.location.href = "/documents";
            return;
          }
        }

        // try to load user language from local storage
        const storedUserLanguage = localStorage.getItem("userLanguage");
        console.log("user language " + storedUserLanguage);
        if (storedUserLanguage) {
          setUserLanguage(getLanguageWithCode(storedUserLanguage));
        }

        // no languages
        setHasLanguages(false);
      } else {
        // no user
        window.location.href = "/";
      }
    });

    return () => unsubscribe();
  }, [db]);

  useEffect(() => {
    if (userLanguage) {
      console.log('i18n choose language userLanguage ' + userLanguage.code);
      i18n.changeLanguage(userLanguage.code);
    }
  }, [userLanguage, i18n]);

  const handleDone = async () => {
    setSubmitting(true);
    try {
      await updateLanguages(userID, userLanguage?.code, learningLanguage?.code);
      window.location.href = "/documents";
    } catch (error) {
      console.error("Error setting user languages", error);
      toast({
        description: "Error setting user languages",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderLanguageMenu = (
    selectedLanguage,
    setSelectedLanguage,
    isOpen,
    setIsOpen,
    label,
    description,
    placeholder
  ) => (
    <>
      <Text color="white">{label}</Text>
      <Menu
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <MenuButton
          as={Button}
          variant="outline"
          _hover={{ bg: "blue.800" }}
          _active={{ bg: "transparent" }}
          color="white"
          width="100%"
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box as="span" display="flex" alignItems="center">
              {selectedLanguage ? (
                <>
                  <Image
                    src={`/icons/flag-${selectedLanguage.code}.png`}
                    alt={selectedLanguage.code}
                    width="20px"
                    height="20px"
                  />
                  <Text ml={2}>{selectedLanguage.name}</Text>
                </>
              ) : (
                <Text ml={2}>{placeholder}</Text>
              )}
            </Box>
            <Icon as={isOpen ? FaAngleUp : FaAngleDown} />
          </Box>
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => setSelectedLanguage(null)}
            disabled={!selectedLanguage}
          >
            <Text>{placeholder}</Text>
          </MenuItem>
          {LANGUAGES.map((lang) => (
            <MenuItem key={lang.code} onClick={() => setSelectedLanguage(lang)}>
              <Box as="span" display="flex" alignItems="center">
                <Image
                  src={`/icons/flag-${lang.code}.png`}
                  alt={lang.code}
                  width="20px"
                  height="20px"
                />
                <Text ml={2}>{lang.name}</Text>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Text color="lightGrey">{description}</Text>
    </>
  );

  return (
    <Box
      width="100%"
      height="calc(100vh)"
      style={{
        backgroundImage: "url('/icons/bg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center center",
      }}
    >
      <Header hideNavigation={true} />
      <SEO pageKey={"choose_language"} />
      <Center as="main" h="calc(100vh - 65px)">
        {finishedAuthenticatingUser && !hasLanguages && (
          <VStack spacing={2} alignItems="start">
            {renderLanguageMenu(
              userLanguage,
              setUserLanguage,
              isUserLanguageMenuOpen,
              setIsUserLanguageMenuOpen,
              t("choose_language.user_language"),
              t("choose_language.user_language_description"),
              "Select your language"
            )}
            {renderLanguageMenu(
              learningLanguage,
              setLearningLanguage,
              isLearningLanguageMenuOpen,
              setIsLearningLanguageMenuOpen,
              t("choose_language.learning_language"),
              t("choose_language.learning_language_description"),
              "Select learning language"
            )}
            <Center width="100%">
              <Button
                mt={4}
                isLoading={isSubmitting}
                colorScheme="green"
                isDisabled={!userLanguage || !learningLanguage}
                onClick={handleDone}
              >
                {t("modal.done")}
              </Button>
            </Center>
          </VStack>
        )}
      </Center>
    </Box>
  );
}

export default ChooseLanguage;
