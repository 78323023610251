import { httpsCallable } from "@firebase/functions";
import { database, functions } from "./firebaseConfig";
import { convertDictionarySearchResultToSnippetModels, createStorySnippetTemplate, localizePerson, removeAccents } from "../utils/snippet-utils";
import { get, ref } from "@firebase/database";
import { Constants } from "../utils/constants";
import { findStoryMapping } from "../admin/story-utils";

// translationService.js

export async function translateText(text, sourceLang, targetLang) {

  if (!text) {
    return ''
  }

  let translateHttpsCallable = httpsCallable(functions, 'snippets-translate_word_term');

  try {
    let result = await translateHttpsCallable({
      text: text,
      sourceLang: sourceLang,
      targetLang: targetLang
    })

    return result?.data.translation ?? ''
  } catch (error) {
    console.error('Error translating text: ' + error)
    throw new Error("Failed to translate text")
  }

  // const url = `${GOOGLE_API_ENDPOINT}?key=${GOOGLE_API_KEY}&q=${encodeURIComponent(text)}&source=${sourceLang}&target=${targetLang}&format=text`;

  // const response = await fetch(url, { method: "POST" });
  // const data = await response.json();

  // if (data && data.data && data.data.translations && data.data.translations.length > 0) {
  //   return data.data.translations[0].translatedText;
  // } else {
  //   throw new Error("Failed to translate text");
  // }
}

export async function loadStorySnippets(mapping, definitions, learningLanguageCode, userLanguageCode, t) {

  let promises = []

  console.log(`definitions are ${JSON.stringify(definitions)} in ${JSON.stringify(mapping)}`)

  let snippets = mapping.snippets ?? {}
  console.log('Snippets are ' + JSON.stringify(snippets))
  // load snippets
  for (let snippetKey in snippets) {
    let snippet = snippets[snippetKey]
    if (snippet.template) {
      // template snippet -> read locally
      console.log("getting template")
      // FIXME: Need to localize
      let getTemplateSnippetPromise = createStorySnippetTemplate(snippet.text, snippet.template, snippet.word_type, t).then((snippet) => {
        if (snippet) {
          console.log('returning snippet ' + JSON.stringify(snippet))
          return { snippets: [snippet] }
        } else {
          console.log('returning null')
          return null
        }
      })
      promises.push(getTemplateSnippetPromise)
    } else {
      // regular snippet -> load from dictionary
      const promiseGetOtherSnippet = loadSingleSnippetForStoryMapping(snippet, mapping.id, snippet.id, definitions, learningLanguageCode, userLanguageCode)
      promises.push(promiseGetOtherSnippet)
    }
  }

  return Promise.all(promises).then((results) => {
    // filter null results
    let mappingChanged = false
    let filtered = results.filter(result => result);

    let combinedSnippets = [];
    for (let result of filtered) {
      mappingChanged = mappingChanged || result.mappingChanged;
      combinedSnippets = combinedSnippets.concat(result.snippets);
    }

    let toReturn = { snippets: combinedSnippets, mappingChanged: mappingChanged };

    console.log('Returning ' + JSON.stringify(toReturn))

    return toReturn

    // each result is an array, concat them
    // let flat = filtered.flat()
    // console.log('flat is ' + JSON.stringify(flat))

  })
}

export async function loadSingleSnippetForStoryMapping(mapping, mappingKey, snippetKey, definitions, learningLanguageCode, userLanguageCode) {
  const { text: word, infinitive: infinitiveVerb, word_type: wordType } = mapping;

  console.log('Loading single snippet for ' + word)

  if (!word || !learningLanguageCode || !wordType) {
    console.log('Aborting because of missing word, word_type or learningLanguageCode')
    return Promise.resolve(null);
  }

  let termToSearch = word

  if (infinitiveVerb) {
    termToSearch = infinitiveVerb
  }

  try {
    console.log('Searching dictionary for ' + termToSearch)
    let searchResult = await dictionarySearch(termToSearch, learningLanguageCode, userLanguageCode)
    if (searchResult) {
      // wiktionary search result 
      console.log('Received search result for ' + termToSearch)
      let snippets = await convertDictionarySearchResultToSnippetModels(searchResult, userLanguageCode, learningLanguageCode, true)
      return prepareStorySnippets(snippets, mappingKey, snippetKey, mapping, definitions, userLanguageCode)
    } else {
      console.log('Search result is null')
      return null
    }
  } catch (error) {
    console.log('dictionary search error: ' + error)
    return null
  }
}

function prepareStorySnippets(dictionarySnippets, mappingKey, snippetKey, snippet, definitions, userLanguageCode) {
  console.log('Snippets to prepare ' + JSON.stringify(dictionarySnippets))
  let mappingChanged = false

  let filteredSnippets = dictionarySnippets.filter(s => s.wordType === snippet.word_type)

  if (filteredSnippets.length === 0) {
    console.log('Filtering failed; couldn\'t find snippet with word type ' + snippet.word_type + ' for ' + JSON.stringify(snippet))
    if(dictionarySnippets.length > 0) {
      filteredSnippets = [dictionarySnippets[0]]
      console.log(`Mapping word type from ${snippet.word_type} to ${dictionarySnippets[0].wordType} for mapping ${JSON.stringify(snippet)}`)
      snippet.word_type = dictionarySnippets[0].wordType
      mappingChanged = true
    } else {
      filteredSnippets = []
    }
    // filteredSnippets = snippets
  }

  // "selectedDefinitionIDs": {
  //   "en": {
  //       "snippetID": "definitionID"
  //   }
  // }

  // {
  //   "key": "saasidja29oppp",
  //   "text": "Era",
  //   "word_type": "verb",
  //   "infinitive": "ser",
  //   "person": 3,
  //   "tense": "imperfect",
  //   "count": "singular",
  //   "range": {
  //       "location": 0,
  //       "length": 3
  //   },
  //   "other_snippets": {
  //       "otherSnippetKey": {

  //       }
  //   },
  // },

  let selectedDefinitionID = definitions[snippetKey]

  console.log(`Trying to select definition for ${snippetKey} = ${selectedDefinitionID} in definitions ${JSON.stringify(definitions)}`)
  console.log('keys are ' + JSON.stringify(Object.keys(definitions)))
  if (selectedDefinitionID) {
    console.log('Iterating through ' + JSON.stringify(filteredSnippets))
    for (let snippet of filteredSnippets) {
      if (snippet.definitions[selectedDefinitionID]) {
        // definition exists
        console.log('FOund!')
        snippet.selectedDefinition = { id: selectedDefinitionID, languageCode: userLanguageCode }
      }
    }
  }

  return { snippets: filteredSnippets, mappingChanged: mappingChanged }
}

export async function dictionarySearch(word, learningLanguageCode, userCode) {
  console.log(`Dictionary search for ${word} in ${learningLanguageCode} for user ${userCode}`)
  let userLanguageCode = ''
  if(userCode === 'en' && learningLanguageCode === 'en') {
    throw new Error('Invalid language code combination')
  } else if (userCode === learningLanguageCode) {
    console.log("User language code is the same as learning language code, setting to English")
    userLanguageCode = 'en'
  } else {
    userLanguageCode = userCode
  }

  console.log(`Dictionary search for ${word} in learningCode ${learningLanguageCode} and userCode ${userLanguageCode}`)
  if (learningLanguageCode === 'en') {
    console.log('Searching English dictionary')
    return dictionarySearchEnglish(word, userLanguageCode)
  }

  console.log('Searching non-English dictionary')
  // unaccented term
  let unaccentedTerm = removeAccents(word.trim().toLowerCase())

  let promiseTranslation = null

  let promises = []
  let promiseData = get(ref(
    database,
    `${Constants.DatabasePath.DICTIONARY_CACHE}/${learningLanguageCode}/${unaccentedTerm}/forms/`,
  ))
  promises.push(promiseData)

  if (userLanguageCode !== 'en') {
    promiseTranslation = get(ref(database, `${Constants.DatabasePath.DICTIONARY_CACHE_TRANSLATION}/${learningLanguageCode}/${userLanguageCode}/${unaccentedTerm}`))
    promises.push(promiseTranslation)
  }

  let results = await Promise.all(promises)

  let snapshot = results[0]
  let data = snapshot?.val()
  let result = null
  if (snapshot?.exists() && data) {
    result = {}

    if (userLanguageCode !== 'en') {
      let translation = results[1]?.val()
      if (!translation) {
        console.log('Missing translation')
        let translateHttpsCallable = httpsCallable(functions, 'snippets-fetch_missing_snippet_translation');

        translation = await translateHttpsCallable({
          term: word,
          learningLanguageCode: learningLanguageCode,
          userLanguageCode: userLanguageCode
        })

        translation = translation?.data ?? translation
        console.log('Translation returned is ' + JSON.stringify(translation))
      }

      if (translation) {
        for (let word in translation) {
          let wordTypesObject = translation[word]
          for (let wordType in wordTypesObject) {
            let translationObject = wordTypesObject[wordType]
            if (data[word]?.[wordType] && translationObject.definitions) {
              data[word][wordType].definitions = translationObject.definitions
            }
          }

        }
        console.log('Added translation ' + JSON.stringify(data))
      }
    }

    if (data[word]) {
      result[word] = data[word]
    } else {
      result = data
    }
  } else {
    const generateSnippets = httpsCallable(functions, 'snippets-dictionary_search');
    result = await generateSnippets({
      text: word.trim().toLowerCase(),
      snippetLanguage: learningLanguageCode,
      userLanguage: userLanguageCode,
    })

    let model = result?.data?.result ?? result?.data
    console.log('acc data: ' + JSON.stringify(model))
    if (!model) {
      // todo localize
      throw new Error("No results found")
    } else {
      console.log('Model returned from dictionary_search: ' + JSON.stringify(model))
      result = model
    }
  }

  if (result) {
    for (let word in result) {
      let dict = result[word]
      for (let wordType in dict) {
        let wordDict = dict[wordType]
        if (wordType !== 'inflections') {
          wordDict.definitionsLanguageCode = userLanguageCode
        }
      }
    }
  }

  console.log('Dictionary search result: ' + JSON.stringify(result))

  console.log('Returning result ' + JSON.stringify(result))
  return result
}

async function dictionarySearchEnglish(word, userLanguageCode) {

  // unaccented term
  let unaccentedTerm = removeAccents(word.trim().toLowerCase())

  let path = `${Constants.DatabasePath.DICTIONARY_CACHE_ENGLISH}/${userLanguageCode}/${unaccentedTerm}/forms`
  let snapshot = await get(ref(
    database,
    path
  ))

  let data = snapshot?.val()

  console.log(`Data at path ${path} is ${JSON.stringify(data)}`)

  if (snapshot?.exists() && data) {

    // for (let wordType in data) {
    //   let wordDict = data[wordType]
    //   if (wordType !== 'inflections') {
    //     wordDict.definitionsLanguageCode = userLanguageCode
    //   }
    // }


    return { [unaccentedTerm]: data }
  } else {
    throw new Error("No results found")
  }
}