import { Box, Button, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function ChooseLanguageComponent({ preSelectedLanguage, onChangeLanguage, languages }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(preSelectedLanguage);
  const [allLanguages, setAllLanguages] = useState(null);

  useEffect(() => {
    if (languages) {
      setAllLanguages(languages);
    } else {
      setAllLanguages([]);
    }
  }, [languages]);

  useEffect(() => {
    setSelectedLanguage(preSelectedLanguage);
  }, [preSelectedLanguage]);

  return (<Menu
    data-test="language-menu"
    isOpen={isMenuOpen}
    onOpen={() => setIsMenuOpen(true)}
    onClose={() => setIsMenuOpen(false)}
  >
    <MenuButton
      data-test="language-menu-button"
      as={Button}
      variant="outline"
      _hover={{ bg: "blue.800" }}
      _active={{ bg: "transparent" }}
      color="white"
    >
      <Box as="span" display="flex" alignItems="center" data-test='content-box'>
        <Image
          data-test='flag-image'
          src={`/icons/flag-${selectedLanguage?.code}.png`}
          width="20px"
          height="20px"
        />
        <Text data-test='lang-text' ml={2}>{selectedLanguage?.name || "Select Language"}</Text>
        <Icon data-test='angle-icon' as={isMenuOpen ? FaAngleUp : FaAngleDown} ml={2} />
      </Box>
    </MenuButton>
    <MenuList data-test='menu-list'>
      {allLanguages && allLanguages.map((lang) => (
        <MenuItem
          data-test={`menu-item_${lang.code}`}
          key={lang.code}
          onClick={() => onChangeLanguage?.(lang.code)}
          color="black"
        >
          <Box as="span" display="flex" alignItems="center">
            <Image
              src={`/icons/flag-${lang.code}.png`}
              alt={lang.code}
              width="20px"
              height="20px"
            />
            <Text data_test={`menu-item-text_${lang.code}`} ml={2}>{lang.name}</Text>
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  </Menu>)
}

export default ChooseLanguageComponent;