import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Text,
  Image,
  Icon,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { LANGUAGES, getLanguageWithCode } from "../utils/languages";
import { t } from "i18next";
import { useState } from "react";
import { learningLanguageState } from "../state/user-state";
import { FaAngleDown, FaAngleUp } from "react-icons/fa"; // Assuming you are using react-icons

function ChooseLearningLanguage() {
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChangeLanguage = (code) => {
    setLearningLanguage(getLanguageWithCode(code));
    setIsMenuOpen(false); // Close the menu after selecting a language
  };

  const renderSelect = () => {
    return (
      <Menu
        isOpen={isMenuOpen}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuButton
          as={Button}
          variant="outline"
          _hover={{ bg: "blue.800" }}
          _active={{ bg: "transparent" }}
          color="white"
        >
          <Box as="span" display="flex" alignItems="center">
            <Image
              src={`/icons/flag-${learningLanguage?.code}.png`}
              alt={learningLanguage?.code}
              width="20px"
              height="20px"
            />
            <Text ml={2}>
              {learningLanguage?.name || t("choose_language.select_language")}
            </Text>
            <Icon as={isMenuOpen ? FaAngleUp : FaAngleDown} ml={2} />
          </Box>
        </MenuButton>
        <MenuList>
          {LANGUAGES.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => handleChangeLanguage(lang.code)}
            >
              <Box as="span" display="flex" alignItems="center">
                <Image
                  src={`/icons/flag-${lang.code}.png`}
                  alt={lang.code}
                  width="20px"
                  height="20px"
                />
                <Text ml={2}>{lang.name}</Text>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };

  return (
    <HStack spacing={2} align="center">
      <Text color="white" whiteSpace="nowrap">
        {t("choose_language.learning_language")}
      </Text>
      {renderSelect()}
    </HStack>
  );
}

export default ChooseLearningLanguage;
