import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function SEO({ pageKey, title }) {
  const { t } = useTranslation();

  let pageTitle;
  if (title) {
    pageTitle = title;
  } else if (pageKey) {
    let tKey = `${pageKey}.page_title`
    pageTitle = t(tKey);
    if(pageTitle === tKey) {
      pageTitle = '';
    }
  } else {
    pageTitle = '';
  }

  return (
    <Helmet bodyAttributes={{style: 'background-color : #1F164E'}}>
      {pageTitle && <title>{pageTitle}</title>}
      {pageKey && <meta name="description" content={t(`${pageKey}.page_description`)} />}
    </Helmet>
  );
}

export default SEO;
